import { styled } from '@/style';

// eslint-disable-next-line import/prefer-default-export
export const Icon = styled.divBox`
  min-width: 60px;
  width: 60px;
  height: 60px;
  color: palette-primary;
  padding: lg 0;
`;
export const RowTextContainer = styled.divBox<{
  $isLast: boolean;
}>`
  padding: lg 0;

  border-bottom: ${({ $isLast }) => ($isLast ? 'none' : '1px solid')};
  border-bottom-color: palette-border;
`;
