import { FC, useState, useEffect } from 'react';

import * as c from './components';

const FooterReveal: FC<{
  footer: JSX.Element;
  isFooterVisible: boolean;
}> = ({ children, footer, isFooterVisible }) => {
  const [pos, setPos] = useState(0);
  useEffect(() => {
    if (isFooterVisible) {
      const footerEl = document.getElementById('footer-container');
      if (footerEl) {
        const footerRect = footerEl.getBoundingClientRect();
        setPos(footerRect.height);
      }
    } else setPos(0);
  }, [isFooterVisible]);
  return (
    <c.Container>
      <c.ContentContainer style={{ bottom: pos }}>
        {children}
      </c.ContentContainer>
      <c.FooterContainer id='footer-container'>{footer}</c.FooterContainer>
    </c.Container>
  );
};

export default FooterReveal;
