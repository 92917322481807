import { FC } from 'react';

import { BlankLink } from '@/elements';

import { ArticleCard } from './card';
import { ArticleImage } from './image';
import { SummaryBanner } from './summary-banner';
import { TextLayoutContainer } from './text-layout-container';
import { ArticleTitle } from './title';

import {
  useArticleLayoutIdsContext,
  useCreateArticleLayoutIds,
} from '../../context';
import { PublishedArticleContent } from '../../utils/use-article-story-formatter';

export const PublishedArticleCard: FC<{
  article: PublishedArticleContent;
  path: string;
}> = ({ article, path }) => {
  const { setLayoutIds } = useArticleLayoutIdsContext();
  const layoutIds = useCreateArticleLayoutIds();

  return (
    <BlankLink
      href={path}
      display='block'
      maxW={640}
      onClick={() => setLayoutIds(layoutIds)}
    >
      <ArticleCard>
        <ArticleImage
          layoutId={layoutIds.image}
          image={article.headlineImage}
        />
        <TextLayoutContainer>
          <ArticleTitle layoutId={layoutIds.title}>
            {article.title}
          </ArticleTitle>
          <SummaryBanner readTime={article.readTime} />
        </TextLayoutContainer>
      </ArticleCard>
    </BlankLink>
  );
};
