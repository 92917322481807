import isEqual from 'lodash/isEqual';

import ElementPositionManager from './components/ElementPositionManager';
import { IItemStateManager, INextTriggers, IScrollStatus } from './types';

export default class TimelineItem<
  TStatus = any,
  TCalibrationData = any,
  TElement extends string = any,
  TTriggers = Array<number>
> {
  private _stateManager: IItemStateManager<
    TStatus,
    TCalibrationData,
    TElement,
    TTriggers
  >;
  private _nextTriggers: INextTriggers | null = null;
  private _triggersList?: TTriggers;
  private _status?: TStatus;
  private _calibrationData?: TCalibrationData;
  private _elementPositionManagers: Record<TElement, ElementPositionManager>;

  constructor(
    stateManager: IItemStateManager<
      TStatus,
      TCalibrationData,
      TElement,
      TTriggers
    >
  ) {
    this._stateManager = stateManager;
    this._elementPositionManagers = Object.keys(stateManager.elements).reduce(
      (prev, key) => ({
        ...prev,
        [key]: new ElementPositionManager(
          stateManager.elements[key as TElement]
        ),
      }),
      {}
    ) as Record<TElement, ElementPositionManager>;
  }

  private get _elements() {
    return Object.keys(this._elementPositionManagers).reduce(
      (prev, key) => ({
        ...prev,
        [key]: this._elementPositionManagers[key as TElement].getElement(),
      }),
      {}
    ) as Record<TElement, HTMLElement | null>;
  }

  public get id() {
    return this._stateManager.id;
  }
  public get calibrationData() {
    return this._calibrationData;
  }
  public get status() {
    return this._status;
  }
  public get triggersList() {
    return this._triggersList;
  }
  public get nextTriggers() {
    return this._nextTriggers;
  }

  private _getInput = (scrollStatus: IScrollStatus) => ({
    scrollStatus,
    status: this._status === undefined ? null : this._status,
    triggersList: this._triggersList === undefined ? null : this._triggersList,
    calibrationData:
      this._calibrationData === undefined ? null : this._calibrationData,
    elements: this._elements,
  });

  private _refreshNextTriggers = (scrollStatus: IScrollStatus) => {
    const newNextTriggers = this._stateManager.getNextTriggers({
      scrollStatus,
      triggersList: this._triggersList,
      elements: this._elements,
    });
    const haveNextTriggersChanged = !isEqual(
      newNextTriggers,
      this._nextTriggers
    );
    this._nextTriggers = newNextTriggers;
    return haveNextTriggersChanged;
  };

  private _refreshStatus = (scrollStatus: IScrollStatus) => {
    const newStatus = this._stateManager.getStatus({
      scrollStatus,
      triggersList: this._triggersList,
      elements: this._elements,
    });
    const haveTriggersChanged = !isEqual(newStatus, this._status);
    this._status = newStatus;
    if (haveTriggersChanged) {
      this._stateManager.handlers.onStatusChanged?.(
        this._getInput(scrollStatus)
      );
    }
    return haveTriggersChanged;
  };

  private _refreshTriggersList = (scrollStatus: IScrollStatus) => {
    const newTriggersList = this._stateManager.getTriggersList({
      scrollStatus,
      elements: this._elements,
    });
    const haveTriggersListChanged = !isEqual(
      newTriggersList,
      this._triggersList
    );
    this._triggersList = newTriggersList;
    return haveTriggersListChanged;
  };

  private _checkAndUpdateCalibrationData = (
    scrollStatus: IScrollStatus
  ): boolean => {
    if (!this._stateManager.getCalibrationData) return false;
    const newCalibrationData = this._stateManager.getCalibrationData({
      scrollStatus,
      elements: this._elements,
    });
    const doesNeedCalibrating = !isEqual(
      newCalibrationData,
      this._calibrationData
    );
    this._calibrationData = newCalibrationData;
    return doesNeedCalibrating;
  };

  register = (scrollStatus: IScrollStatus): INextTriggers | null => {
    this._calibrationData = this._stateManager.getCalibrationData?.({
      scrollStatus,
      elements: this._elements,
    });
    this._triggersList = this._stateManager.getTriggersList({
      scrollStatus,
      elements: this._elements,
    });
    this._status = this._stateManager.getStatus({
      scrollStatus,
      triggersList: this._triggersList,
      elements: this._elements,
    });
    this._nextTriggers = this._stateManager.getNextTriggers({
      scrollStatus,
      triggersList: this._triggersList,
      elements: this._elements,
    });

    this._stateManager.handlers.onRegister?.(this._getInput(scrollStatus));
    this._stateManager.handlers.onStatusChanged?.(this._getInput(scrollStatus));
    this._stateManager.handlers.onCalibrate?.(this._getInput(scrollStatus));

    return this._nextTriggers;
  };

  raf = (scrollStatus: IScrollStatus) => {
    if (this._stateManager.handlers.onRaf)
      this._stateManager.handlers.onRaf(this._getInput(scrollStatus));
  };
  onScroll = (scrollStatus: IScrollStatus) => {
    if (this._stateManager.handlers.onScroll)
      this._stateManager.handlers.onScroll(this._getInput(scrollStatus));
  };

  tryCalibrate = (scrollStatus: IScrollStatus): INextTriggers | null => {
    if (this._checkAndUpdateCalibrationData(scrollStatus)) {
      return this.calibrate(scrollStatus);
    }
    return null;
  };

  calibrate = (scrollStatus: IScrollStatus): INextTriggers | null => {
    this._stateManager.handlers.onCalibrate?.(this._getInput(scrollStatus));
    if (this._refreshTriggersList(scrollStatus)) {
      if (this._refreshNextTriggers(scrollStatus)) {
        this._refreshStatus(scrollStatus);

        return this.nextTriggers;
      }
    }

    return null;
  };

  trigger = (scrollStatus: IScrollStatus): INextTriggers | null => {
    const nextTriggers = this._refreshNextTriggers(scrollStatus)
      ? this.nextTriggers
      : null;

    this._refreshStatus(scrollStatus);

    return nextTriggers;
  };
}
