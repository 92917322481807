import type { SbComponent, SbRichText, TCtaContent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import { Diamond } from '@/common/diamond';
import {
  CtaRow,
  RichText,
  Icon,
  TIconName,
  RichSentence,
  TRichSentence,
} from '@/common/storyblok/components';
import { Card, Layout } from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

export const CalloutSection: SbComponent<{
  iconName: TIconName;
  title: TRichSentence;
  description: SbRichText;
  ctas?: Array<TCtaContent>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <AnimateOnVisible>
      <Section>
        <Fade.Up>
          <x.div position='relative'>
            <Card disableHover flex={1} position='relative' px='48px'>
              <Layout.VStack
                spacing='lg'
                maxW='section-width-sm'
                mx='auto'
                my='26px'
              >
                <x.div display='flex' alignItems='center'>
                  <RichSentence
                    variant='h3'
                    pb={0}
                    mt={{ _: '0.5rem' }}
                    color='palette-primary'
                  >
                    {content.title}
                  </RichSentence>
                </x.div>
                <RichText>{content.description}</RichText>
                {content.ctas ? (
                  <CtaRow ctas={content.ctas} justifyContent='flex-start' />
                ) : null}
              </Layout.VStack>
            </Card>
            <x.div
              position='absolute'
              left='0'
              top='20px'
              transform='translate(-50%)'
              p='md'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Diamond
                size='100%'
                strokeWidth={0.5}
                fillColor='white'
                position='absolute'
              />
              <Icon
                position='relative'
                size={30}
                name={content.iconName}
                color='palette-primary'
              />
            </x.div>
          </x.div>
        </Fade.Up>
      </Section>
    </AnimateOnVisible>
  </SbEditable>
);
