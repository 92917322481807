import * as Sentry from '@sentry/nextjs';

export const reportError = (message: string, info?: any) => {
  if (info) {
    Sentry.addBreadcrumb({
      level: Sentry.Severity.Info,
      message: `Info for exception: ${message}`,
      data: info,
    });
  }
  Sentry.captureException(new Error(message));
};
