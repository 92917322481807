import { motion } from 'framer-motion';

import ArrowDown from '@/assets/iconly/Arrow - Down 2.svg';
import ArrowRight from '@/assets/iconly/Arrow - Right.svg';
import * as e from '@/elements';
import { styled, css } from '@/style';

const MenuButtonHeight = 54;

export const DrawerContainer = styled(motion.div)<{
  $isOpen: boolean;
}>`
  z-index: overlay;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  overflow: hidden;
  background-color: palette-bg;
  color: palette-text;
  transition: fast;
  box-shadow: none;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      width: 100vw;
      max-width: drawer-width;
      box-shadow: md;
    `}
`;
export const BurgerButton = styled(motion.div)`
  position: fixed;
  top: lg;
  right: lg;
  width: ${MenuButtonHeight}px;
  height: ${MenuButtonHeight}px;
  border-radius: ${MenuButtonHeight / 2}px;
  transition: fast;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: calc(${({ theme }) => theme.zIndices.overlay} + 2);
  background-color: palette-bg;
  color: palette-text;
  transition: base;
  box-shadow: none;
  :hover {
    box-shadow: md;
  }
`;

export const Overlay = styled(DrawerContainer)`
  max-width: 100vw;
  cursor: pointer;
  pointer-events: none;
  background-color: transparent;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      background-color: overlay;
      pointer-events: auto;
    `}
`;

export const DrawerContent = styled.nav<{
  $isOpen: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: drawer-width;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: fast;
  opacity: 0;
  pointer-events: none;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;

export const DrawerTitle = styled.divBox`
  padding-left: lg;
  padding-right: lg;
  height: header;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: palette-border;
`;

export const DrawerList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: md;
`;
export const DrawerCta = styled(e.Layout.HStack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: md;
`;

export const AccordionChildAnimation = (props: any) => (
  <motion.div
    variants={{
      'accordion-show': {
        opacity: 1,
        x: 0,
      },
      'accordion-hide': {
        opacity: 0,
        x: 8,
      },
    }}
    {...props}
  />
);
export const DrawerChildAnimation = (props: any) => (
  <motion.div
    variants={{
      'drawer-show': {
        opacity: 1,
        y: 0,
      },
      'drawer-hide': {
        opacity: 0,
        y: 8,
      },
    }}
    {...props}
  />
);

export const ListItemContent = styled.divBox<{
  $isActive?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  color: palette-text;
  font-size: md;
  font-weight: 600;
  border-bottom: 1px solid;
  border-bottom-color: palette-border;
  width: 100%;
  transition: base;
  padding: 0 5px;
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: palette-primary;
    `}
`;
export const ListItemText = styled.div`
  text-transform: uppercase;
`;
export const ListItemArrowRight = styled(ArrowRight)`
  position: relative;
  top: -2px;
  color: palette-primary;
  width: 24px;
  height: 24px;
  margin-left: sm;
`;
export const AccordionToggleIcon = styled(ArrowDown)`
  position: relative;
  transform-origin: 50%;
  width: 24px;
  height: 24px;
`;

export const AccordionListItem = styled.divBox`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: md;
  align-items: center;
  height: 48px;
`;
export const AccordionArrowRight = styled(ArrowRight)`
  position: relative;
  top: -2px;
  color: palette-primary;
  width: 24px;
  height: 24px;
  margin-right: sm;
`;
