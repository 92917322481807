/* eslint-disable max-classes-per-file */
import { FC, useState } from 'react';

import { LayoutGroup } from 'framer-motion';

import Palette, { TPalette } from '@/common/Palette';
import { Progress, useTimelineItem } from '@/lib/ScrollTimeline';
import usePageState from '@/state/PageState';
import { useUp, x } from '@/style';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import * as c from './components';

export const HeaderComponent: FC<{
  palette?: TPalette;
}> = ({ palette, children }) => {
  const [isAtTop, setIsAtTop] = useState(false);
  useTimelineItem(
    () =>
      new Progress({
        id: 'app-header',
        progress: {
          from: 0,
          to: 1,
        },
        elements: {},
        getStartPos: () => 0,
        getEndPos: () => 5,
        handlers: {
          onStatusChanged: ({ status: progress }) => {
            if (progress !== null) {
              if (progress < 0.5) setIsAtTop(true);
              else setIsAtTop(false);
            }
          },
        },
      })
  );
  const breakpoint = 'laptop';
  const isLarge = useUp(breakpoint);

  return (
    <Palette palette={palette}>
      <LayoutGroup id='header'>
        <c.HeaderContainer>
          <x.div display={{ _: 'none', [breakpoint]: 'block' }}>
            <DesktopHeader isAtTop={isAtTop} key='desktop' />
          </x.div>
          <x.div flex='1' display={{ _: 'block', [breakpoint]: 'none' }}>
            <MobileHeader key='mobile' isVisible={!isLarge} />
          </x.div>
        </c.HeaderContainer>
      </LayoutGroup>
      <LayoutGroup id='content'>
        <x.div>{children}</x.div>
      </LayoutGroup>
    </Palette>
  );
};

const Header: FC = (props) => {
  const { palette } = usePageState();

  return <HeaderComponent palette={palette} {...props} />;
};

export default Header;
