import { FC } from 'react';

import config from '@/config';
import * as e from '@/elements';
import { Fade } from '@/lib/utils/animation';
import colors, { brand } from '@/style/theme/colors';

import * as c from './components';

type BrandColor = keyof typeof brand;

const Socials: FC<{
  color?: BrandColor;
}> = ({ color = 'brand-white' }) => (
  <e.Layout.HStack whiteSpace='nowrap'>
    <Fade.Up>
      <c.SocialLink href={config.socialProfile.facebook} target='_blank'>
        <c.Facebook color={colors[color]} />
      </c.SocialLink>
    </Fade.Up>
    <Fade.Up>
      <c.SocialLink href={config.socialProfile.twitter} target='_blank'>
        <c.Twitter color={colors[color]} />
      </c.SocialLink>
    </Fade.Up>
    <Fade.Up>
      <c.SocialLink href={config.socialProfile.linkedIn} target='_blank'>
        <c.LinkedIn color={colors[color]} />
      </c.SocialLink>
    </Fade.Up>
  </e.Layout.HStack>
);
export default Socials;
