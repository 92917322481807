/* eslint-disable max-classes-per-file */
import { FC } from 'react';

import LogoTransparentText from '@/assets/logos/Full Logo - Transparent Text.svg';
import Logo from '@/assets/logos/Full Logo.svg';
import * as e from '@/elements';
import HoverHeader from '@/lib/HoverHeader';
import { StaggerChildren, Fade } from '@/lib/utils/animation';
import useAppState, { INestedItem, ILinkItem } from '@/state/AppState';
import { css, x } from '@/style';

import DropdownNavItem from './DropdownNavItem';
import MenuButton from './MenuButton';
import RocketButton from './RocketButton';
import * as c from './components';

const DesktopHeader: FC<{
  isAtTop: boolean;
}> = ({ isAtTop }) => {
  const { headerItems, getHeaderCss } = useAppState();
  return (
    <HoverHeader
      forceIsExpanded={isAtTop}
      hiddenCss={c.headerCssForExpandedContainer({
        defaultCss: css`
          background-color: ${isAtTop ? 'transparent' : 'palette-bg'};
        `,
        additionalCss: getHeaderCss(isAtTop),
      })}
      Left={({ inExpandedContainer }) =>
        inExpandedContainer ? (
          <x.div w='256px'>
            <e.Link variant='blank' href='/' trackName='home'>
              <Logo width='150px' />
            </e.Link>
          </x.div>
        ) : (
          <e.Link variant='blank' href='/' trackName='home'>
            <LogoTransparentText width='150px' />
          </e.Link>
        )
      }
      Center={() => (
        <c.NavContainer aria-label='Main'>
          <StaggerChildren showName='header-show' hideName='header-hide'>
            <e.Layout.HStack spacing='lg'>
              {headerItems.map((item) => {
                switch (item.type) {
                  case 'nested': {
                    const nestedItem = item as INestedItem;
                    return (
                      <Fade.Down
                        key={nestedItem.id}
                        showName='header-show'
                        hideName='header-hide'
                      >
                        <DropdownNavItem
                          ariaLabel={nestedItem.ariaLabel}
                          items={nestedItem.items}
                        >
                          {nestedItem.label}
                        </DropdownNavItem>
                      </Fade.Down>
                    );
                  }
                  case 'link': {
                    const linkItem = item as ILinkItem;
                    return (
                      <Fade.Down
                        key={linkItem.id}
                        showName='header-show'
                        hideName='header-hide'
                      >
                        <e.Link
                          variant='nav'
                          href={linkItem.href}
                          trackName={linkItem.trackName}
                        >
                          {linkItem.label}
                        </e.Link>
                      </Fade.Down>
                    );
                  }
                  default:
                    return null;
                }
              })}
            </e.Layout.HStack>
          </StaggerChildren>
        </c.NavContainer>
      )}
      Right={({ inExpandedContainer, isExpanded }) => (
        <c.CtaContainer>
          {inExpandedContainer ? (
            <e.Layout.HStack w='256px' spacing='lg' alignItems='center'>
              <RocketButton inExpandedContainer isExpanded={isExpanded} />
            </e.Layout.HStack>
          ) : (
            <e.Layout.HStack spacing='md' alignItems='center'>
              <MenuButton isVisible={!isExpanded} />
              <RocketButton isExpanded={isExpanded} />
            </e.Layout.HStack>
          )}
        </c.CtaContainer>
      )}
    />
  );
};

export default DesktopHeader;
