import loadable from '@loadable/component';

import { StyledComponent, x } from '@/style';

const sizeMap = {
  sm: '16px',
  md: '24px',
  lg: '44px',
};

const IconMap = {
  briefcase: loadable(() => import('@/assets/iconly/Work.svg')),
  calling: loadable(() => import('@/assets/iconly/Calling.svg')),
  emailSending: loadable(() => import('@/assets/iconly/Email Sending.svg')),
  newspaper: loadable(() => import('@/assets/iconly/Receipt Bill.svg')),
};

export type IconName = keyof typeof IconMap;

export const Icon: StyledComponent<{
  name: IconName;
  size?: keyof typeof sizeMap | string;
}> = ({ name, size = 'md', ...rest }) => {
  const IconSvg = IconMap[name];
  const maxW = (sizeMap as any)[size] || size;
  return (
    <x.span display='block' w='100%' maxW={maxW} {...rest}>
      <IconSvg width='100%' height='100%' />
    </x.span>
  );
};
