import { Section, useTimelineItem, Visibility } from '@/lib/ScrollTimeline';
import { useGetContextOrError } from '@/lib/hooks/use-get-context-or-error';

import { context } from './context';

export const useItemVisibilityTracker = (id: string) => {
  const endId = `${id}-end`;

  const { offsetTop, setOptionVisibility } = useGetContextOrError(
    context,
    'Scroll Spy'
  );

  useTimelineItem(
    () =>
      new Section({
        elements: {
          section: endId,
        },
        handlers: {
          onStatusChanged: ({ status: visibility }) => {
            const isVisible = visibility !== Visibility.BelowInvisible;
            setOptionVisibility(id, isVisible);
          },
        },
      })
  );

  return {
    id,
    endId,
    offsetTop,
  };
};
