import type { SbImage } from 'storyblok';

import { motion } from 'framer-motion';

import { resolveImageAttrs } from '@/lib/storyblok';
import { StyledComponent, x } from '@/style';

export const ArticleImage: StyledComponent<{
  layoutId?: string;
  image: SbImage;
}> = ({ layoutId, image }) => (
  <x.div
    as={motion.div}
    layoutId={layoutId}
    aspectRatio='16 / 9'
    overflow='hidden'
    borderRadius='xl'
    border='1px solid transparent'
    borderColor='palette-border'
    bg='brand-bright-white'
  >
    <x.img
      {...resolveImageAttrs(image, 640)}
      w='100%'
      h='100%'
      objectFit='cover'
      style={{
        objectPosition: 'center',
      }}
    />
  </x.div>
);
