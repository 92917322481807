import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { StyledComponent, x } from '@/style';

export const RelativeContainer: StyledComponent = ({ children, ...rest }) => (
  <x.div p='md' {...rest} w='100%' display='flex' justifyContent='center'>
    <AnimateOnVisible>
      <Fade.Up>{children}</Fade.Up>
    </AnimateOnVisible>
  </x.div>
);
