import TimelineItem from './TimelineItem';
import List from './components/List';
import { INextTriggers, IQueuePositionManager, IScrollStatus } from './types';

interface IQueueItem {
  trigger: number;
  item: TimelineItem;
}
export default class Queue {
  private _allItems: List<TimelineItem> = new List();
  private _queuePositionManager: IQueuePositionManager;
  private _queue: Array<IQueueItem> = [];

  constructor(queuePositionManager: IQueuePositionManager) {
    this._queuePositionManager = queuePositionManager;
  }

  private get _queueInfo() {
    return [...this._queue].map((i) => ({
      trigger: i.trigger,
      id: i.item.id,
      item: i.item,
    }));
  }

  public get queue() {
    return [...this._queue];
  }
  public get allItems() {
    return this._allItems.toArray();
  }
  public get nextTrigger() {
    return this._queue.length > 0 ? this._queue[0].trigger : null;
  }

  private _sort = (items: Array<IQueueItem>) =>
    items.sort((a, b) =>
      this._queuePositionManager.isBefore(a.trigger, b.trigger) ? -1 : 1
    );

  private _isTriggerBreached = (currentPos: number) => {
    if (this.nextTrigger === null) return false;

    return this._queuePositionManager.isBefore(this.nextTrigger, currentPos);
  };

  private _placeItemInQueue = (position: number | null, item: TimelineItem) => {
    if (position !== null) {
      if (this._queue.length === 0) {
        this._queue.push({
          trigger: position,
          item,
        });
      } else {
        let index = 0;
        while (
          this._queue.length > index &&
          this._queuePositionManager.isBefore(
            this._queue[index].trigger,
            position
          )
        ) {
          index += 1;
        }
        this._queue.splice(index, 0, {
          trigger: position,
          item,
        });
      }
    }
  };

  deregister = (item: TimelineItem) => {
    this._allItems.removeIfExists(item);

    const queueIndex = this._queue.findIndex((i) => i.item.id === item.id);
    if (queueIndex > -1) {
      this._queue.splice(queueIndex, 1);
      return true;
    }
    return false;
  };

  register = (item: TimelineItem, scrollStatus: IScrollStatus) => {
    this._allItems.addIfNotExists(item);

    const nextTriggers = item.register(scrollStatus);

    const nextTrigger = this._queuePositionManager.getTrigger(nextTriggers);

    this._placeItemInQueue(nextTrigger, item);
    return () => this.deregister(item);
  };

  trigger = (scrollStatus: IScrollStatus) => {
    if (this._isTriggerBreached(scrollStatus.currentPos)) {
      const changedTriggers: Array<{
        item: TimelineItem;
        nextTriggers: INextTriggers | null;
      }> = [];
      const updatedQueue = this._queue
        .map(({ item, trigger }) => {
          if (
            this._queuePositionManager.isBefore(
              trigger,
              scrollStatus.currentPos
            )
          ) {
            const nextTriggers = item.trigger(scrollStatus);
            changedTriggers.push({
              item,
              nextTriggers,
            });
            return {
              trigger: this._queuePositionManager.getTrigger(nextTriggers),
              item,
            };
          }
          return {
            trigger,
            item,
          };
        })
        .filter((i) => i.trigger !== null);
      this._queue = this._sort(updatedQueue as Array<IQueueItem>);

      return changedTriggers;
    }
    return null;
  };

  /**
   * For each updated item this will check if the item is
   * in the queue
   * 1. If it isn't then it will be added
   * 2. If it is but has the same trigger then nothing will be changed
   * 3. If it is but has a different trigger then it will be removed and
   *    the updated item will be inserted
   * @param updatedItems The list of items to be inserted into the queue
   */
  updateQueue = (
    updatedItems: Array<{
      item: TimelineItem;
      nextTriggers: INextTriggers | null;
    }>
  ) => {
    updatedItems.forEach((updatedItem) => {
      const updatedTrigger = this._queuePositionManager.getTrigger(
        updatedItem.nextTriggers
      );
      const existingQueueItemIndex = this._queue.findIndex(
        ({ item }) => item.id === updatedItem.item.id
      );

      const isInQueue = existingQueueItemIndex > -1;
      if (isInQueue) {
        const existingQueueItem = this._queue[existingQueueItemIndex];

        if (updatedTrigger === null) {
          // remove the item
          this._queue.splice(existingQueueItemIndex, 1);
        } else {
          const isMatchingTrigger =
            updatedTrigger === existingQueueItem.trigger;
          if (!isMatchingTrigger) {
            // remove the item
            this._queue.splice(existingQueueItemIndex, 1);
            // place new item in the queue
            this._placeItemInQueue(updatedTrigger, updatedItem.item);
          }
        }
      } else {
        this._placeItemInQueue(updatedTrigger, updatedItem.item);
      }
    });
  };
}
