import type { SbEditableContent } from 'storyblok';

import { useCallback } from 'react';

import { useGetPathFromPageId } from '@/lib/storyblok/path-from-page-id';
import { reportError } from '@/services/report';

import { getArticleStatus } from './get-article-status';

import { ArticleProps } from '../types';

class ArticleStoryFormatter {
  constructor(
    private readonly _config: {
      content: SbEditableContent<ArticleProps>;
      getPathFromPageId: (pageId: string) => string | null;
    }
  ) {}

  private get _articleOutline() {
    return this._config.content;
  }
  private get _articleContent() {
    return this._articleOutline.content[0];
  }
  private get _status() {
    return getArticleStatus(this._articleOutline);
  }
  private get _id() {
    return this._articleOutline._uid;
  }
  private get _imageLayoutId() {
    return `article-image-${this._id}`;
  }
  private get _titleLayoutId() {
    return `article-title-${this._id}`;
  }
  private get _title() {
    return this._articleOutline.title;
  }
  private get _headlineImage() {
    return this._articleOutline.headlineImage;
  }
  private get _content() {
    return this._articleContent.content;
  }

  private get _socials() {
    return this._articleContent?.socials?.length
      ? this._articleContent.socials[0]
      : null;
  }

  private _formatReadTime = () => parseInt(this._articleContent.readTime, 10);

  private _getArticleOutline = () => ({
    id: this._id,
    status: this._status,
    title: this._title,
    headlineImage: this._headlineImage,
    titleLayoutId: this._titleLayoutId,
    imageLayoutId: this._imageLayoutId,
  });

  private _getCtaSection = () => ({
    title: this._articleContent.ctaTitle,
    content: this._articleContent.ctaContent,
    ctas: this._articleContent.ctas,
  });

  private _getSuggestions = () => ({
    title:
      this._articleContent.recommendedArticlesTitle || 'What to read next?',
    articles: this._articleContent.recommendedArticles,
  });

  private _getSocials = () => ({
    ...this._socials,
  });

  private _formatPublishedArticle = () => ({
    ...this._getArticleOutline(),
    recommendedParentNav: this._articleContent.recommendedParentNav,
    readTime: this._formatReadTime(),
    content: this._content,
    ctaSection: this._getCtaSection(),
    suggestions: this._getSuggestions(),
    socials: this._getSocials(),
  });

  private _formatDraftArticle = () => ({
    ...this._getArticleOutline(),
  });

  private _formatArticle = () => {
    switch (this._status) {
      case 'draft':
        return this._formatDraftArticle();
      case 'published':
        return this._formatPublishedArticle();
      default: {
        reportError(`Article status ${this._status} unrecognised`);
        return this._formatDraftArticle();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly formattedArticle = this._formatArticle();
}

export type DraftArticleContent = ReturnType<
  ArticleStoryFormatter['_formatDraftArticle']
>;
export type PublishedArticleContent = ReturnType<
  ArticleStoryFormatter['_formatPublishedArticle']
>;

export type ArticleContent = DraftArticleContent | PublishedArticleContent;

export const useArticleStoryFormatter = () => {
  const { getPathFromPageId } = useGetPathFromPageId();

  const formatArticleStory = useCallback(
    <T extends ArticleContent>(content: SbEditableContent<ArticleProps>) =>
      new ArticleStoryFormatter({
        content,
        getPathFromPageId,
      }).formattedArticle as T,
    []
  );

  return {
    formatArticleStory,
  };
};
