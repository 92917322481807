import type { TTextColumnContent } from 'storyblok';

import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { Icon, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade, time } from '@/lib/utils/animation';
import { x } from '@/style';

import * as c from './components';

const Grid: FC<{
  items: Array<TTextColumnContent>;
}> = ({ items }) => (
  <AnimateOnVisible staggerChildren={0}>
    <e.Layout.Grid
      columnGap='lg'
      $columnCount={items.length}
      textAlign='left'
      mt='lg'
    >
      {items.map((item, index) => (
        <SbEditable content={item} key={item.iconName}>
          <c.Grid.Icon gridColumn={index + 1}>
            <Fade.Up transition={{ delay: index * time.md }}>
              <Icon name={item.iconName} />
            </Fade.Up>
          </c.Grid.Icon>
          <e.Text variant='h4' gridRow='2' gridColumn={index + 1}>
            <Fade.Up transition={{ delay: index * time.md }}>
              {item.title}
            </Fade.Up>
          </e.Text>
          <x.div gridRow='3' gridColumn={index + 1}>
            <Fade.Up transition={{ delay: index * time.md }}>
              {item.subtitle ? (
                <e.Text variant='body1' fontWeight={'700 !important' as any}>
                  {item.subtitle}
                </e.Text>
              ) : null}
              <RichText>{item.content}</RichText>
            </Fade.Up>
          </x.div>
        </SbEditable>
      ))}
    </e.Layout.Grid>
  </AnimateOnVisible>
);

export default Grid;
