import type { SeoPlugin } from 'storyblok';

import { FC } from 'react';

import Head from 'next/head';

import SocialMeta from '@/lib/snippets/socialMeta';

const Seo: FC<{
  content: SeoPlugin;
}> = ({ content }) => (
  <Head>
    <SocialMeta
      title={content.title}
      description={content.description}
      ogDescription={content.og_description}
      ogImage={content.og_image}
      ogTitle={content.og_title}
      twitterDescription={content.twitter_description}
      twitterImage={content.twitter_image}
      twitterImageAlt={content.twitter_title}
      twitterTitle={content.twitter_title}
    />
  </Head>
);

export default Seo;
