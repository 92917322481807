import BurgerSvg from '@/assets/icons/Burger.svg';
import { styled, css } from '@/style';

export const BurgerContainer = styled.div<{
  $isVisible: boolean;
}>`
  overflow: hidden;
  ${({ $isVisible }) =>
    $isVisible
      ? css`
          width: 16px;
          opacity: 1;
          transition: fast;
        `
      : css`
          width: 0;
          opacity: 0;
          transition: base;
        `}
`;

export const BurgerButton = styled(BurgerSvg)`
  height: 16px;
  width: 16px;
  color: palette-primary;
`;
