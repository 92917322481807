import { FC, createContext, useContext } from 'react';

import useAccordion from './containerContext';

const context = createContext<{
  id: string;
  isSelected: boolean;
  toggleSelected: () => void;
  setSelected: (isSelected: boolean) => void;
  close: () => void;
  open: () => void;
}>(undefined as any);

export const Provider: FC<{
  id: string;
}> = ({ id, children }) => {
  const { selectedItemId, setSelectedItemId } = useAccordion();
  const isSelected = id === selectedItemId;
  const open = () => setSelectedItemId(id);
  const close = () => setSelectedItemId(undefined);
  const toggleSelected = () => {
    if (isSelected) {
      close();
    } else {
      open();
    }
  };
  const setSelected = (newIsSelected: boolean) => {
    if (newIsSelected) setSelectedItemId(id);
    else setSelectedItemId(undefined);
  };
  return (
    <context.Provider
      value={{ id, isSelected, toggleSelected, setSelected, close, open }}
    >
      {children}
    </context.Provider>
  );
};

export default function useAccordionItem(name = 'useAccordionItem') {
  const c = useContext(context);
  if (!c) {
    throw new Error(`${name} must be used within a AccordionItem`);
  }
  return c;
}
