import createDynamicComponents from '@/lib/storyblok/createDynamicComponent';

import AbstractShapesHero from './AbstractShapesHero';
import { PartnerHero } from './PartnerHero';
import PlainHero from './PlainHero';
import { ScreenshotHero } from './ScreenshotHero';

export const components = {
  AbstractShapesHero,
  PartnerHero,
  PlainHero,
  ScreenshotHero,
};

type TComponent = typeof components;
type TKeys = keyof TComponent;
type TValues = TComponent[TKeys];

export default createDynamicComponents<TValues>(components);
