import * as e from '@/elements';
import { x, StyledComponent } from '@/style';

import { MenuItem } from './menu-item';
import { Option } from './types/option';

export const DiamondMenu: StyledComponent<{
  activeId?: string;
  options: Array<Option>;
  onOptionSelected?: (id: string, option: Option) => void;
  showLabel?: boolean;
  isCondensed?: boolean;
}> = ({
  activeId,
  options,
  onOptionSelected,
  showLabel,
  isCondensed = false,
  ...rest
}) => (
  <x.div {...rest}>
    <e.Layout.VStack dir='column' alignItems='flex-start' spacing='0'>
      {options.map((option, index) => (
        <MenuItem
          key={option.id}
          activeId={activeId}
          option={option}
          isLastOption={index === options.length - 1}
          showLabel={showLabel}
          isCondensed={isCondensed}
        />
      ))}
    </e.Layout.VStack>
  </x.div>
);
