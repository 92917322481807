import { FC } from 'react';

import * as c from './components';
import useScrollTracking from './useScrollTracking';

const ScrollPagingView: FC<{
  pages: Array<string>;
  setActivePageId: (id: string) => void;
  scrollTriggerDistance?: number;
}> = ({ pages, setActivePageId, scrollTriggerDistance = 200, children }) => {
  useScrollTracking({
    pages,
    onChange: setActivePageId,
  });
  return (
    <>
      <c.ScrollContainer>
        {pages.map((id) => (
          <c.ScrollSection
            key={id}
            id={id}
            $sectionHeight={scrollTriggerDistance}
          />
        ))}
      </c.ScrollContainer>
      <c.FixedContainer>{children}</c.FixedContainer>
    </>
  );
};

export default ScrollPagingView;
