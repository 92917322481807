import { styled, css } from '@/style';

export const FixedContainer = styled.divBox`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

export const ScrollContainer = styled.divBox`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;

  /* pointer-events: none; */
`;
export const ScrollSection = styled.divBox<{
  $sectionHeight: number;
}>`
  width: 100%;
  height: ${({ $sectionHeight }) => $sectionHeight}px;
  :last-child {
    height: 100vh;
  }
`;

export const cssOnVisible = css`
  button,
  a {
    pointer-events: auto;
  }
`;
