import { motion } from 'framer-motion';

import StepNumberSvg from '@/assets/general/StepNumber.svg';
import * as e from '@/elements';
import { css, styled } from '@/style';

// eslint-disable-next-line import/prefer-default-export
export const Step = {
  Container: styled(e.Layout.HStack)<{
    $isReverse?: boolean;
    $layout?: 'row' | 'column';
  }>`
    display: flex;

    align-items: flex-start;
    position: relative;

    .overlay-image {
      transition: base;
      transform: scale(1);
      /* transform: rotateY(0); */
      --scale: 2;
      &:nth-child(2) {
        --pos: 1;
      }
      &:nth-child(3) {
        --pos: 2;
      }
      &:nth-child(4) {
        --pos: 3;
      }
      &:nth-child(5) {
        --pos: 6;
      }
      &:nth-child(6) {
        --pos: 7;
      }
      &:nth-child(7) {
        --pos: 8;
      }
      &:nth-child(8) {
        --pos: 9;
      }
      &:nth-child(9) {
        --pos: 10;
      }
      &:nth-child(10) {
        --pos: 11;
      }
    }
    :hover {
      & .overlay-image {
        ${({ $disableIncrementalScaling }) =>
          $disableIncrementalScaling
            ? css`
                transform: scale(1.075);
              `
            : css`
                transform: scale(calc(1 + var(--pos) / 40));
              `}
        transition-delay: calc(var(--pos) * 0.03s);
      }
    }
  `,
  Column: styled.divBox`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  TextContainer: styled.divBox`
    flex: 1;
  `,
  ImageContainer: styled.divBox`
    flex: 1;
    margin: lg auto;
    position: relative;
    perspective: 250px;
    max-width: 400px;
    align-self: center;
  `,
  FixedImage: styled.divBox`
    width: 100%;
  `,
  OverlayImage: styled.divBox`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  Image: styled.imgBox`
    width: 100%;
    height: 100%;
    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
  `,
  NumberContainer: styled.divBox`
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: md;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  NumberAnimation: styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  `,
  numberContainerVariants: {
    show: {
      opacity: 1,
      scale: 1,
    },
    hide: {
      opacity: 0,
      scale: 0.4,
    },
  },
  NumberBackground: styled(StepNumberSvg)`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
  Number: styled.divBox`
    position: relative;
    z-index: 1;
    color: palette-text;
    font-size: 16px;
    line-height: 1rem;
    font-weight: 700;
  `,
  Svg: styled.svgBox`
    position: absolute;
    pointer-events: none;
    color: palette-primary;
  `,
  Path: styled(motion.path)`
    stroke: currentColor;
    fill: transparent;
  `,
};
