import { FC, useCallback } from 'react';

import { motion } from 'framer-motion';

import * as Accordion from '@/lib/Accordion';
import type { INestedItem } from '@/state/AppState';
import { x } from '@/style';

import { ButtonNavItem } from './button-nav-item';
import * as c from './components';
import { LinkNavItem } from './link-nav-item';
import { NestedNavItem } from './nested-nav-item';

type TProps = {
  content: INestedItem;
  onSelect: () => void;
  closeDrawer: () => any;
};

const DropdownNavItemContent: FC<TProps> = ({
  content: { label, ariaLabel, items },
  onSelect,
  closeDrawer,
}) => {
  const { isSelected, close } = Accordion.useAccordionItem();
  const afterClick = useCallback(() => {
    onSelect();
    close();
    closeDrawer();
  }, [onSelect, closeDrawer, close]);
  return (
    <>
      <Accordion.Button w='100%' aria-label={ariaLabel}>
        <c.ListItemContent $isActive={isSelected}>
          <c.ListItemText>{label}</c.ListItemText>
          <motion.div
            variants={{
              'accordion-show': { rotate: 180 },
              'accordion-hide': { rotate: 0 },
            }}
          >
            <c.AccordionToggleIcon />
          </motion.div>
        </c.ListItemContent>
      </Accordion.Button>
      <Accordion.Content>
        <x.div borderBottom='1px solid' borderBottomColor='palette-border'>
          {items.map((item) => {
            switch (item.type) {
              case 'link':
                return (
                  <LinkNavItem
                    key={item.id}
                    {...item}
                    afterClick={afterClick}
                  />
                  // onSelect();
                  // close();
                );
              case 'button':
                return (
                  <ButtonNavItem
                    key={item.id}
                    {...item}
                    afterClick={afterClick}
                  />
                );
              case 'nested':
                return (
                  <NestedNavItem
                    key={item.id}
                    {...item}
                    afterClick={afterClick}
                  />
                );
              default:
                throw new Error(
                  `Type "${(item as any).type}" is not recognised`
                );
            }
          })}
        </x.div>
      </Accordion.Content>
    </>
  );
};

const DropdownNavItem: FC<TProps> = (props) => (
  // eslint-disable-next-line react/destructuring-assignment
  <Accordion.Item id={props.content.id}>
    <DropdownNavItemContent {...props} />
  </Accordion.Item>
);

export default DropdownNavItem;
