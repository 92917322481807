import type { SbComponent, SbRichText, SbImage } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import SectionTitle from '@/common/SectionTitle';
import {
  RichText,
  RichSentence,
  TRichSentence,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { resolveImageAttrs } from '@/lib/storyblok';
import { OnVisible } from '@/lib/utils/animation';

import * as c from './components';

const QuoteSection: SbComponent<{
  title: TRichSentence;
  preParagraph?: SbRichText;
  authorPic: SbImage;
  authorName: string;
  authorRole: string;
  comment: SbRichText;
  postParagraph?: SbRichText;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section maxWidth='section-width'>
      <e.Layout.VStack spacing='lg'>
        <SectionTitle>
          <OnVisible.FadeUp>
            <RichSentence variant='h2'>{content.title}</RichSentence>
          </OnVisible.FadeUp>
        </SectionTitle>
        {content.preParagraph ? (
          <OnVisible.FadeUp>
            <RichText>{content.preParagraph}</RichText>
          </OnVisible.FadeUp>
        ) : null}

        <OnVisible.FadeUp>
          <c.QuoteContainer>
            <c.AuthorContainer>
              <c.AuthorPic
                alt={`A profile picture of ${content.authorName}`}
                {...resolveImageAttrs(content.authorPic, 360)}
              />
              <c.AuthorText>
                <c.AuthorName>{content.authorName}</c.AuthorName>
                <c.AuthorRole>{content.authorRole}</c.AuthorRole>
              </c.AuthorText>
              <c.QuoteMark />
            </c.AuthorContainer>
            <c.AuthorComment>
              <RichText>{content.comment}</RichText>
            </c.AuthorComment>
          </c.QuoteContainer>
        </OnVisible.FadeUp>

        {content.postParagraph ? (
          <OnVisible.FadeUp>
            <RichText>{content.postParagraph}</RichText>
          </OnVisible.FadeUp>
        ) : null}
      </e.Layout.VStack>
    </Section>
  </SbEditable>
);

export default QuoteSection;
