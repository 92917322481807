import type { HomeViewComponent } from './types';

import useHasMounted from '@/lib/hooks/useHasMounted';
import { x, useUp } from '@/style';

import DesktopView from './DesktopView';
import MobileView from './MobileView';

const HomeView: HomeViewComponent = (props) => {
  const breakpoint = 'tablet';
  const hasMounted = useHasMounted();
  const isBig = useUp(breakpoint);
  return (
    <>
      <x.div display={{ _: 'block', [breakpoint]: 'none' }}>
        {!hasMounted || !isBig ? <MobileView {...props} /> : null}
      </x.div>
      <x.div display={{ _: 'none', [breakpoint]: 'block' }}>
        {!hasMounted || isBig ? <DesktopView {...props} /> : null}
      </x.div>
    </>
  );
};
// const HomeView = () => <DesktopView content={pageContent} />

export default HomeView;
