import { FC } from 'react';

import { motion } from 'framer-motion';

import { time } from '@/lib/utils/animation';
import { useSize, x } from '@/style';

import { useContext } from './use-context';

export const MenuScreen: FC = ({ children }) => {
  const { isOpen, animationOpenName, animationClosedName, setIsOpen } =
    useContext();
  const buttonHeight = useSize('button');
  return (
    <x.div
      fixedFull
      bottom={buttonHeight}
      display='flex'
      alignItems='flex-end'
      justifyContent='stretch'
      pointerEvents={isOpen ? 'auto' : 'none'}
    >
      <x.div
        absoluteFull
        as={motion.div}
        bg='overlay'
        onClick={() => setIsOpen(false)}
        variants={{
          [animationOpenName]: {
            opacity: 1,
          },
          [animationClosedName]: {
            opacity: 0,
          },
        }}
      />
      <x.div
        position='relative'
        w='100%'
        pointerEvents='none'
        as={motion.div}
        pt={buttonHeight}
        maxH={`calc(100vh - ${buttonHeight})`}
        overflow='auto'
        variants={{
          [animationOpenName]: {
            bottom: 0,
            opacity: 1,
            transition: {
              type: 'tween',
              ease: 'easeOut',
              staggerChildren: time.sm,
              delayChildren: -time.md,
              when: 'beforeChildren',
            },
          },
          [animationClosedName]: {
            bottom: '-100vh',
            opacity: 0,
            transition: {
              type: 'tween',
              ease: 'easeOut',
            },
          },
        }}
      >
        <x.div pointerEvents='auto'>{children}</x.div>
      </x.div>
    </x.div>
  );
};
