import { motion } from 'framer-motion';
import { FlattenSimpleInterpolation } from 'styled-components';

import RightArrow from '@/assets/iconly/Arrow - Right.svg';
import * as e from '@/elements';
import { styled, css } from '@/style';

export const HeaderContainer = styled.headerBox`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: overlay;
  display: flex;
  justify-content: center;
  align-items: center;
  height: header;
  padding-left: lg;
  padding-right: lg;
`;

export const HeaderContent = styled.divBox`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const NavContainer = styled.navBox`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  top: 2px;
`;

export const CtaContainer = styled.divBox`
  display: flex;
  align-items: center;
`;

export const headerCssForExpandedContainer = ({
  defaultCss,
  additionalCss,
}: {
  defaultCss: FlattenSimpleInterpolation;
  additionalCss: FlattenSimpleInterpolation | null;
}) => css`
  ${defaultCss}
  ${additionalCss || undefined}
  border-bottom-color: palette-border;
`;

const LinkArrow = styled(RightArrow)`
  width: 20px;
  height: 20px;
  position: relative;
  left: 0;
  transition: base;
  margin-right: md;
  top: -1px;
`;
export const Dropdown = {
  Container: styled.divBox`
    background-color: palette-bg;
    border-radius: lg;
    color: palette-text;
    box-shadow: lg;
    padding: md 0;
  `,
  Row: styled.divBox`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: sm lg;
    color: palette-text;
    transition: base;
    font-weight: 600;
    color: palette-primary;
    font-size: md;
    line-height: md;
    ${LinkArrow} {
      opacity: 0;
    }
    /* :hover {
      color: palette-primary;
      ${LinkArrow} {
        left: 4px;
      }
    } */
  `,
  RowLink: styled(e.BlankLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: sm lg;
    color: palette-text;
    transition: base;
    font-weight: 600;
    font-size: md;
    line-height: md;
    :hover {
      color: palette-primary;
      ${LinkArrow} {
        left: 4px;
      }
    }
  `,
  LinkArrow,
  LinkText: styled.divBox``,
  Overlay: styled((props) => (
    <motion.div
      {...props}
      variants={{
        'header-show': {
          display: 'block',
          opacity: 1,
          transition: {
            when: 'afterChildren',
          },
          transitionEnd: {
            opacity: 0,
            display: 'none',
          },
        },
        'header-hide': {
          opacity: 1,
          display: 'block',
        },
      }}
    />
  ))`
    position: absolute;
    z-index: infront;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
  `,
};
