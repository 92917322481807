import type { SbComponent, SbImage, SbRichText, TCtaContent } from 'storyblok';

import { motion } from 'framer-motion';
import SbEditable from 'storyblok-react';
import { css } from 'styled-components';

import { Section } from '@/common/Layout';
import ScrollHr from '@/common/ScrollHr';
import { useScrollAdjustment } from '@/common/hooks/use-scroll-adjustment';
import { CtaRow, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { useHeaderCss } from '@/state/AppState/use-header-css';
import { useScreens, useSize, x } from '@/style';

export const ScreenshotHero: SbComponent<{
  title: string;
  subtitle: string;
  description: SbRichText;
  ctas?: Array<TCtaContent>;
  desktopScreenshot: SbImage;
  mobileScreenshot: SbImage;
}> = ({ content }) => {
  const headerHeight = useSize('header') as string;
  const { tablet } = useScreens();

  const screenshotAdjustment = useScrollAdjustment({
    from: [0, 2000],
    to: [20, -200],
  });

  useHeaderCss(
    () =>
      css`
        background-color: palette-bg;
      `
  );
  return (
    <SbEditable content={content}>
      <AnimateOnVisible>
        <x.div pt={{ _: '0', tablet: headerHeight }} overflow='hidden'>
          <x.div position='absolute' />
          <Section maxWidth='section-width-xl' px={0}>
            <e.Layout.Stack
              flexDirection={{
                _: 'column',
                tablet: 'row',
              }}
              gap={{
                _: 'xl',
                tablet: 'md',
              }}
              alignItems='center'
            >
              <x.div flex='1' position='relative' zIndex='1'>
                <e.Layout.VStack
                  mx='auto'
                  px='md'
                  pt='xl'
                  maxW='section-width-sm'
                >
                  <Fade.Up>
                    <e.Text variant='h1'>{content.title}</e.Text>
                  </Fade.Up>
                  <Fade.Up>
                    <e.Text
                      variant='subtitle1'
                      as={x.h3}
                      color='palette-primary'
                    >
                      {content.subtitle}
                    </e.Text>
                  </Fade.Up>
                  <Fade.Up>
                    <RichText>{content.description}</RichText>
                  </Fade.Up>
                  {content.ctas?.length ? (
                    <Fade.Up>
                      <CtaRow
                        ctas={content.ctas}
                        mt='md'
                        justifyContent='flex-start'
                      />
                    </Fade.Up>
                  ) : null}
                </e.Layout.VStack>
              </x.div>
              <x.div
                flex='1'
                alignSelf='stretch'
                display='flex'
                alignItems='center'
              >
                <x.picture
                  as={motion.picture as any}
                  position={{ _: 'relative', tablet: 'absolute' }}
                  w={{ _: '94%', tablet: '54%' }}
                  h={{ _: '94%', tablet: 'unset' }}
                  overflow={{ _: 'hidden', tablet: 'visible' }}
                  mx='auto'
                  style={{ y: screenshotAdjustment }}
                  variants={{
                    show: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: 'tween',
                        duration: 0.6,
                        opacity: {
                          duration: 0.4,
                          delay: 0.6,
                        },
                      },
                    },
                    hide: {
                      y: 30,
                      opacity: 0,
                      transition: {
                        type: 'tween',
                        duration: 0.6,
                      },
                    },
                  }}
                >
                  <x.source
                    media={`(min-width:${tablet}px)`}
                    srcSet={content.desktopScreenshot.filename}
                  />
                  <x.img w='100%' src={content.mobileScreenshot.filename} />
                </x.picture>
              </x.div>
            </e.Layout.Stack>
          </Section>
        </x.div>
        <x.div mb={headerHeight} position='relative' bottom='23px'>
          <ScrollHr position='inline' />
        </x.div>
      </AnimateOnVisible>
    </SbEditable>
  );
};
