import { INestedItem } from '@/state/AppState/types';

import { ButtonNavItem } from './button-nav-item';
import * as c from './components';
import { LinkNavItem } from './link-nav-item';

export const NestedNavItem = ({
  label,
  items,
  afterClick,
}: INestedItem & {
  afterClick: () => any;
}) => (
  <>
    <c.AccordionChildAnimation>
      <c.AccordionListItem color='palette-primary' fontWeight='600'>
        {label}
        {/* <c.AccordionArrowRight /> */}
      </c.AccordionListItem>
    </c.AccordionChildAnimation>
    {items.map((item) => {
      switch (item.type) {
        case 'link': {
          return (
            <LinkNavItem key={item.id} {...item} afterClick={afterClick} />
          );
        }
        case 'button': {
          return (
            <ButtonNavItem key={item.id} {...item} afterClick={afterClick} />
          );
        }
        default:
          throw new Error('Not Implemented');
      }
    })}
  </>
);
