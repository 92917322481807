import type { IJourneyStepContent } from '../types.d';

import { FC } from 'react';

import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';

import ImportantStepContent from './Important';
import RegularStepContent from './Regular';

const DynamicStepContent: FC<{
  step: IJourneyStepContent;
  stepNo: number;
}> = ({ step, stepNo }) => {
  const isLargeScreen = useIsUpAndMounted('tablet');
  const isAlternatingEnabled = isLargeScreen;
  const isReverse = isAlternatingEnabled ? stepNo % 2 === 0 : false;

  const showStepAsImportant = step.isImportant;

  if (showStepAsImportant) {
    return <ImportantStepContent step={step} stepNo={stepNo} />;
  }
  return (
    <RegularStepContent isReverse={isReverse} step={step} stepNo={stepNo} />
  );
};

export default DynamicStepContent;
