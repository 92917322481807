import { FC } from 'react';

import { motion } from 'framer-motion';

import { Diamond } from '@/common/diamond';
import { Link, Text } from '@/elements';
import { HStack } from '@/elements/layout';
import { movement } from '@/lib/utils/animation';
import { x } from '@/style';

import { useContext } from '../use-context';

export const MenuItem: FC<{
  isActive: boolean;
  href: string;
}> = ({ isActive, href, children }) => {
  const { setIsOpen, animationOpenName, animationClosedName } = useContext();
  return (
    <x.div
      as={motion.div}
      variants={{
        [animationOpenName]: {
          y: 0,
          opacity: 1,
        },
        [animationClosedName]: {
          y: movement.sm,
          opacity: 0,
        },
      }}
      w='100%'
    >
      <Link
        onClick={() => setIsOpen(false)}
        variant='hoverColor'
        href={href}
        w='100%'
        p='md'
        textAlign='left'
      >
        <HStack alignItems='center' justifyContent='flex-start'>
          <Diamond isFilled={isActive} />
          <Text flex='1' pb='0'>
            {children}
          </Text>
        </HStack>
      </Link>
    </x.div>
  );
};
