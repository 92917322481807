import { styled } from '@/style';

export const Table = styled.tableBox`
  border: 1px solid;
  border-color: palette-border;
  border-radius: lg;
  border-spacing: 0;
`;
export const TableBody = styled.tbodyBox``;
export const Row = styled.trBox`
  border-bottom: 1px solid;
  border-color: palette-border;
  :last-child {
    border-botto§m: 0;
  }

  background-color: brand-bright-white;
  &:nth-child(even) {
    background-color: palette-subtle;
  }
`;
export const Cell = styled.tdBox`
  padding: md;
  vertical-align: top;
  border-right: 1px solid;
  border-color: palette-border;
  :last-child {
    border-right: 0;
  }
`;
export const PledgeNameCell = styled.spanBox`
  white-space: nowrap;
  font-weight: 700;
  display: flex;
  align-items: center;
`;
export const PledgeName = styled.spanBox`
  position: relative;
  top: 3px;
  margin-left: sm;
`;
