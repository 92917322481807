import type { MoreSectionProps } from '../../types.d';
import type { SbComponent } from 'storyblok';

import { motion } from 'framer-motion';

import { Icon, CtaRow, RichText } from '@/common/storyblok/components';
import { Layout, Text } from '@/elements';
import * as Accordion from '@/lib/Accordion';
import { Fade } from '@/lib/utils/animation';

import * as c from './components';

const MoreSection: SbComponent<MoreSectionProps> = ({
  content: { columns, title },
}) => (
  <Layout.VStack maxW={1200}>
    <Fade.Up transition={{ delay: 0 }}>
      <Text variant='h1'>{title}</Text>
    </Fade.Up>

    <Accordion.Container>
      <c.Rows>
        {columns.map((column, index) => (
          <Fade.Up key={column.id}>
            <Accordion.Item id={column.id}>
              <Accordion.Button style={{ width: '100%' }}>
                <c.Row lastChild={index + 1 === columns.length}>
                  <c.Icon>
                    <Icon name={column.iconName} width='40px' height='40px' />
                  </c.Icon>
                  <c.Title>{column.title}</c.Title>
                  <motion.div
                    variants={{
                      'accordion-show': { rotate: 180 },
                      'accordion-hide': { rotate: 0 },
                    }}
                  >
                    <c.Arrow />
                  </motion.div>
                </c.Row>
              </Accordion.Button>
              <Accordion.Content>
                <c.ContentContainer lastChild={index + 1 === columns.length}>
                  <c.Content>
                    <RichText>{column.content}</RichText>
                  </c.Content>
                  <CtaRow ctas={column.ctas} />
                </c.ContentContainer>
              </Accordion.Content>
            </Accordion.Item>
          </Fade.Up>
        ))}
      </c.Rows>
    </Accordion.Container>
  </Layout.VStack>
);
export default MoreSection;
