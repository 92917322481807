import pick from 'lodash/pick';

import { mapContent } from '@/lib/storyblok';

export const removeArticleContentFromCollections = (content: any): any =>
  mapContent(content, (value, identifier) => {
    if (
      identifier.isComponent('BlogArticleContent') &&
      identifier.hasParentComponent('BlogArticlesCollection')
    ) {
      return pick(value, ['_uid', 'component', 'readTime']);
    }
    return value;
  });
