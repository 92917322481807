import { motion } from 'framer-motion';

import { ILinkItem, INestedItem } from '@/state/AppState';
import { x } from '@/style';

import * as c from './components';
import { LinkNavItem } from './link-nav-item';

export const NestedNavItem = ({
  label,
  items,
  onSelect,
}: INestedItem & {
  onSelect: () => any;
}) => (
  <motion.div
    variants={{
      show: { opacity: 1, y: 0 },
      hide: { opacity: 0, y: 8 },
    }}
  >
    <c.Dropdown.Row>
      <c.Dropdown.LinkText>{label}</c.Dropdown.LinkText>
    </c.Dropdown.Row>
    <x.div>
      {items.map((item) => {
        switch (item.type) {
          case 'nested': {
            const nestedItem = item as INestedItem;
            return (
              <NestedNavItem
                key={nestedItem.id}
                {...nestedItem}
                onSelect={onSelect}
              />
            );
          }
          case 'link': {
            const linkItem = item as ILinkItem;
            return (
              <LinkNavItem
                key={linkItem.id}
                {...linkItem}
                onSelect={onSelect}
              />
            );
          }
          default:
            throw new Error('Not Implemented');
        }
      })}
    </x.div>
  </motion.div>
);
