import type { TSpritePlayer } from './types';

import { useRef, useState } from 'react';

import PositionService from './PositionService';
import SpriteSheet from './SpriteSheet';

const useSpritePlayer = <T extends string>({
  sprites,
  initialGroup,
}: {
  sprites: Record<T, SpriteSheet>;
  initialGroup: T;
}): TSpritePlayer<T> => {
  const spriteSheet = useRef<SpriteSheet>(sprites[initialGroup]);
  const [positionService] = useState(
    () => new PositionService(spriteSheet.current.count)
  );

  return {
    start: () => {
      spriteSheet.current.start();
    },
    getFrame: (spritePos: number, currentTime: number) => {
      const optimalPosition = positionService.requestPosition(
        spritePos,
        currentTime
      );

      if (spriteSheet.current)
        return spriteSheet.current.requestSpriteAt(optimalPosition);
      return null;
    },
    setSpriteGroup: (group: T) => {
      spriteSheet.current = sprites[group];
      spriteSheet.current.start();
    },
  };
};

export default useSpritePlayer;
