import type { SbComponent, SbImage, SbRichText, TCtaContent } from 'storyblok';

import { ComponentProps } from 'react';

import SbEditable from 'storyblok-react';

import { CtaRow } from '@/common/storyblok/components';
import * as e from '@/elements';
import { VStack } from '@/elements/layout';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';

import { SideScreenshot } from '../../components/side-screenshot';
import { TextRow } from '../../components/text-row';

export const ScreenshotSection: SbComponent<{
  title: string;
  subtitle: string;
  description: SbRichText;
  ctas?: Array<TCtaContent>;
  points: Array<ComponentProps<typeof TextRow>>;
  screenshot: Array<SbImage>;
  reversed: boolean;
  disableAnimation?: boolean;
  disableCard?: boolean;
}> = ({ content }) => (
  <SbEditable content={content}>
    <AnimateOnVisible>
      <SideScreenshot
        reversed={content.reversed}
        screenshot={content.screenshot}
        disableAnimation={content.disableAnimation}
        disableCard={content.disableCard}
      >
        <e.Layout.VStack mx='auto' px='md' maxW='section-width-sm'>
          <Fade.Up>
            <e.Text variant='h1'>{content.title}</e.Text>
          </Fade.Up>
          <VStack spacing='sm'>
            {content.points.map((point) => (
              <Fade.Up>
                <TextRow key={point._uid} {...point} />
              </Fade.Up>
            ))}
          </VStack>
          {content.ctas?.length ? (
            <Fade.Up>
              <CtaRow ctas={content.ctas} mt='md' justifyContent='flex-start' />
            </Fade.Up>
          ) : null}
        </e.Layout.VStack>
      </SideScreenshot>
    </AnimateOnVisible>
  </SbEditable>
);
