import { FC, ComponentProps } from 'react';

import type { TPalette } from '@/common/Palette';
// import TrackScroll from '@/lib/GTM/TrackScroll';
import { MainScrollContainer } from '@/common/main-scroll-container/scroll-container';
import { MAIN_SCROLL_CONTAINER_ID } from '@/constants';
import { TimelineProvider } from '@/lib/ScrollTimeline';
import { TrackScroll } from '@/lib/track';
import { Provider as PageProvider } from '@/state/PageState';
import { x } from '@/style';

import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';

const Page: FC<
  {
    defaultPalette?: TPalette;
    disableFooter?: boolean;
  } & ComponentProps<typeof x.main>
> = ({ defaultPalette, disableFooter, children, ...rest }) => (
  <>
    <PageProvider defaultPalette={defaultPalette}>
      <TimelineProvider containerId={MAIN_SCROLL_CONTAINER_ID}>
        <Header>
          <MainScrollContainer id={MAIN_SCROLL_CONTAINER_ID}>
            <Main {...rest}>{children}</Main>
            {disableFooter ? null : (
              <TrackScroll name='footer' trackEnter>
                <Footer isFooterVisible />
              </TrackScroll>
            )}
          </MainScrollContainer>
        </Header>
      </TimelineProvider>
    </PageProvider>
  </>
);

export default Page;
