import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import { Icon } from '@/common/icon';
import { RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { HStack, Stack, VStack } from '@/elements/layout';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

const ContactDetailsSection: SbComponent<{
  title: string;
  subtitle?: string;
  description?: string;
  phone?: string;
  email?: string;
}> = ({ content }) => {
  const { title, subtitle, description, phone, email } = content;
  return (
    <SbEditable content={content}>
      <AnimateOnVisible>
        <Section>
          <e.Layout.VStack textAlign='center' mx='auto' maxW='section-width-sm'>
            {subtitle ? (
              <Fade.Up>
                <e.Text variant='subtitle1' as='h3' color='palette-primary'>
                  {subtitle}
                </e.Text>
              </Fade.Up>
            ) : null}
            <Fade.Up>
              <e.Text variant='h3'>{title}</e.Text>
            </Fade.Up>
            {description ? (
              <Fade.Up>
                <x.div my='md'>
                  <RichText>{description}</RichText>
                </x.div>
              </Fade.Up>
            ) : null}
            <Stack
              mx='auto'
              spacing={{ _: 'md', phablet: 'xl' }}
              flexDirection={{ _: 'column', phablet: 'row' }}
            >
              {phone ? (
                <Fade.Up>
                  <HStack alignItems='center'>
                    <Icon name='calling' size='lg' color='palette-primary' />
                    <VStack alignItems='flex-start' spacing='0'>
                      <e.Text variant='bodyBig' fontWeight='600' pb='0'>
                        Phone
                      </e.Text>
                      <e.Link variant='simpleLink' href={`tel:${phone}`}>
                        {phone}
                      </e.Link>
                    </VStack>
                  </HStack>
                </Fade.Up>
              ) : null}
              {email ? (
                <Fade.Up>
                  <HStack alignItems='center'>
                    <Icon
                      name='emailSending'
                      size='lg'
                      color='palette-primary'
                    />
                    <VStack alignItems='flex-start' spacing='0'>
                      <e.Text variant='bodyBig' fontWeight='600' pb='0'>
                        Email
                      </e.Text>
                      <e.Link variant='simpleLink' href={`mailto:${email}`}>
                        {email}
                      </e.Link>
                    </VStack>
                  </HStack>
                </Fade.Up>
              ) : null}
            </Stack>
          </e.Layout.VStack>
        </Section>
      </AnimateOnVisible>
    </SbEditable>
  );
};

export default ContactDetailsSection;
