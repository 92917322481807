export default class ElementPositionManager {
  private readonly _id: string;

  constructor(id: string) {
    this._id = id;
  }

  public get id() {
    return this._id;
  }

  public getElement(): HTMLElement | null {
    return document.getElementById(this._id) || null;
  }
}
