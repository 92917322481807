import type { SbEditableContent } from 'storyblok';

import { StoryData } from 'storyblok-js-client';

import { ArticleProps } from '../types';

export const getArticleStatus = (content: SbEditableContent<ArticleProps>) =>
  content.content[0] ? 'published' : 'draft';

export const getArticleStatusFromStory = (story: StoryData<any>) =>
  getArticleStatus(story.content);
