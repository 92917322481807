import { ComponentProps, FC } from 'react';

import loadable from '@loadable/component';

import * as logger from '@/services/log';
import { x } from '@/style';

export const IconMap = {
  activity: loadable(() => import('@/assets/iconly/Activity.svg')),
  add: loadable(() => import('@/assets/icons/Add.svg')),
  approvedDocument: loadable(
    () => import('@/assets/icons/Approved Document.svg')
  ),
  boardDocument: loadable(() => import('@/assets/iconly/Board Document.svg')),
  bookStackNextTo: loadable(
    () => import('@/assets/icons/Book Stack Next To.svg')
  ),
  bookStackOnTopOf: loadable(
    () => import('@/assets/icons/Book Stack On Top Of.svg')
  ),
  cardWalletDouble: loadable(
    () => import('@/assets/iconly/Card Wallet Double.svg')
  ),
  charity: loadable(() => import('@/assets/iconly/Charity.svg')),
  chartIncrease: loadable(() => import('@/assets/icons/Chart Increase.svg')),
  clap: loadable(() => import('@/assets/iconly/Clap.svg')),
  clipboard2Lines: loadable(
    () => import('@/assets/iconly/Clipboard 2 Lines.svg')
  ),
  coinStack: loadable(() => import('@/assets/iconly/Coin Stack.svg')),
  countdownCalendar: loadable(
    () => import('@/assets/icons/Countdown Calendar.svg')
  ),
  documentInvestigation: loadable(
    () => import('@/assets/icons/Document Investigation.svg')
  ),
  eye: loadable(() => import('@/assets/icons/Eye.svg')),
  feather: loadable(() => import('@/assets/iconly/Feather.svg')),
  fireworks: loadable(() => import('@/assets/icons/Fireworks.svg')),
  folderFileOpen: loadable(
    () => import('@/assets/iconly/Folder File Open.svg')
  ),
  folderFileLabelled: loadable(() => import('@/assets/iconly/Folder File.svg')),
  giveDocument: loadable(() => import('@/assets/iconly/Give Document.svg')),
  handshake: loadable(() => import('@/assets/icons/Handshake.svg')),
  homeSearch: loadable(() => import('@/assets/iconly/Home - Search.svg')),
  hourglass: loadable(() => import('@/assets/iconly/Hourglass.svg')),
  houseContract: loadable(() => import('@/assets/icons/House Contract.svg')),
  keyWithTag: loadable(() => import('@/assets/iconly/Key.svg')),
  maze: loadable(() => import('@/assets/icons/Maze.svg')),
  moneyBag: loadable(() => import('@/assets/icons/Money Bag.svg')),
  notebook: loadable(() => import('@/assets/iconly/Notebook Document.svg')),
  options: loadable(() => import('@/assets/icons/Options.svg')),
  package: loadable(() => import('@/assets/iconly/Box.svg')),
  paperPlane: loadable(() => import('@/assets/iconly/Send.svg')),
  personMagnified: loadable(
    () => import('@/assets/icons/Person Magnified.svg')
  ),
  pledge: loadable(() => import('@/assets/icons/Pledge.svg')),
  rocketUp: loadable(() => import('@/assets/icons/Rocket Up.svg')),
  signature: loadable(() => import('@/assets/iconly/Pen.svg')),
  stampedDocument: loadable(
    () => import('@/assets/icons/Stamped Document.svg')
  ),
  star: loadable(() => import('@/assets/iconly/Star.svg')),
  stopWatch: loadable(() => import('@/assets/icons/Stop Watch.svg')),
  shieldDone: loadable(() => import('@/assets/iconly/Shield Done.svg')),
  truckMoving: loadable(() => import('@/assets/iconly/Truck Moving.svg')),
  truckStill: loadable(() => import('@/assets/iconly/Truck Still.svg')),
  thumbsUp: loadable(() => import('@/assets/icons/Thumbs Up.svg')),
  walletDouble: loadable(() => import('@/assets/iconly/Wallet Double.svg')),
};
export type TIconName = keyof typeof IconMap;

const Icon: FC<
  {
    name: TIconName;
    size?: number | string;
  } & ComponentProps<typeof x.div>
> = ({ name, size = 60, ...wrapperProps }) => {
  const IconComponent = IconMap[name];
  if (!IconComponent) {
    logger.error(`No Icon was found for ${name}`);
    return null;
  }
  return (
    <x.div display='inline-block' {...wrapperProps}>
      <IconComponent width={size} height={size} />
    </x.div>
  );
};

export default Icon;
