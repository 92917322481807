import { Link } from '@/elements';
import { ExtendComponent } from '@/style';

import { BackTextAndIcon } from './back-text-and-icon';

export const BackLink: ExtendComponent<typeof Link> = (props) => (
  <Link {...props} variant='hoverColor'>
    <BackTextAndIcon />
  </Link>
);
