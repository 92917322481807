import type { SbRichText, SbBlok } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Text } from '@/elements';
import { VStack } from '@/elements/layout';

import RichText from '../RichText';

export const TextRow: SbBlok<{
  title: SbRichText;
  content: SbRichText;
}> = (blok) => {
  const { title, content } = blok;
  return (
    <SbEditable content={blok}>
      <VStack spacing='xs'>
        <RichText
          resolver={{
            Paragraph: (props) => (
              <Text pb='0' fontSize='lg' fontWeight='bold' {...props} />
            ),
          }}
        >
          {title}
        </RichText>
        <RichText>{content}</RichText>
      </VStack>
    </SbEditable>
  );
};
