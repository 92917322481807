import ArrowDown from '@/assets/iconly/Arrow - Down 2.svg';
import { styled, css } from '@/style';

export const Rows = styled.divBox`
  max-width: 380px;
`;
export const Row = styled.divBox<{
  lastChild?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 66px;
  border-bottom: 1px solid;
  border-bottom-color: palette-border;
  margin-left: 60px;
  ${({ lastChild }) => lastChild && `border-bottom-color: transparent;`}
`;
export const Icon = styled.div`
  color: palette-primary;
  margin-right: md;
  position: absolute;
  right: 100%;
`;
export const Title = styled.h3Box`
  flex: 1;
  text-align: left;
  font-weight: 700;
  font-size: lg;
  line-height: lg;
  color: palette-text;
`;
export const Arrow = styled(ArrowDown)`
  width: 24px;
  height: 24px;
  color: palette-text;
`;
export const ContentContainer = styled.divBox<{
  lastChild?: boolean;
}>`
  margin-left: 60px;
  padding: md 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-bottom-color: palette-border;
  ${({ lastChild }) =>
    lastChild &&
    css`
      border-top: 1px solid;
      border-top-color: palette-border;
      border-bottom-color: transparent;
    `}
`;
export const Content = styled.divBox`
  padding-bottom: sm;
`;
export const Cta = styled.divBox``;
