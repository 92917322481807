import type { SbEditableContent } from 'storyblok';

import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { x } from '@/style';

// eslint-disable-next-line import/no-cycle
import * as c from './components';

const RtTable: FC<SbEditableContent> = (props) => {
  const { thead, tbody } = props.content;
  return (
    <SbEditable content={props}>
      <x.div py='md' mx='auto'>
        <c.Table>
          <c.TableHead>
            <c.HeaderRow>
              {thead.map((column: any) => {
                return (
                  <c.HeaderCell key={column._uid}>{column.value}</c.HeaderCell>
                );
              })}
            </c.HeaderRow>
          </c.TableHead>
          <c.TableBody>
            {tbody.map((row: any) => {
              return (
                <c.Row key={row._uid}>
                  {row.body.map((cell: any) => {
                    return <c.Cell key={cell._uid}>{cell.value}</c.Cell>;
                  })}
                </c.Row>
              );
            })}
          </c.TableBody>
        </c.Table>
      </x.div>
    </SbEditable>
  );
};

export default RtTable;
