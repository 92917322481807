import { useEffect, useState } from 'react';

import useRouteHash from '@/lib/hooks/useRouteHash';

import { useHomePageConfig } from './use-home-page-config';

import { PageState } from '../types';

const useHomePageState = ({
  sections,
  onActivePageChange,
}: {
  sections: Array<{ id: string; label: string }>;
  onActivePageChange: (pageId: PageState) => void;
}) => {
  const homeStateConfig = useHomePageConfig(sections);
  const [pageId, setActivePageId] = useState(homeStateConfig.unmountedStateId);

  const hash = useRouteHash();
  useEffect(() => {
    if (hash && homeStateConfig.scrollableStates.indexOf(hash) > -1)
      setActivePageId(hash);
    else setActivePageId(homeStateConfig.scrollableStates[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeState = homeStateConfig.states[pageId];

  useEffect(() => {
    onActivePageChange(activeState);
  }, [activeState, onActivePageChange]);

  return {
    activeState,
    setActivePageId,
    scrollSpyState: homeStateConfig.scrollSpyState,
    scrollableStates: homeStateConfig.scrollableStates,
  };
};

export default useHomePageState;
