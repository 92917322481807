import { motion } from 'framer-motion';

import { BlankLink } from '@/elements';
import { styled, css } from '@/style';

const positionVariants = {
  inline: {
    container: css`
      position: relative;
      width: 100%;
    `,
    line: css``,
  },
  bottom: {
    container: css`
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: lg 0;
    `,
    line: css`
      height: 50%;
      background-color: palette-bg;
    `,
  },
};

export type PositionVariants = keyof typeof positionVariants;

export const Container = styled.divBox<{
  $position: PositionVariants;
}>`
  ${({ $position }) => positionVariants[$position].container}
`;

export const Button = styled.buttonBox`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Link = styled(BlankLink)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Line = styled.spanBox<{
  $position: PositionVariants;
}>`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid transparent;
  border-color: palette-border;
  ${({ $position }) => positionVariants[$position].line}
`;
export const ArrowContainer = styled(motion.span)`
  border: 1px solid transparent;
  border-color: palette-border;
  background-color: palette-bg;
  color: palette-primary;
  width: 44px;
  height: 44px;
  border-radius: md;
  position: relative;
  z-index: infront;
`;

export const ScrollPosition = styled.div`
  position: absolute;
  top: calc(100% + 30px);
`;
