import type { SbComponent, SbRichText } from 'storyblok';

import { ComponentProps } from 'react';

import SbEditable from 'storyblok-react';

import { Text } from '@/elements';
import { RichText } from '@/lib/storyblok';

import resolver from './resolver';

// eslint-disable-next-line import/no-cycle
import defaultBloks from '../RichText/bloks';
import defaultConfig from '../RichText/config';

/**
 * RichSentence only allows emphasis,
 * such as bold, italic, underline, and strikethrough.
 */
const RichSentence: SbComponent<
  {
    text: SbRichText;
  },
  ComponentProps<typeof Text>
> = ({ content, variant = 'body1', ...rest }) => (
  <SbEditable content={content}>
    <Text {...rest} variant={variant}>
      <RichText config={defaultConfig} bloks={defaultBloks} resolver={resolver}>
        {content.text}
      </RichText>
    </Text>
  </SbEditable>
);

export default RichSentence;
