import * as c from './components';
import { TProfileSymbolComponent } from './types.d';

const BuyerProfileSymbol: TProfileSymbolComponent = ({
  condensedAnimationName = 'condensed',
  distance = 3,
  size = 128,
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 128 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <c.SymbolG
      variants={{
        [condensedAnimationName]: {
          x: distance,
          y: -distance,
        },
      }}
    >
      <circle cx='59.6624' cy='66.4076' r='36.48' fill='#EB6E46' />
    </c.SymbolG>
    <c.SymbolG
      variants={{
        [condensedAnimationName]: {
          x: -distance,
          y: distance,
        },
      }}
    >
      <circle cx='89.1058' cy='42.4065' r='22.4' fill='#EB6E46' />
    </c.SymbolG>
  </svg>
);

export default BuyerProfileSymbol;
