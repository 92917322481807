import filter from 'lodash/filter';
import unionBy from 'lodash/unionBy';

export default class List<TItem> {
  private _all: Array<TItem> = [];
  private _propName: string;

  constructor(propName = 'id') {
    this._propName = propName;
  }

  public forEach: typeof this._all.forEach = (...params) =>
    this._all.forEach(...params);
  public map: typeof this._all.map = (...params) => this._all.map(...params);
  public some: typeof this._all.some = (...params) => this._all.some(...params);

  public addIfNotExists = (item: TItem) => {
    const { length } = this._all;
    this._all = unionBy(this._all, [item], this._propName);
    return this._all.length > length;
  };

  public removeIfExists = (item: TItem) => {
    const { length } = this._all;
    this._all = filter(
      this._all,
      (i) => (i as any)[this._propName] !== (item as any)[this._propName]
    );
    return this._all.length < length;
  };

  public toArray = () => [...this._all];
}
