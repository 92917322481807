import { VFC } from 'react';

import loadable from '@loadable/component';

import { x } from '@/style';

const sizeMap = {
  sm: '240px',
  md: '300px',
};

const logoMap = {
  innovateUk: loadable(() => import('@/assets/logos/innovate-uk.svg')),
  fca: loadable(() => import('@/assets/logos/fca.svg')),
};

export type LogoName = keyof typeof logoMap;

export const Logo: VFC<{
  name: LogoName;
  size?: keyof typeof sizeMap;
}> = ({ name, size = 'md' }) => {
  const LogoSvg = logoMap[name];
  const maxW = sizeMap[size];
  return (
    <x.div w='100%' maxW={maxW}>
      <LogoSvg width='100%' height='100%' />
    </x.div>
  );
};
