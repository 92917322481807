import type { TPaletteFunction } from '@/lib/Palette';

// eslint-disable-next-line import/prefer-default-export
export const createLightPalette =
  ({
    bg,
    text = 'brand-dark',
    border = 'brand-beige',
    subtle = 'brand-beige-light',
    secondaryBorder = subtle,
  }: {
    bg: string;
    text?: string;
    border?: string;
    subtle?: string;
    secondaryBorder?: string;
  }): TPaletteFunction =>
  (theme: any) => ({
    bg: theme[bg] || bg,
    text: theme[text] || text,
    subtle: theme[subtle] || subtle,
    border: theme[border] || border,
    accent: theme['brand-orange'],
    primary: theme['brand-orange'],
    primaryText: theme['brand-white'],
    primaryBorder: theme['brand-dark-orange'],
    secondary: theme[bg] || bg,
    secondaryText: theme['brand-text'],
    secondaryBorder: theme[secondaryBorder] || secondaryBorder,
  });
