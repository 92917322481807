import { useMemo } from 'react';

import { HomeStateConfig } from '../types';

export const useHomePageConfig = (
  sections: Array<{ id: string; label: string }>
) => {
  if (sections.length !== 5) {
    throw new Error('Sections must contain exactly 5 items');
  }
  return useMemo((): HomeStateConfig => {
    const ids: Array<string> = [];
    const labels: Array<string> = [];
    sections.forEach(({ id, label }) => {
      ids.push(id);
      labels.push(label);
    });
    return {
      unmountedStateId: 'unmounted',
      scrollableStates: [...ids, 'footer'],
      scrollSpyState: [
        { id: ids[0], label: labels[0] },
        { id: ids[1], label: labels[1] },
        { id: ids[2], label: labels[2] },
        { id: ids[3], label: labels[3] },
        { id: ids[4], label: labels[4] },
      ],
      states: {
        unmounted: {
          id: 'unmounted',
          palette: 'white',
          isHouseVisible: false,
          houseFrame: 0,
        },
        [ids[0]]: {
          id: ids[0],
          activeId: ids[0],
          label: labels[0],
          palette: 'white',
          isHouseVisible: true,
          houseFrame: 0,
          nextHref: `#${labels[1]}`,
        },
        [ids[1]]: {
          id: ids[1],
          activeId: ids[1],
          label: labels[1],
          palette: 'lightYellow',
          isHouseVisible: true,
          houseFrame: 100,
        },
        [ids[2]]: {
          id: ids[2],
          activeId: ids[2],
          label: labels[2],
          palette: 'lightOrange',
          isHouseVisible: true,
          houseFrame: 200,
        },
        [ids[3]]: {
          id: ids[3],
          activeId: ids[3],
          label: labels[3],
          palette: 'lightGreen',
          isHouseVisible: true,
          houseFrame: 300,
        },
        [ids[4]]: {
          id: ids[4],
          activeId: ids[4],
          palette: 'lightRed',
          isHouseVisible: false,
          houseFrame: 300,
        },
        footer: {
          id: 'footer',
          activeId: ids[4],
          palette: 'lightRed',
          houseFrame: 300,
          isHouseVisible: false,
          isFooterVisible: true,
        },
      },
    };
  }, []);
};
