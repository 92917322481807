import { FC } from 'react';

import { Text } from '@/elements';

export const DraftBanner: FC<{ id: string }> = () => (
  <Text
    textAlign='center'
    bg='palette-primary'
    color='palette-primary-text'
    w='100%'
    p='md'
    border='1px solid transparent'
    borderColor='palette-primary'
  >
    This article is coming soon
  </Text>
);
