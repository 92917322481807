import * as c from './components';
import { TProfileSymbolComponent } from './types.d';

const BuyerProfileSymbol: TProfileSymbolComponent = ({
  condensedAnimationName = 'condensed',
  distance = 3,
  size = 128,
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 128 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <c.SymbolG
      variants={{
        [condensedAnimationName]: {
          x: distance,
          y: -distance,
        },
      }}
    >
      <rect
        x='26.7471'
        y='40.1674'
        width='63.36'
        height='63.36'
        fill='#3A6E6E'
      />
    </c.SymbolG>
    <c.SymbolG
      variants={{
        [condensedAnimationName]: {
          x: -distance,
          y: distance,
        },
      }}
    >
      <rect
        x='67.0674'
        y='22.8871'
        width='40.32'
        height='40.32'
        fill='#3A6E6E'
      />
    </c.SymbolG>
  </svg>
);

export default BuyerProfileSymbol;
