import { useRef, MutableRefObject, FC } from 'react';

import { Link, Button } from './components';

const useScrollState = (
  href?: string
): {
  ClickComponent: FC;
  scrollRef: MutableRefObject<HTMLElement | undefined>;
} => {
  const scrollRef = useRef<HTMLElement | undefined>();
  const scroll = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return {
    ClickComponent: href
      ? (props: any) => <Link {...props} href={href} />
      : (props: any) => <Button {...props} onClick={scroll} />,
    scrollRef,
  };
};

export default useScrollState;
