import { FC } from 'react';

import { BackTextAndIcon } from '@/common/back-link';
import { StickyFooterButton } from '@/common/sticky-footer-button';

import { PublishedArticleContent } from '../../utils/use-article-story-formatter';
import { useGetBackPath } from '../use-get-back-path';

export const BackButton: FC<{
  article: PublishedArticleContent;
}> = ({ article }) => {
  const backPath = useGetBackPath(article);
  return (
    <StickyFooterButton as='link' href={backPath}>
      <BackTextAndIcon />
    </StickyFooterButton>
  );
};
