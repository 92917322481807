import { usePreviousPath } from '@/lib/previous-path';

export const usePreviousPathOrDefault = ({
  shouldUsePreviousPath,
  defaultPath,
}: {
  shouldUsePreviousPath: (path: string) => boolean;
  defaultPath: string;
}) => {
  const previousPath = usePreviousPath();

  if (previousPath && shouldUsePreviousPath(previousPath)) return previousPath;
  return defaultPath;
};
