import { getArticleStatusFromStory } from '@/common/blog/utils/get-article-status';
import { isStoryComponentType } from '@/common/blog/utils/is-story-component-type';

import { StaticPropsInterceptor } from '../types/static-props-interceptor';
import { getPropsFromStaticPropsResult } from '../utils/get-props-from-static-props-result';

export const draftBlogArticleInterceptor: StaticPropsInterceptor = (result) => {
  const { story } = getPropsFromStaticPropsResult(result);
  if (story) {
    if (isStoryComponentType(story, 'BlogArticle')) {
      if (getArticleStatusFromStory(story) === 'draft') {
        return {
          notFound: true,
        };
      }
    }
  }
  return result;
};
