import type { Link as SbLink } from 'storyblok';

import { FC } from 'react';

import { Link } from '@/elements';
import { useResolveAttrsFromStoryblokLink } from '@/lib/storyblok';
import { InferProps } from '@/style';

const StoryblokLink: FC<
  InferProps<typeof Link> & {
    link: SbLink;
  }
> = ({ link, ...rest }) => {
  const linkAttrs = useResolveAttrsFromStoryblokLink(link);
  return <Link {...rest} {...linkAttrs} />;
};

export default StoryblokLink;
