import { useEffect, useRef, useState } from 'react';

import { useMotionValue } from 'framer-motion';

import { Progress, useTimelineItem } from '@/lib/ScrollTimeline';

import * as utils from './utils';

const useAnimator = ({
  stepNo,
  animationOffset,
  isLast,
  dotRadius,
  pulseRadius,
  lineWidth = 1,
  curveRadius = 30,
  joinGap = 10,
  isAlternatingEnabled,
}: {
  stepNo: number;
  animationOffset: number;
  isLast: boolean;
  lineWidth?: number;
  curveRadius?: number;
  joinGap?: number;
  dotRadius: number;
  pulseRadius: number;
  isAlternatingEnabled: boolean;
}) => {
  const isAlternatingEnabledRef = useRef(isAlternatingEnabled);
  isAlternatingEnabledRef.current = isAlternatingEnabled;
  const svgRef = useRef<SVGElement>();
  const pathRef = useRef<SVGPathElement>();
  const pulseRef = useRef<SVGGElement>();

  const motionValue = useMotionValue(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    motionValue.onChange((progress) => {
      const path = pathRef.current;
      const pulse = pulseRef.current;
      const isAtEnd = progress >= 1 || progress <= 0;
      if (path && pulse) {
        const fullLength = path.getTotalLength();
        if (fullLength) {
          const progressLength = fullLength * progress;
          const point = path.getPointAtLength(progressLength);

          pulse.setAttribute(
            'transform',
            `translate(${point.x - dotRadius},${point.y - dotRadius}) scale(${
              isAtEnd ? 0 : 1
            })`
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTimelineItem(
    () =>
      new Progress({
        progress: {
          from: 0,
          to: 1,
        },
        elements: {
          start: `step-number-${stepNo}`,
          end: `step-number-${stepNo + 1}`,
          stepContainer: `step-container-${stepNo}`,
        },
        getExtraCalibrationData: ({ elements: { start, end } }) => {
          let startX;
          let endX;
          if (start) {
            const startRec = start.getBoundingClientRect();
            startX = startRec.left;
          }
          if (end) {
            const endRec = end.getBoundingClientRect();
            endX = endRec.left;
          }
          return {
            startX,
            endX,
          };
        },
        getStartPos: ({ scrollStatus: { container }, elements: { start } }) => {
          if (start) {
            const containerRec = container.getBoundingClientRect();
            const startRec = start.getBoundingClientRect();
            return (
              startRec.bottom +
              animationOffset +
              container.scrollTop -
              containerRec.height
            );
          }
          return null;
        },
        getEndPos: ({
          scrollStatus: { container },
          elements: { start, end },
        }) => {
          const containerRec = container.getBoundingClientRect();
          if (isLast && start) {
            const startRec = start.getBoundingClientRect();
            return (
              startRec.bottom +
              animationOffset +
              container.scrollTop +
              1 -
              containerRec.height
            );
          }
          if (end) {
            const endRec = end.getBoundingClientRect();
            return (
              endRec.top +
              animationOffset +
              container.scrollTop -
              containerRec.height
            );
          }
          return null;
        },
        handlers: {
          onCalibrate: ({ elements: { start, end, stepContainer } }) => {
            if (!isLast) {
              utils.drawPath({
                stepContainer,
                startElement: start,
                endElement: end,
                svg: svgRef.current,
                path: pathRef.current,
                lineWidth,
                curveRadius,
                joinGap,
                pulseRadius,
                isAlternatingEnabled: isAlternatingEnabledRef.current,
              });
            }
          },
          onStatusChanged: ({ status: progress }) => {
            if (progress !== null) {
              motionValue.set(progress);
              setIsVisible(progress > 0);
            }
          },
        },
      })
  );

  return {
    motionValue,
    isVisible,
    svgRef,
    pathRef,
    pulseRef,
  };
};

export default useAnimator;
