import { FC } from 'react';

import { motion } from 'framer-motion';

import { Text } from '@/elements';
import { x } from '@/style';

export const ArticleTitle: FC<{
  layoutId?: string;
}> = ({ layoutId, children }) => (
  <x.div p='md' pb='md' flex={1}>
    <Text as={motion.h3} layoutId={layoutId} variant='bodyBig' pb={0}>
      {children}
    </Text>
  </x.div>
);
