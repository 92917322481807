import Buyer from './Buyer';
import EstateAgent from './EstateAgent';
import Seller from './Seller';

export { default as getProfileSymbolForPalette } from './getProfileSymbolForPalette';

export const profileSymbolMap = {
  buyer: Buyer,
  seller: Seller,
  ea: EstateAgent,
};
export { Buyer, EstateAgent, Seller };
