import { ComponentProps, forwardRef, PropsWithChildren } from 'react';

import { motion, MotionProps, Variant } from 'framer-motion';

import { StyledProps, x } from '@/style';

import { setPropsAndExtend } from './style';

export const time = {
  sm: 0.05,
  md: 0.1,
  lg: 0.3,
};
// export const TimeLg = 0.3;
export const movement = {
  sm: 8,
};
// export const MovementLg = 20;

type TMotionProps = ComponentProps<typeof motion.div>;
export function createAnimation<TDefaultProps, TProps extends TDefaultProps>(
  createShowAndHide: (
    props: TProps & { showName: string; hideName: string }
  ) => {
    show?: Variant;
    hide?: Variant;
    transition?: TMotionProps['transition'];
    whileInView?: TMotionProps['whileInView'];
    viewport?: TMotionProps['viewport'];
    initial?: TMotionProps['initial'];
  },
  defaultProps: TDefaultProps = {} as any
) {
  return setPropsAndExtend(
    motion.div,
    (props) => {
      const { showName, hideName } = props;
      const { show, hide, transition, whileInView, viewport, initial } =
        createShowAndHide(props as any);
      return {
        variants: {
          ...props.variants,
          [showName!]: {
            ...props.variants?.[showName!],
            ...show,
          },
          [hideName!]: {
            ...props.variants?.[hideName!],
            ...hide,
          },
        },
        transition: {
          ...transition,
          ...props.transition,
        },
        whileInView,
        viewport,
        initial,
      };
    },
    {
      ...defaultProps,
      showName: 'show',
      hideName: 'hide',
    }
  );
}

export const StaggerChildren = createAnimation(
  ({ staggerChildren }) => ({
    show: {
      transition: {
        staggerChildren,
      },
    },
  }),
  {
    staggerChildren: time.sm,
  }
);

export const AnimateOnVisible = createAnimation(
  ({ staggerChildren, once, showName, hideName }) => ({
    show: {
      transition: {
        staggerChildren,
      },
    },
    whileInView: showName,
    initial: hideName,
    viewport: { once },
  }),
  {
    staggerChildren: time.md,
    once: true,
  }
);

export const OnVisible = {
  FadeUp: createAnimation(
    ({
      showName,
      hideName,
      movement: propMovement,
      viewportMargin,
      once,
      pos,
    }) => ({
      [showName]: {
        y: 0,
        opacity: 1,
      },
      [hideName]: {
        y: propMovement,
        opacity: 0,
      },
      transition: {
        type: 'tween',
        delay: time.md * pos,
      },
      whileInView: showName,
      initial: hideName,
      viewport: {
        margin: viewportMargin,
        once,
      },
    }),
    {
      movement: movement.sm,
      viewportMargin: '-100px 0px',
      once: true,
      pos: 0,
      showName: 'vShow',
      hideName: 'hShow',
    }
  ),
};

export const Fade = {
  Up: forwardRef(
    (
      {
        propMovement = movement.sm,
        ...rest
      }: PropsWithChildren<
        StyledProps & {
          propMovement?: number;
        } & MotionProps
      >,
      ref
    ) => (
      <x.div
        ref={ref as any}
        as={motion.div}
        variants={{
          show: {
            y: 0,
            opacity: 1,
          },
          hide: {
            y: propMovement,
            opacity: 0,
          },
        }}
        transition={{
          type: 'tween',
        }}
        {...rest}
      />
    )
  ),
  Down: createAnimation(
    ({ movement: propMovement }) => ({
      show: {
        y: 0,
        opacity: 1,
      },
      hide: {
        y: -propMovement,
        opacity: 0,
      },
      transition: {
        type: 'tween',
      },
    }),
    {
      movement: movement.sm,
    }
  ),
  Left: createAnimation(
    ({ movement: propMovement }) => ({
      show: {
        x: 0,
        opacity: 1,
      },
      hide: {
        x: propMovement,
        opacity: 0,
      },
      transition: {
        type: 'tween',
      },
    }),
    {
      movement: movement.sm,
    }
  ),
  Right: createAnimation(
    ({ movement: propMovement }) => ({
      show: {
        x: 0,
        opacity: 1,
      },
      hide: {
        x: -propMovement,
        opacity: 0,
      },
      transition: {
        type: 'tween',
      },
    }),
    {
      movement: movement.sm,
    }
  ),
  In: createAnimation(() => ({
    show: {
      opacity: 1,
    },
    hide: {
      opacity: 0,
    },
    transition: {
      type: 'tween',
    },
  })),
};
