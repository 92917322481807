export const getMenuItemState = ({
  isCondensed,
  isLastOption,
}: {
  isCondensed: boolean;
  isLastOption: boolean;
}) => {
  if (isCondensed)
    return {
      labelPaddingBottom: isLastOption ? 0 : 'md',
      showLine: false,
    };
  return {
    labelPaddingBottom: 0,
    showLine: !isLastOption,
  };
};
