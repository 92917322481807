import type { IJourneyStepContent } from '../types.d';

import { FC } from 'react';

import { CtaRow, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { Fade } from '@/lib/utils/animation';

import StepNumber from '../StepNumber';
import * as c from '../components';

const directionVariants = {
  standard: {
    flexDirection: { _: 'column', tablet: 'row' },
  },
  reverse: {
    flexDirection: { _: 'column', tablet: 'row-reverse' },
  },
};

const Step: FC<{
  step: IJourneyStepContent;
  stepNo: number;
  isReverse: boolean;
}> = ({ step, stepNo, isReverse }) => (
  <c.Step.Container
    {...directionVariants[isReverse ? 'reverse' : 'standard']}
    $disableIncrementalScaling={step.disableIncrementalScaling}
    spacing='lg'
  >
    <c.Step.Column my={{ _: 0, tablet: 'lg' }}>
      <StepNumber stepNo={stepNo} />
      <c.Step.TextContainer mt='12px'>
        <Fade.Up>
          <e.Text variant='h4'>{step.title}</e.Text>
        </Fade.Up>
        <Fade.Up>
          <RichText>{step.content}</RichText>
        </Fade.Up>
        {step.ctas?.length ? (
          <Fade.Up>
            <CtaRow ctas={step.ctas} mt='md' justifyContent='flex-start' />
          </Fade.Up>
        ) : null}
      </c.Step.TextContainer>
    </c.Step.Column>
    <c.Step.Column
      my={{ _: 0, tablet: 'lg' }}
      display={{ _: 'none', largePhone: 'block' }}
    >
      <c.Step.ImageContainer>
        {step.images
          ? step.images.map((image, index) => {
              switch (index) {
                case 0:
                  return (
                    <c.Step.FixedImage key={image.filename}>
                      <Fade.In>
                        <c.Step.Image src={image.filename} />
                      </Fade.In>
                    </c.Step.FixedImage>
                  );
                default:
                  return (
                    <c.Step.OverlayImage
                      className='overlay-image'
                      key={image.filename}
                    >
                      <Fade.Up>
                        <c.Step.Image src={image.filename} />
                      </Fade.Up>
                    </c.Step.OverlayImage>
                  );
              }
            })
          : null}
      </c.Step.ImageContainer>
    </c.Step.Column>
  </c.Step.Container>
);

export default Step;
