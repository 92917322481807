import { FC } from 'react';

import { motion, Transition } from 'framer-motion';

const Expandable: FC<{
  isExpanded?: boolean;
  showName?: string;
  hideName?: string;
  style?: React.CSSProperties;
  transition?: Transition;
}> = ({
  isExpanded,
  style,
  showName = 'show',
  hideName = 'hide',
  transition = { type: 'tween', ease: 'easeOut', staggerChildren: 0.1 },
  ...rest
}) => (
  <motion.div
    transition={transition}
    {...rest}
    style={{ ...style, overflow: 'hidden' }}
    layout
    variants={{
      [hideName]: {
        height: 0,
        transition: {
          staggerDirection: -1,
        },
      },
      [showName]: {
        height: 'auto',
      },
    }}
  />
);

export default Expandable;
