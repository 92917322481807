import { FC } from 'react';

import { useSize, x } from '@/style';

import { BackButton } from './back-button';

import { PublishedArticleContent } from '../../utils/use-article-story-formatter';
import { Content } from '../content';
import { CtaSection } from '../cta-section';
import { SubscribeSection } from '../subscribe-section';
import { Suggestions } from '../suggestions';
import { ToTopButton } from '../to-top-button';

export const MobilePage: FC<{
  article: PublishedArticleContent;
}> = ({ article }) => {
  const headerHeight = useSize('header');
  const buttonHeight = useSize('button');
  const { ctaSection, suggestions } = article;
  return (
    <>
      <x.div mt={headerHeight}>
        <Content article={article} />
        <CtaSection
          title={ctaSection.title}
          content={ctaSection.content}
          ctas={ctaSection.ctas}
        />
        <Suggestions
          title={suggestions.title}
          articles={suggestions.articles}
        />
        <SubscribeSection />
      </x.div>

      <x.div
        w='100%'
        position='sticky'
        bottom={buttonHeight}
        p='md'
        display='flex'
        justifyContent='flex-end'
      >
        <ToTopButton />
      </x.div>
      <BackButton article={article} />
    </>
  );
};
