import { FC } from 'react';

import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

import { useArticlesFeed } from './articles-feed';

import { ArticleCard } from '../article-card';

export const ArticlesGrid: FC = () => {
  const { visibleArticles, columnCount } = useArticlesFeed();

  return (
    <AnimateOnVisible>
      {columnCount !== null ? (
        <x.div display='grid' gap='md' gridTemplateColumns={columnCount}>
          {visibleArticles.map(({ uuid, article }) => (
            <Fade.Up key={uuid} animate='show'>
              <ArticleCard uuid={uuid} article={article} />
            </Fade.Up>
          ))}
        </x.div>
      ) : null}
    </AnimateOnVisible>
  );
};
