import type { SbComponent, SbRichText } from 'storyblok';

import { ComponentProps } from 'react';

import SbEditable from 'storyblok-react';

import Palette from '@/common/Palette';
import {
  RichText,
  RichSentence,
  TRichSentence,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

import { Carousel } from '../../components/Carousel';

export const CarouselSection: SbComponent<{
  subtitle: string;
  title: TRichSentence;
  hasDescription: boolean;
  description: SbRichText;
  carousels: Array<ComponentProps<typeof Carousel>>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Palette palette='lightRed'>
      <AnimateOnVisible>
        <e.Layout.VStack my='lg'>
          <e.Layout.VStack textAlign='center' mx='auto' maxW='section-width-sm'>
            {content.subtitle ? (
              <Fade.Up>
                <e.Text variant='subtitle1' as={x.h3} color='palette-primary'>
                  {content.subtitle}
                </e.Text>
              </Fade.Up>
            ) : null}
            <Fade.Up>
              <RichSentence variant='h2'>{content.title}</RichSentence>
            </Fade.Up>
            {content.hasDescription ? (
              <Fade.Up>
                <x.div my='md'>
                  <RichText>{content.description}</RichText>
                </x.div>
              </Fade.Up>
            ) : null}
          </e.Layout.VStack>
          {content.carousels.map((carousel) => (
            <Fade.Up key={carousel._uid}>
              <Carousel {...carousel} />
            </Fade.Up>
          ))}
        </e.Layout.VStack>
      </AnimateOnVisible>
    </Palette>
  </SbEditable>
);
