import type { HomeViewComponent } from '../../types';

import Footer from '@/common/Layout/Footer';
import Palette from '@/common/Palette';
import ScrollHr from '@/common/ScrollHr';
import { DiamondMenu } from '@/common/diamond-menu';
import FooterReveal from '@/lib/FooterReveal';
import ScrollPagingView from '@/lib/ScrollPagingView';
import usePageState from '@/state/PageState';

import * as c from './components';

import useHomePageState from '../../hooks/useHomePageState';
import HouseSection from '../HouseSection';
import MoreSection from '../MoreSection';
import { Container as PageAnimatorContainer } from '../PageAnimator';

const ScrollPagingViewComponent: HomeViewComponent = ({ content }) => {
  const { setPalette } = usePageState();
  const {
    activeState: { palette, nextHref, isFooterVisible, activeId },
    scrollableStates,
    scrollSpyState,
    setActivePageId,
  } = useHomePageState({
    sections: [
      content.headline[0],
      content.buyer[0],
      content.seller[0],
      content.ea[0],
      content.more[0],
    ],
    onActivePageChange: (page) => {
      setPalette(page.palette);
    },
  });

  return (
    <Palette palette={palette}>
      <ScrollPagingView
        pages={scrollableStates}
        setActivePageId={setActivePageId}
        scrollTriggerDistance={200}
      >
        <FooterReveal
          footer={
            <c.FooterContainer isVisible={!!isFooterVisible}>
              <Footer isFooterVisible={!!isFooterVisible} />
            </c.FooterContainer>
          }
          isFooterVisible={!!isFooterVisible}
        >
          <c.ContentContainer>
            <c.ScrollSpyArea>
              <DiamondMenu
                w='24px'
                options={scrollSpyState.map((page) => ({
                  id: page.id,
                  label: page.label,
                  href: `#${page.id}`,
                }))}
                activeId={activeId}
              />
            </c.ScrollSpyArea>
            <c.ContentArea>
              <c.HouseTextArea>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[0].id}
                >
                  <HouseSection content={content.headline[0]} />
                </PageAnimatorContainer>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[1].id}
                >
                  <HouseSection content={content.buyer[0]} />
                </PageAnimatorContainer>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[2].id}
                >
                  <HouseSection content={content.seller[0]} />
                </PageAnimatorContainer>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[3].id}
                >
                  <HouseSection content={content.ea[0]} />
                </PageAnimatorContainer>
              </c.HouseTextArea>
              <PageAnimatorContainer
                isActive={activeId === scrollSpyState[4].id}
              >
                <MoreSection content={content.more[0]} />
              </PageAnimatorContainer>
            </c.ContentArea>
          </c.ContentContainer>
          <PageAnimatorContainer isActive={!!nextHref}>
            <ScrollHr href={nextHref} />
          </PageAnimatorContainer>
        </FooterReveal>
      </ScrollPagingView>
    </Palette>
  );
};

export default ScrollPagingViewComponent;
