import type { Image } from 'storyblok';

import { FC } from 'react';

import { useRouter } from 'next/router';

import { createMarketingSitePath } from '@/common/utils/create-marketing-site-path';
import SocialMeta from '@/lib/snippets/socialMeta';
import resizeImage from '@/lib/storyblok/imageService';

const defaultKeywords = ['house', 'buy', 'sell'];

export const ArticleMeta: FC<{
  title: string;
  summary: string;
  image: Image;
  keywords?: Array<string>;
}> = ({ title, summary, image, keywords = [] }) => {
  const { asPath } = useRouter();
  const currentAbsoluteUrl = createMarketingSitePath(asPath);
  return (
    <SocialMeta
      title={title}
      description={summary}
      ogUrl={currentAbsoluteUrl}
      image={resizeImage(image.filename, 600)}
      keywords={[...defaultKeywords, ...keywords].join(',')}
    />
  );
};
