import { FC } from 'react';

import Palette from '@/common/Palette';
import { Logo } from '@/common/logo';
import * as e from '@/elements';
import { StaggerChildren, Fade } from '@/lib/utils/animation';
import * as logger from '@/services/log';
import useAppState, { INestedItem } from '@/state/AppState';
import { x } from '@/style';

import { FooterButton } from './Button';
import FooterLink from './Link';
import FooterList from './List';
import Socials from './Socials';
import FooterTitle from './Title';
import * as c from './components';

const Footer: FC<{
  isFooterVisible: boolean;
}> = ({ isFooterVisible }) => {
  const { footerItems } = useAppState();

  return (
    <Palette palette='dark'>
      <x.footer
        id='footer'
        position='relative'
        zIndex={0}
        bg='palette-bg'
        color='palette-text'
      >
        <x.div display={{ _: 'none', tablet: 'block' }} padding='lg'>
          <e.Layout.Stack justifyContent='space-between' py='xl'>
            <e.Layout.VStack spacing='lg'>
              <e.BlankLink
                href='https://www.ukri.org/councils/innovate-uk/'
                target='__blank'
              >
                <e.Layout.VStack spacing='sm'>
                  <x.div>Funded by UK Government:</x.div>
                  <e.Card p='md'>
                    <Logo name='innovateUk' size='sm' />
                  </e.Card>
                </e.Layout.VStack>
              </e.BlankLink>
              <e.Layout.VStack spacing='sm'>
                <x.div>
                  Regulated by{' '}
                  <e.Link
                    variant='simpleLink'
                    href='https://register.fca.org.uk/s/firm?id=0014G00002YwQR9QAN'
                    target='__blank'
                  >
                    the FCA
                  </e.Link>
                </x.div>
              </e.Layout.VStack>
            </e.Layout.VStack>

            <e.Layout.HStack
              spacing='60px'
              maxW='900px'
              justifyContent='flex-end'
              flex={1}
            >
              {footerItems.map((item) => {
                switch (item.type) {
                  case 'nested': {
                    const nestedItem = item as INestedItem;
                    return (
                      <FooterList
                        key={item.label}
                        isVisible={isFooterVisible}
                        colNo={0}
                      >
                        <FooterTitle>{nestedItem.label}</FooterTitle>
                        {nestedItem.items.map((subItem) => {
                          switch (subItem.type) {
                            case 'link':
                              return (
                                <FooterLink
                                  key={subItem.label}
                                  href={subItem.href}
                                  target={subItem.target}
                                  trackName={subItem.trackName}
                                >
                                  {subItem.label}
                                </FooterLink>
                              );
                            case 'button':
                              return (
                                <FooterButton
                                  key={subItem.label}
                                  onClick={subItem.onClick}
                                  trackName={subItem.trackName}
                                >
                                  {subItem.label}
                                </FooterButton>
                              );
                            default:
                              throw new Error(
                                `Item type "${
                                  (subItem as any).type
                                }" is not recognised`
                              );
                          }
                        })}
                      </FooterList>
                    );
                  }
                  default: {
                    logger.error(
                      new Error(
                        `Unknown footer item type ${
                          item.type
                        } for ${JSON.stringify(item)}`
                      )
                    );
                    return null;
                  }
                }
              })}
            </e.Layout.HStack>
          </e.Layout.Stack>
          <e.Hr />
          <StaggerChildren
            initial='hide'
            animate={isFooterVisible ? 'show' : 'hide'}
          >
            <e.Layout.HStack
              spacing='lg'
              justifyContent='space-between'
              alignItems='center'
              pt='8px'
            >
              <x.div flex={0}>
                <e.Text variant='body1' pb={0}>
                  <Fade.Up>
                    <x.span whiteSpace='nowrap'>All Right Reserved</x.span>{' '}
                    <x.span whiteSpace='nowrap'>@ housecure.co.uk</x.span>
                  </Fade.Up>
                </e.Text>
              </x.div>
              <x.div flex={1}>
                <c.Legals maxW='480px' pb={0}>
                  <Fade.Up>
                    Housecure Ltd is registered in England and Wales (No.
                    12593624) and is regulated by the Financial Conduct
                    Authority with our Financial Services Register number is
                    935777.
                  </Fade.Up>
                </c.Legals>
              </x.div>
              <x.div flex={0}>
                <Socials />
              </x.div>
            </e.Layout.HStack>
          </StaggerChildren>
        </x.div>
        <x.div display={{ _: 'block', tablet: 'none' }} padding='lg'>
          <StaggerChildren
            initial='hide'
            animate={isFooterVisible ? 'show' : 'hide'}
          >
            <e.Layout.VStack
              justifyContent='space-between'
              alignItems='center'
              py='sm'
            >
              <x.div py='lg'>
                <Socials />
              </x.div>
              <e.Layout.HStack
                spacing='lg'
                w='100%'
                justifyContent='center'
                pb='xl'
              >
                <e.BlankLink
                  href='https://www.ukri.org/councils/innovate-uk/'
                  target='__blank'
                  flex={1}
                >
                  <e.Layout.VStack spacing='sm'>
                    <x.div>Funded by UK Government:</x.div>
                    <e.Card p='md'>
                      <Logo name='innovateUk' size='sm' />
                    </e.Card>
                  </e.Layout.VStack>
                </e.BlankLink>
                <e.Layout.VStack spacing='sm' flex={1}>
                  <x.div>
                    Regulated by:
                    <br />
                    <e.Link
                      variant='simpleLink'
                      href='https://register.fca.org.uk/s/firm?id=0014G00002YwQR9QAN'
                      target='__blank'
                    >
                      the FCA
                    </e.Link>
                  </x.div>
                </e.Layout.VStack>
              </e.Layout.HStack>
              <x.div>
                <c.Legals maxW='480px'>
                  <Fade.Up>
                    Housecure Ltd is registered in England and Wales (No.
                    12593624) and is regulated by the Financial Conduct
                    Authority with our Financial Services Register number is
                    935777.
                  </Fade.Up>
                </c.Legals>
              </x.div>
              <x.div>
                <e.Text variant='body1'>
                  <Fade.Up>
                    <x.span whiteSpace='nowrap'>All Right Reserved</x.span>{' '}
                    <x.span whiteSpace='nowrap'>@ housecure.co.uk</x.span>
                  </Fade.Up>
                </e.Text>
              </x.div>
            </e.Layout.VStack>
          </StaggerChildren>
        </x.div>
      </x.footer>
    </Palette>
  );
};
export default Footer;
