import { FC } from 'react';

import * as c from './components';

const MenuButton: FC<{
  isVisible: boolean;
}> = ({ isVisible }) => (
  <c.BurgerContainer $isVisible={isVisible}>
    <c.BurgerButton />
  </c.BurgerContainer>
);

export default MenuButton;
