import type { SbEditableContent } from 'storyblok';

import { ComponentProps, FC } from 'react';

import { getSingleBlok } from '@/lib/storyblok/utils';

// eslint-disable-next-line import/no-cycle
import RichSentenceComponent from './SbComponent';

export type TRichSentence = Array<SbEditableContent<{ text: string }>>;

const RichSentence: FC<
  {
    children: TRichSentence;
  } & Omit<ComponentProps<typeof RichSentenceComponent>, 'content'>
> = ({ children, ...rest }) => {
  const content = getSingleBlok(children);
  if (!content) return null;
  return <RichSentenceComponent {...rest} content={content} />;
};

export default RichSentence;
