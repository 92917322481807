import * as e from '@/elements';
import { styled } from '@/style';

export { default as Facebook } from '@/assets/logos/social/Facebook.svg';
export { default as Instagram } from '@/assets/logos/social/Instagram.svg';
export { default as Twitter } from '@/assets/logos/social/Twitter.svg';
export { default as LinkedIn } from '@/assets/logos/social/LinkedIn.svg';

export const Legals = styled(e.Text)`
  font-size: xxs;
  line-height: xxs;
  opacity: 0.8;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
`;
export const SocialLink = styled.aBox`
  display: block;
  width: 36px;
  min-width: 36px;
  height: 36px;
  transition: base;
  transform: scale(1);
  :hover {
    transform: scale(1.1);
  }
`;
export const variants = {
  list: {
    hidden: (i: number) => ({
      transition: {
        delayChildren: i * 0.1,
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    }),
    visible: (i: number) => ({
      transition: {
        delayChildren: i * 0.1,
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    }),
  },
  listItem: {
    hidden: {
      opacity: 0,
      x: 10,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  },
};
