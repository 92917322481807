import { Link as LinkElement } from '@/elements';
import { ExtendComponent } from '@/style';

import { useResolvePathForRichTextLink } from './use-resolve-href';

export const RichTextLink: ExtendComponent<typeof LinkElement> = ({
  href,
  ...rest
}) => {
  const resolvedPath = useResolvePathForRichTextLink(href);

  return <LinkElement href={resolvedPath} {...rest} />;
};
