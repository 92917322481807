import { motion } from 'framer-motion';
import { FlattenSimpleInterpolation } from 'styled-components';

import { styled } from '@/style';

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: header;
  z-index: foreground;
  flex: 1;
`;

export const Background = styled.divBox``;

export const PermanentContainer = styled(motion.div)<{
  css: FlattenSimpleInterpolation;
}>`
  height: 100%;
  flex: 1;
  ${({ css }) => css}
`;
export const HiddenContainer = styled(motion.div)<{
  $isVisible: boolean;
  css: FlattenSimpleInterpolation;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  ${({ css }) => css}
`;

export const Content = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: sm lg;
`;

export const LeftContainer = styled.divBox``;

export const CenterContainer = styled.divBox``;

export const RightContainer = styled.divBox``;
