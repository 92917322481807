import QuoteMarkSvg from '@/assets/icons/Quote Mark.svg';
import { styled, css, breakpoints } from '@/style';

export const QuoteContainer = styled.divBox`
  display: flex;
  padding: lg;
  flex-direction: column;
  background-color: palette-subtle;
  border-radius: 28px;
  ${breakpoints({
    phablet: css`
      padding: xl;
      align-items: flex-start;
      flex-direction: row;
    `,
  })}
`;

export const AuthorContainer = styled.divBox`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: lg;
`;
export const AuthorPic = styled.imgBox`
  width: 72px;
  height: 72px;
  border-radius: xl;
  margin-right: md;
`;
export const AuthorText = styled.divBox`
  margin-right: lg;
`;
export const AuthorName = styled.pBox`
  font-size: lg;
  font-weight: bold;
  margin-bottom: sm;
  ${breakpoints({
    phablet: css`
      white-space: nowrap;
    `,
  })}
`;
export const AuthorRole = styled.divBox`
  font-weight: bold;
  font-size: xs;
  ${breakpoints({
    phablet: css`
      min-width: 100px;
      /* white-space: nowrap; */
    `,
  })}
`;
export const AuthorComment = styled.divBox``;
export const QuoteMark = styled(QuoteMarkSvg)`
  position: absolute;
  top: -8px;
  left: -4px;
  width: 32px;
  height: 32px;
`;
