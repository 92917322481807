import { FC } from 'react';

import { Section } from '@/common/Layout';
import { PublishedArticleContent } from '@/common/blog/utils/use-article-story-formatter';
import { Hr } from '@/elements';
import { VStack } from '@/elements/layout';

import { Hero } from './hero';

import { RichText } from '../rich-text';

export const Content: FC<{
  article: PublishedArticleContent;
}> = ({ article }) => (
  <Section maxWidth='section-width' pt='lg'>
    <VStack>
      <Hero article={article} />
      <Hr variant='boldStart' />
      <RichText>{article.content}</RichText>
    </VStack>
  </Section>
);
