import { ReactNode } from 'react';

import OffsetAnchor from '@/common/OffsetAnchor';
import { Layout } from '@/elements';
import { StyledComponent } from '@/style';

const Section: StyledComponent<{
  id?: string;
  isFullPage?: boolean;
  maxWidth?: string;
  as?: ReactNode;
}> = ({
  id,
  as,
  isFullPage,
  maxWidth = 'section-width',
  children,
  ...rest
}) => (
  <Layout.Box as={as as any}>
    {id ? <OffsetAnchor id={id} /> : null}
    <Layout.Box
      bg='palette-bg'
      color='text'
      p='xl lg'
      minHeight={isFullPage ? '100vh' : 'section-height'}
      display='flex'
      alignItems='center'
      position='relative'
      {...rest}
    >
      <Layout.Box mx='auto' maxWidth={maxWidth} w='100%'>
        {children}
      </Layout.Box>
    </Layout.Box>
  </Layout.Box>
);

export default Section;
