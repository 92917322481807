import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import ScrollHr from '@/common/ScrollHr';
import { x } from '@/style';

const CtaSection: SbComponent = ({ content }) => (
  <SbEditable content={content}>
    <x.div my='xl'>
      <ScrollHr position='inline' />
    </x.div>
  </SbEditable>
);

export default CtaSection;
