import type { HomeViewComponent } from '../../types.d';

import Footer from '@/common/Layout/Footer';
import Palette from '@/common/Palette';
import ScrollHr from '@/common/ScrollHr';
import { DiamondMenu } from '@/common/diamond-menu';
import FooterReveal from '@/lib/FooterReveal';
import ScrollPagingView from '@/lib/ScrollPagingView';
import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';
import usePageState from '@/state/PageState';

import * as c from './components';

import HouseSprite from '../../HouseSprite';
import useHomePageState from '../../hooks/useHomePageState';
import HouseSection from '../HouseSection';
import MoreSection from '../MoreSection';
import {
  Container as PageAnimatorContainer,
  Child as ChildAnimatorContainer,
} from '../PageAnimator';

const ScrollPagingViewComponent: HomeViewComponent = ({
  content: { headline, buyer, seller, ea, more },
}) => {
  const { setPalette } = usePageState();
  const {
    activeState: {
      palette,
      isHouseVisible,
      isFooterVisible,
      houseFrame,
      activeId,
      nextHref,
    },
    scrollSpyState,
    scrollableStates,
    setActivePageId,
  } = useHomePageState({
    sections: [headline[0], buyer[0], seller[0], ea[0], more[0]],
    onActivePageChange: (page) => {
      setPalette(page.palette);
    },
  });
  const isBig = useIsUpAndMounted('laptop');
  const scrollSpyLayoutState = isBig
    ? { containerWidth: '200px', scrollSpyWidth: '132px', showLabel: true }
    : { containerWidth: '80px', scrollSpyWidth: '24px', showLabel: false };

  return (
    <Palette palette={palette}>
      <ScrollPagingView
        pages={scrollableStates}
        setActivePageId={setActivePageId}
        scrollTriggerDistance={200}
      >
        <FooterReveal
          footer={
            <c.FooterContainer isVisible={!!isFooterVisible}>
              <Footer isFooterVisible={!!isFooterVisible} />
            </c.FooterContainer>
          }
          isFooterVisible={!!isFooterVisible}
        >
          <c.ContentContainer>
            <c.ScrollSpyArea w={scrollSpyLayoutState.containerWidth}>
              <DiamondMenu
                w={scrollSpyLayoutState.scrollSpyWidth}
                options={scrollSpyState.map((page) => ({
                  id: page.id,
                  label: page.label,
                  href: `#${page.id}`,
                }))}
                activeId={activeId}
                showLabel={scrollSpyLayoutState.showLabel}
              />
            </c.ScrollSpyArea>
            <c.ContentArea>
              <c.HouseTextArea>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[0].id}
                >
                  <HouseSection content={headline[0]} />
                </PageAnimatorContainer>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[1].id}
                >
                  <HouseSection content={buyer[0]} />
                </PageAnimatorContainer>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[2].id}
                >
                  <HouseSection content={seller[0]} />
                </PageAnimatorContainer>
                <PageAnimatorContainer
                  isActive={activeId === scrollSpyState[3].id}
                >
                  <HouseSection content={ea[0]} />
                </PageAnimatorContainer>
              </c.HouseTextArea>
              <c.HouseSpriteArea>
                <PageAnimatorContainer isActive={!!isHouseVisible}>
                  <ChildAnimatorContainer
                    style={{ width: '100%', height: '100%' }}
                  >
                    <HouseSprite framePos={houseFrame || 0} />
                  </ChildAnimatorContainer>
                </PageAnimatorContainer>
              </c.HouseSpriteArea>
              <PageAnimatorContainer
                isActive={activeId === scrollSpyState[4].id}
              >
                <MoreSection content={more[0]} />
              </PageAnimatorContainer>
            </c.ContentArea>
          </c.ContentContainer>
          <PageAnimatorContainer isActive={!!nextHref}>
            <ScrollHr href={nextHref} />
          </PageAnimatorContainer>
        </FooterReveal>
      </ScrollPagingView>
    </Palette>
  );
};

export default ScrollPagingViewComponent;
