import type { SbRichText, TCtaContent } from 'storyblok';

import { FC } from 'react';

import { Section } from '@/common/Layout';
import Palette from '@/common/Palette';
import { CtaRow } from '@/common/storyblok/components';
import { Text } from '@/elements';
import { VStack } from '@/elements/layout';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

import { RichText } from '../rich-text';

export const CtaSection: FC<{
  title?: string;
  content: SbRichText;
  ctas?: Array<TCtaContent>;
}> = ({ title, content, ctas }) => (
  <Palette palette='dark'>
    <AnimateOnVisible>
      <Section>
        <VStack px={{ _: 0, tablet: 'lg' }}>
          {title ? (
            <Fade.Up>
              <Text variant='h2'>{title}</Text>
            </Fade.Up>
          ) : null}
          <Fade.Up>
            <x.div>
              <RichText>{content}</RichText>
            </x.div>
          </Fade.Up>
          <Fade.Up>
            <CtaRow ctas={ctas} justifyContent='flex-start' />
          </Fade.Up>
        </VStack>
      </Section>
    </AnimateOnVisible>
  </Palette>
);
