import { FC } from 'react';

import { styled } from '@/style';

import { context } from './context';

const ScrollContainer = styled.divBox`
  position: fixed;
  height: 100%;
  max-height: 100vh;
  max-height: view-height;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

export const MainScrollContainer: FC<{
  id: string;
}> = ({ id, children }) => (
  <context.Provider value={id}>
    <ScrollContainer id={id}>{children}</ScrollContainer>
  </context.Provider>
);
