import type { SbComponent, SbRichText, LinkedStory } from 'storyblok';

import SbEditable from 'storyblok-react';
import tinycolor from 'tinycolor2';

import { Section } from '@/common/Layout';
import { RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { useColor, x } from '@/style';

import * as c from './components';

const FaqAccordionSection: SbComponent<{
  title?: string;
  hasDescription: boolean;
  description?: SbRichText;
  items: Array<
    LinkedStory<
      SbComponent<{
        question: string;
        answer: SbRichText;
      }>
    >
  >;
  question: string;
  answer: SbRichText;
}> = ({ content }) => {
  const colorPrimary = useColor('palette-primary');
  return (
    <SbEditable content={content}>
      <AnimateOnVisible>
        <Section>
          <e.Layout.VStack spacing='sm'>
            <c.Header>
              <Fade.Up>
                <e.Text variant='h3'>{content.title || 'FAQ'}</e.Text>
              </Fade.Up>
              <Fade.Up>
                <e.Text variant='body1' position='relative' top='-2px'>
                  Have another question?{' '}
                  <e.Link variant='simpleLink' href='/contact/us?s=faq' ml='xs'>
                    Contact Us
                  </e.Link>
                </e.Text>
              </Fade.Up>
            </c.Header>
            {content.hasDescription && content.description ? (
              <Fade.Up>
                <x.div>
                  <RichText>{content.description}</RichText>
                </x.div>
              </Fade.Up>
            ) : null}
            <Fade.Up>
              <c.FaqList.Accordion>
                <c.FaqList.Container>
                  {content.items.map(({ content: itemContent }, index) => (
                    <c.FaqList.Item
                      key={itemContent.question}
                      id={itemContent.question}
                    >
                      <c.FaqList.Button
                        $isFirst={index === 0}
                        variants={{
                          'accordion-hover': {
                            color: colorPrimary,
                            backgroundColor: tinycolor(colorPrimary as string)
                              .setAlpha(0.04)
                              .toHex8String(),
                          },
                          'accordion-show': { color: colorPrimary },
                        }}
                      >
                        <c.FaqList.QuestionContainer>
                          <c.FaqList.LeftSideArrowContainer
                            variants={{
                              'accordion-hover': { x: 4 },
                              'accordion-show': { x: 4 },
                            }}
                          >
                            <c.FaqList.LeftSideArrow />
                          </c.FaqList.LeftSideArrowContainer>
                          <c.FaqList.Question>
                            {itemContent.question}
                          </c.FaqList.Question>
                        </c.FaqList.QuestionContainer>
                        <c.FaqList.Arrow />
                      </c.FaqList.Button>
                      <c.FaqList.ContentContainer>
                        <c.FaqList.Content>
                          <RichText>{itemContent.answer}</RichText>
                        </c.FaqList.Content>
                      </c.FaqList.ContentContainer>
                    </c.FaqList.Item>
                  ))}
                </c.FaqList.Container>
              </c.FaqList.Accordion>
            </Fade.Up>
            {/* <Fade.Up>
              <c.Footer>
                <e.Link variant='simpleLink' href='/read/faq'>
                  Read more
                </e.Link>
              </c.Footer>
            </Fade.Up> */}
          </e.Layout.VStack>
        </Section>
      </AnimateOnVisible>
    </SbEditable>
  );
};

export default FaqAccordionSection;
