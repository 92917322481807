import createProvider from '@/lib/Palette/createProvider';

import { createLightPalette } from './utils';

const palettes = {
  dark: (colors: any) => ({
    bg: colors['brand-dark'],
    text: colors['brand-white'],
    accent: colors['brand-orange'],
    subtle: colors['brand-beige'],
    border: colors['brand-beige-light'],
    primary: colors['brand-orange'],
    primaryText: colors['brand-dark'],
    primaryBorder: colors['brand-dark'],
    secondary: colors['brand-dark'],
    secondaryText: colors['brand-white'],
    secondaryBorder: colors['brand-white'],
  }),
  white: createLightPalette({
    bg: 'brand-white',
    secondaryBorder: 'brand-beige',
  }),
  lightYellow: createLightPalette({ bg: 'brand-light-yellow' }),
  lightOrange: createLightPalette({ bg: 'brand-light-orange' }),
  lightGreen: createLightPalette({
    bg: 'brand-light-green',
    subtle: 'brand-beige',
  }),
  lightRed: createLightPalette({ bg: 'brand-light-red', subtle: 'brand-grey' }),
};

export type TPalette = keyof typeof palettes;
export default createProvider(palettes);
