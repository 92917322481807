import type { SbRichText } from 'storyblok';

import { FC } from 'react';

import * as e from '@/elements';
import { x } from '@/style';

import Check from './Comparison Icon - Check.svg';
import Cross from './Comparison Icon - Cross.svg';

import RichText from '../RichText';

const icon = {
  check: <Check width='22px' height='22px' />,
  cross: <Cross width='22px' height='22px' />,
};

const ComparisonRow: FC<{
  type: keyof typeof icon;
  text: SbRichText;
}> = ({ type, text }) => (
  <e.Layout.HStack spacing='10px'>
    <x.div>{icon[type]}</x.div>
    <x.div position='relative' top='2px'>
      <RichText>{text}</RichText>
    </x.div>
  </e.Layout.HStack>
);

export default ComparisonRow;
