import { styled, css, breakpoints } from '@/style';

// eslint-disable-next-line import/prefer-default-export
export const CardContainer = styled.divBox`
  border: 1px solid;
  border-color: palette-border;
  padding: lg;
  border-radius: xl;
  background-color: brand-bright-white;
  ${breakpoints({
    tablet: css`
      padding: xl lg;
    `,
  })}
`;
