import { useMemo } from 'react';

import { ArticlesCollectionProps } from '@/common/blog/types';

import { getLinkAttrsFromCollection } from './get-link-attrs-from-collection';

export const useMenuOptions = (collections: Array<ArticlesCollectionProps>) => {
  const menuOptions = useMemo(
    () => collections.map(getLinkAttrsFromCollection),
    [collections]
  );
  return {
    menuOptions,
  };
};
