import { FC, useEffect } from 'react';

import config from '@/config';
import Canvas, { scaleImageToCover } from '@/lib/Canvas';
import useSpritePlayer, { SpriteSheet, TSpriteSheetData } from '@/lib/Sprite';

import hdData from './data/hd.json';
import mdData from './data/md.json';
import sdData from './data/sd.json';
import useResponsiveSpriteQuality from './useResponsiveSpriteQuality';

const { imageCdn, housePath } = config;

const sd = sdData as TSpriteSheetData;
const md = mdData as TSpriteSheetData;
const hd = hdData as TSpriteSheetData;

const sdSheet = new SpriteSheet(sd, {
  getImageSrc: (sheet) => `${imageCdn}/${housePath}/sd/${sheet.imageFileName}`,
});
const mdSheet = new SpriteSheet(md, {
  getImageSrc: (sheet) => `${imageCdn}/${housePath}/md/${sheet.imageFileName}`,
});
const hdSheet = new SpriteSheet(hd, {
  getImageSrc: (sheet) => `${imageCdn}/${housePath}/hd/${sheet.imageFileName}`,
});

const sprites = {
  sd: sdSheet,
  md: mdSheet,
  hd: hdSheet,
};
const HouseSprite: FC<{
  framePos: number;
}> = ({ framePos: requestedFramePos }) => {
  const spritePlayer = useSpritePlayer<keyof typeof sprites>({
    sprites,
    initialGroup: 'sd',
  });
  useResponsiveSpriteQuality(spritePlayer);

  useEffect(() => {
    spritePlayer.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Canvas
      style={{ width: '100%', height: '100%' }}
      draw={({ context, timestamp, canvas, devicePixelRatio }) => {
        const frame = spritePlayer.getFrame(requestedFramePos, timestamp);

        if (frame) {
          const fromSize = frame.sourceSize;
          const toSize = {
            w: canvas.width,
            h: canvas.height,
          };
          const dest = scaleImageToCover(
            frame.spriteSourceSize,
            fromSize,
            toSize,
            devicePixelRatio
          );

          context.drawImage(
            frame.image,
            frame.frame.x,
            frame.frame.y,
            frame.frame.w,
            frame.frame.h,
            dest.x,
            dest.y,
            dest.w,
            dest.h
          );
        }
      }}
    />
  );
};

export default HouseSprite;
