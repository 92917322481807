import { FC } from 'react';

import { motion } from 'framer-motion';

import * as e from '@/elements';
import { styled } from '@/style';

import * as c from './components';

const StyledButton = styled(e.Button)`
  padding: 0;
  text-align: left;
  font-weight: 600;
  opacity: 0.7;
  transition: base;
  :hover {
    opacity: 1;
  }
`;

export const FooterButton: FC<{
  onClick: () => void;
  trackName?: string;
}> = ({ onClick, trackName, children }) => (
  <motion.div variants={c.variants.listItem}>
    <StyledButton variant='blank' onClick={onClick} trackName={trackName}>
      {children}
    </StyledButton>
  </motion.div>
);
