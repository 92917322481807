/* eslint-disable import/no-cycle */
import type { SbComponent } from 'storyblok';

import { Page } from '@/common/Layout';
import { ArticleMeta, ArticlePage } from '@/common/blog/article-page';
import { ArticleProps } from '@/common/blog/types';
import { Title } from '@/lib/snippets/title';

import Seo from '../../components/Seo';

export const BlogArticle: SbComponent<ArticleProps> = ({ content, story }) => (
  <>
    <Title>{content.title}</Title>
    <ArticleMeta
      title={content.title}
      image={content.headlineImage}
      summary={content.content?.[0].summary}
      keywords={story?.tag_list}
    />
    <Page.Main pb={{ _: 0, tablet: 'xl' }} alignItems='center'>
      {content.seo ? <Seo content={content.seo} /> : null}
      <ArticlePage content={content} />
    </Page.Main>
  </>
);
