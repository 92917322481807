import type { SbRichText } from 'storyblok';

import { FC } from 'react';

import {
  RichText as RichTextComp,
  RichTextLink,
} from '@/common/storyblok/components';
import { Hr, Strong, Text } from '@/elements';
import { x } from '@/style';

export const RichText: FC<{
  children: SbRichText;
}> = ({ children }) => (
  <RichTextComp
    resolver={{
      H2: (props) => <Text variant='h2' pt='lg' {...props} />,
      H3: (props) => <Text variant='h3' pt='md' {...props} />,
      Bold: (props) => <Strong {...props} />,
      Link: (props) => <RichTextLink variant='simpleLink' {...props} />,
      Hr: (props) => (
        <x.div pt='md'>
          <Hr variant='boldStart' {...props} />
        </x.div>
      ),
    }}
  >
    {children}
  </RichTextComp>
);
