import { forwardRef } from 'react';

const Pulse = forwardRef<
  SVGGElement,
  {
    dotRadius?: number;
    pulseRadius?: number;
    strokeWidth?: number;
    dur?: number;
    delay?: number;
  }
>(
  (
    { dotRadius = 4, pulseRadius = 8, strokeWidth = 2, dur = 1.5, delay = 1 },
    ref
  ) => (
    <g ref={ref} transform='scale(0)'>
      <circle
        cx={dotRadius}
        cy={dotRadius}
        fill='none'
        r={0}
        stroke='currentColor'
        opacity='0'
        strokeWidth={strokeWidth}
      >
        <animate
          id='pulse'
          attributeName='r'
          from={dotRadius - strokeWidth}
          to={pulseRadius}
          dur={`${dur}s`}
          begin={`${delay}s;pulse.end+${delay}s`}
        />
        <animate
          attributeName='opacity'
          from='1'
          to='0'
          dur={`${dur}s`}
          begin={`${delay}s;pulse.end+${delay}s`}
        />
        <animate
          attributeName='stroke-width'
          from={strokeWidth}
          to='0'
          dur={`${dur}s`}
          begin={`${delay}s;pulse.end+${delay}s`}
        />
      </circle>
      <circle cx={dotRadius} cy={dotRadius} fill='currentColor' r={dotRadius} />
    </g>
  )
);

export default Pulse;
