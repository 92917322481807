import type { SbComponent } from 'storyblok';

import { ScrollSpyContainer } from '@/common/scroll-spy';
import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';

import { DesktopUserResources } from './desktop';
import { MobileUserResources } from './mobile';

import { useResourceHubLayout } from '../context';
import { UserResourcesProps } from '../types';

export const BlogUserResources: SbComponent<UserResourcesProps> = ({
  content: { name, navTitle, collections },
}) => {
  const { contentTop } = useResourceHubLayout();
  const isTabletOrUp = useIsUpAndMounted('tablet');

  return (
    <ScrollSpyContainer
      offsetTop={`-${contentTop}px`}
      options={collections.map((collection) => collection.hashLabel)}
    >
      {isTabletOrUp ? (
        <DesktopUserResources
          name={name}
          navTitle={navTitle}
          collections={collections}
        />
      ) : (
        <MobileUserResources
          name={name}
          navTitle={navTitle}
          collections={collections}
        />
      )}
    </ScrollSpyContainer>
  );
};
