import { FC } from 'react';

import Rocket from '@/assets/icons/Rocket.svg';
import * as e from '@/elements';
import { styled, css } from '@/style';

const Link = styled(e.Link)<{
  isExpanded: boolean;
  inExpandedContainer: boolean;
}>`
  transition: base;
  ${({ isExpanded }) =>
    isExpanded
      ? null
      : css`
          color: palette-primary;
          background: transparent;
          border-color: none;
          box-shadow: none;
          padding-left: sm;
          padding-right: sm;
        `}
  ${({ inExpandedContainer }) =>
    inExpandedContainer
      ? css`
          opacity: 0;
        `
      : css`
          position: relative;
          z-index: 9999999999;
        `}}
`;
const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Text = styled.span<{
  isExpanded: boolean;
}>`
  padding-left: sm;
  transition: fast;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          opacity: 1;
          width: 120px;
        `
      : css`
          opacity: 0;
          width: 0;
        `}
`;

const RocketButton: FC<{
  isExpanded: boolean;
  inExpandedContainer?: boolean;
}> = ({ inExpandedContainer, isExpanded }) => (
  <Link
    trackName='nav.get-started'
    variant='rounded'
    href='https://app.housecure.co.uk'
    inExpandedContainer={!!inExpandedContainer}
    isExpanded={inExpandedContainer || isExpanded}
  >
    <ButtonContent>
      <Rocket width='20px' height='20px' style={{ display: 'inline-block' }} />
      <Text isExpanded={inExpandedContainer || isExpanded}>Get started</Text>
    </ButtonContent>
  </Link>
);
export default RocketButton;
