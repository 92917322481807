import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import OffsetAnchor from '@/common/OffsetAnchor';
import * as e from '@/elements';
import { OnVisible } from '@/lib/utils/animation';

import Card, { TCardContent } from './Card';

const CardsSection: SbComponent<{
  cards: Array<TCardContent>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section maxWidth='900px'>
      <e.Layout.VStack spacing='2xl'>
        {content.cards.map((card) => (
          <OnVisible.FadeUp key={card.id}>
            <OffsetAnchor id={card.id} />
            <Card content={card} />
          </OnVisible.FadeUp>
        ))}
      </e.Layout.VStack>
    </Section>
  </SbEditable>
);
export default CardsSection;
