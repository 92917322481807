import LeftArrowSvg from '@/assets/iconly/Arrow - Left.svg';
import { StyledComponent, x } from '@/style';

export const BackTextAndIcon: StyledComponent = (props) => (
  <x.span
    {...props}
    display='flex'
    flexDirection='row'
    alignItems='center'
    fontWeight='500'
  >
    <LeftArrowSvg width='18px' height='18px' />
    <x.span pl='sm' pt='3px'>
      Back
    </x.span>
  </x.span>
);
