import { FC } from 'react';

import useHasMounted from '@/lib/hooks/useHasMounted';

const ClientSide: FC = ({ children }) => {
  const isClientSide = useHasMounted();
  return isClientSide ? <>{children}</> : null;
};

export default ClientSide;
