import type { IJourneyStepContent } from './types.d';

import { FC } from 'react';

import SbEditable from 'storyblok-react';

import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';
import { StaggerChildren } from '@/lib/utils/animation';
import { x } from '@/style';

import Pulse from './Pulse';
import StepContent from './StepContent';
import * as c from './components';
import useAnimator from './useAnimator';

const Step: FC<{
  step: IJourneyStepContent;
  nextStep: IJourneyStepContent | null;
  stepNo: number;
  animationOffset: number;
  isLast?: boolean;
  lineWidth?: number;
  curveRadius?: number;
  joinGap?: number;
  dotRadius?: number;
  pulseRadius?: number;
}> = ({
  step,
  nextStep,
  stepNo,
  isLast,
  animationOffset,
  lineWidth = 1,
  curveRadius = 30,
  joinGap = 10,
  dotRadius = 3,
  pulseRadius = 14,
}) => {
  const isLargeScreen = useIsUpAndMounted('tablet');
  const nextStepIsImportantAndCurrentIsEven =
    stepNo % 2 === 1 && nextStep?.isImportant;
  const nextStepIsOdddCurrentIsImportant = stepNo % 2 === 0 && step.isImportant;
  const needsStraightLineForImportantSection =
    nextStepIsImportantAndCurrentIsEven || nextStepIsOdddCurrentIsImportant;
  const isAlternatingEnabled =
    isLargeScreen && !needsStraightLineForImportantSection;

  const { motionValue, isVisible, svgRef, pathRef, pulseRef } = useAnimator({
    stepNo,
    isLast: !!isLast,
    dotRadius,
    pulseRadius,
    animationOffset,
    lineWidth,
    curveRadius,
    joinGap,
    isAlternatingEnabled,
  });

  return (
    <SbEditable content={step}>
      <x.div position='relative' id={`step-container-${stepNo}`}>
        <c.Step.Svg ref={svgRef as any}>
          <c.Step.Path
            ref={pathRef as any}
            strokeWidth={lineWidth}
            style={{
              pathLength: motionValue,
            }}
          />
          <Pulse
            ref={pulseRef as any}
            dotRadius={dotRadius}
            pulseRadius={pulseRadius}
            strokeWidth={4}
          />
        </c.Step.Svg>
        <StaggerChildren animate={isVisible ? 'show' : 'hide'}>
          <StepContent step={step} stepNo={stepNo} />
        </StaggerChildren>
      </x.div>
    </SbEditable>
  );
};

export default Step;
