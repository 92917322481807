import { FC } from 'react';

import { usePopoverState, Popover } from '@welcome-ui/popover';
import { motion } from 'framer-motion';

import { Button, Layout } from '@/elements';
import Arrow from '@/lib/Arrow';
import ClientSide from '@/lib/ClientSide';
import { INestedItem } from '@/state/AppState';
import { x } from '@/style';

import * as c from './components';
import { LinkNavItem } from './link-nav-item';
import { NestedNavItem } from './nested-nav-item';

const DropdownNavItem: FC<Pick<INestedItem, 'ariaLabel' | 'items'>> = ({
  children,
  ariaLabel,
  items,
}) => {
  const popover = usePopoverState({
    triggerMethod: 'hover',
    placement: 'bottom',
    unstable_fixed: true,
    gutter: 10,
    showTimeout: 0,
  });

  return (
    <ClientSide>
      <x.div position='relative'>
        <Popover.Trigger
          {...popover}
          as={Button}
          variant='nav'
          onClick={() => {
            popover.show();
          }}
        >
          {children}
        </Popover.Trigger>
        {/*
         * Place overlay over trigger until finished animating
         * otherwise the dropdown doesn't correctly position
         * itself
         */}
        <c.Dropdown.Overlay />
        <Popover
          {...popover}
          aria-label={ariaLabel}
          style={{ background: 'transparent' }}
          arrowStyle={{ display: 'none' }}
        >
          <motion.div
            animate={popover.visible ? 'show' : 'hide'}
            transition={{ staggerChildren: 0.1 }}
            variants={{
              show: {
                y: 0,
                opacity: 1,
                transition: {
                  staggerChildren: 0.1,
                  duration: 0.4,
                  delayChildren: 0.2,
                },
              },

              hide: {
                y: 14,
                opacity: 0,
              },
            }}
          >
            <Arrow
              $size={6}
              $borderWidth={1}
              $border='palette-border'
              $background='palette-bg'
              borderRadius='lg'
              $startPos='calc(50% - 40px)'
              position='relative'
              left='40px'
            >
              <c.Dropdown.Container>
                <Layout.VStack spacing='sm' alignItems='stretch' py='sm'>
                  {items.map((item) => {
                    switch (item.type) {
                      case 'link':
                        return (
                          <LinkNavItem
                            key={item.id}
                            {...item}
                            onSelect={popover.hide}
                          />
                        );
                      case 'nested': {
                        return (
                          <NestedNavItem
                            key={item.id}
                            {...item}
                            onSelect={popover.hide}
                          />
                        );
                      }
                      case 'button':
                      default:
                        throw new Error(`Not implemented "${item.type}"`);
                    }
                  })}
                </Layout.VStack>
              </c.Dropdown.Container>
            </Arrow>
          </motion.div>
        </Popover>
      </x.div>
    </ClientSide>
  );
};

export default DropdownNavItem;
