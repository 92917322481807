import config from '@/config';
import { Client, utils } from '@/lib/storyblok';

const client = new Client(config.storyblok.token);

const relationsToAutomaticallyResolveForPage = [
  'story.story',
  'FaqAccordionSection.items',
  'BlogArticlesCollection.articles',
  'BlogArticleContent.recommendedArticles',
];
const relationsToAutomaticallyResolveForPageText =
  relationsToAutomaticallyResolveForPage.join(',');
export const slugHelper = utils.createSlugHelper({
  prefixToRemove: 'website',
});

export const fetchLinks = () => client.getLinks();

export const fetchCommonContent = () =>
  client.get('cdn/stories/support/common', {
    resolve_relations: 'story.story',
    resolve_links: 'url',
  });

export const fetchPageContent = <T>(slug: string) =>
  client.getStory<T>(`${slug}`, {
    resolve_relations: relationsToAutomaticallyResolveForPageText,
    resolve_links: 'url',
  });

export const fetchPage = <T>(slug: string) =>
  Promise.all([
    // fetchCommonContent(),
    fetchPageContent<T>(slug),
    fetchLinks(),
  ]);

export default client;
