import type { SbImage } from 'storyblok';

import { PropsWithChildren } from 'react';

import { motion, Variant } from 'framer-motion';

import { Section } from '@/common/Layout';
import * as e from '@/elements';
import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';
import { x } from '@/style';

import { LayeredImageContainer, LayeredImage } from '../layered-image';

const animationVariants: Record<string, (isReversed: boolean) => Variant> = {
  rotate: (isReversed) => ({
    rotateY: isReversed ? '1deg' : '-1deg',
  }),
  none: () => ({}),
};
export const SideScreenshot = ({
  children,
  screenshot,
  reversed = false,
  disableAnimation = false,
  disableCard = false,
}: PropsWithChildren<{
  screenshot: Array<SbImage>;
  reversed?: boolean;
  disableAnimation?: boolean;
  disableCard?: boolean;
}>) => {
  const breakpoint = 'tablet';
  const isUp = useIsUpAndMounted(breakpoint);
  const animationVariant =
    animationVariants[disableAnimation ? 'none' : 'rotate'];
  return (
    <LayeredImageContainer>
      <Section maxWidth='section-width-xl' px={0} overflow='hidden'>
        <e.Layout.Stack
          style={{ perspective: '250px' }}
          flexDirection={{
            _: 'column',
            [breakpoint]: reversed ? 'row-reverse' : 'row',
          }}
          gap={{
            _: 'xl',
            [breakpoint]: 'md',
          }}
          alignItems='center'
          justifyContent='center'
        >
          <x.div>{children}</x.div>
          <x.div
            as={motion.div}
            position='relative'
            px='md'
            {...(reversed
              ? { right: '20px', transformOrigin: 'right' }
              : { left: '20px', transformOrigin: 'left' })}
            {...(disableCard
              ? {}
              : {
                  background: 'white',
                  boxShadow: 'xl',
                  borderRadius: 'xl',
                })}
            right={{ _: '0', [breakpoint]: undefined }}
            left={{ _: '0', [breakpoint]: undefined }}
            overflow='hidden'
            variants={{
              containerHover: isUp ? animationVariant(reversed) : {},
            }}
          >
            <LayeredImage images={screenshot} />
          </x.div>
        </e.Layout.Stack>
      </Section>
    </LayeredImageContainer>
  );
};
