import { motion } from 'framer-motion';

import { Link } from '@/elements';
import { ILinkItem } from '@/state/AppState';

import * as c from './components';

export const LinkNavItem = ({
  href,
  trackName,
  target,
  label,
  onSelect,
}: ILinkItem & {
  onSelect: () => any;
}) => (
  <motion.div
    variants={{
      show: { opacity: 1, y: 0 },
      hide: { opacity: 0, y: 8 },
    }}
    key={href}
  >
    <c.Dropdown.RowLink
      as={Link}
      href={href}
      onClick={onSelect}
      trackName={trackName}
      target={target}
    >
      <c.Dropdown.LinkArrow />
      <c.Dropdown.LinkText>{label}</c.Dropdown.LinkText>
    </c.Dropdown.RowLink>
  </motion.div>
);
