import type { SbComponent, SbRichText } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import SectionTitle from '@/common/SectionTitle';
import {
  RichText,
  RichSentence,
  TRichSentence,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { OnVisible } from '@/lib/utils/animation';

import ProfileRow from './ProfileRow';

const ProfilesSection: SbComponent<{
  title: TRichSentence;
  intro?: SbRichText;
  buyerText: TRichSentence;
  sellerText: TRichSentence;
  eaText: TRichSentence;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section maxWidth='section-width'>
      <e.Layout.VStack spacing='lg'>
        <SectionTitle>
          <OnVisible.FadeUp>
            <RichSentence variant='h2'>{content.title}</RichSentence>
          </OnVisible.FadeUp>
        </SectionTitle>
        {content.intro ? (
          <OnVisible.FadeUp>
            <RichText>{content.intro}</RichText>
          </OnVisible.FadeUp>
        ) : null}
        <OnVisible.FadeUp>
          <ProfileRow
            symbolName='buyer'
            href='/for/buyers'
            title='Buyer'
            text={content.buyerText}
            pos='top'
          />
          <ProfileRow
            symbolName='seller'
            href='/for/sellers'
            title='Seller'
            text={content.sellerText}
            pos='middle'
          />
          <ProfileRow
            symbolName='ea'
            href='/for/estate-agents'
            title='Estate agents'
            text={content.eaText}
            pos='bottom'
          />
        </OnVisible.FadeUp>
      </e.Layout.VStack>
    </Section>
  </SbEditable>
);

export default ProfilesSection;
