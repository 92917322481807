/* eslint-disable import/no-cycle */
import { createDynamicComponent } from '@/lib/storyblok';

import Root from './Root';
import DynamicHero, { components as heros } from './heros';
import DynamicPage, { components as pages } from './pages';
import DynamicSection, { components as sections } from './sections';

export * from './utils';

const components = {
  ...sections,
  ...heros,
  ...pages,
};

type TComponent = typeof components;
type TKeys = keyof TComponent;
type TValues = TComponent[TKeys];

const DynamicComponent = createDynamicComponent<TValues>(components);

export default DynamicComponent;
export { DynamicHero, DynamicPage, DynamicSection, Root };
