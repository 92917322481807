type TPathProps = {
  startPos: { x: number; y: number };
  endPos: { x: number; y: number };
  lineWidth: number;
  curveRadius: number;
  joinGap: number;
  pulseRadius: number;
};

const createForwardPath = ({
  startPos,
  endPos,
  lineWidth,
  curveRadius,
  joinGap,
  pulseRadius,
}: TPathProps) => {
  const canvas = {
    x: startPos.x - lineWidth / 2 - pulseRadius,
    y: startPos.y - pulseRadius,
    width: endPos.x - startPos.x + lineWidth + 2 * pulseRadius,
    height: endPos.y - startPos.y + 2 * pulseRadius,
  };
  const { width, height } = canvas;
  const xPadding = pulseRadius;
  const yPadding = joinGap + pulseRadius;
  return {
    canvas,
    path: `
      M${lineWidth / 2 + xPadding},${yPadding} 
      V${height - 2 * curveRadius - 2 * yPadding}
      Q${lineWidth / 2 + xPadding},${height - curveRadius - yPadding} 
      ${lineWidth / 2 + curveRadius + xPadding},${
      height - curveRadius - yPadding
    }
      H${width - lineWidth / 2 - curveRadius - 2 * xPadding}
      Q${width - lineWidth / 2 - xPadding},${height - curveRadius - yPadding} 
      ${width - lineWidth / 2 - xPadding},${height - yPadding}
      V${height - yPadding}
    `,
  };
};

const createReversePath = ({
  startPos,
  endPos,
  lineWidth,
  curveRadius,
  joinGap,
  pulseRadius,
}: TPathProps) => {
  const canvas = {
    x: endPos.x - lineWidth / 2 - pulseRadius,
    y: startPos.y - pulseRadius,
    width: startPos.x - endPos.x + lineWidth + 2 * pulseRadius,
    height: endPos.y - startPos.y + 2 * pulseRadius,
  };
  const { width, height } = canvas;
  const xPadding = pulseRadius;
  const yPadding = joinGap + pulseRadius;
  return {
    canvas,
    path: `
      M${width - lineWidth / 2 - xPadding},${yPadding} 
      V${height - 2 * curveRadius - 2 * yPadding}
      Q${width - lineWidth / 2 - xPadding},${height - curveRadius - yPadding} 
      ${width - lineWidth / 2 - curveRadius - xPadding},${
      height - curveRadius - yPadding
    }
      H${lineWidth / 2 + curveRadius + xPadding}
      Q${lineWidth / 2 + xPadding},${height - curveRadius - yPadding} 
      ${lineWidth / 2 + xPadding},${height - yPadding}
      V${height - yPadding}
    `,
  };
};

const createStraightPath = ({
  startPos,
  endPos,
  lineWidth,
  joinGap,
  pulseRadius,
}: TPathProps) => {
  const canvas = {
    x: endPos.x - lineWidth / 2 - pulseRadius,
    y: startPos.y - pulseRadius,
    width: startPos.x - endPos.x + lineWidth + 2 * pulseRadius,
    height: endPos.y - startPos.y + 2 * pulseRadius,
  };
  const { width, height } = canvas;
  const xPadding = pulseRadius;
  const yPadding = joinGap + pulseRadius;
  return {
    canvas,
    path: `
      M${width - lineWidth / 2 - xPadding},${yPadding} 
      V${height - yPadding}
    `,
  };
};

export const createPath = (
  props: TPathProps,
  isAlternatingEnabled: boolean
) => {
  if (isAlternatingEnabled) {
    const isReverse = props.startPos.x > props.endPos.x;

    return isReverse ? createReversePath(props) : createForwardPath(props);
  }
  return createStraightPath(props);
};

export const drawPath = ({
  stepContainer,
  startElement,
  endElement,
  svg,
  path,
  lineWidth,
  curveRadius,
  joinGap,
  pulseRadius,
  isAlternatingEnabled,
}: {
  stepContainer?: HTMLElement | null;
  startElement?: HTMLElement | null;
  endElement?: HTMLElement | null;
  svg?: SVGElement;
  path?: SVGPathElement;
  lineWidth: number;
  curveRadius: number;
  joinGap: number;
  pulseRadius: number;
  isAlternatingEnabled: boolean;
}) => {
  if (stepContainer && startElement && endElement && svg && path) {
    const stepContainerRec = stepContainer.getBoundingClientRect();
    const startRec = startElement.getBoundingClientRect();
    const endRec = endElement.getBoundingClientRect();

    const startPos = {
      x: startRec.x + startRec.width / 2 - stepContainerRec.x,
      y: startRec.bottom - stepContainerRec.y,
    };
    const endPos = {
      x: endRec.x + endRec.width / 2 - stepContainerRec.x,
      y: endRec.top - stepContainerRec.y,
    };

    const { canvas, path: pathDefinition } = createPath(
      {
        startPos,
        endPos,
        lineWidth,
        curveRadius,
        joinGap,
        pulseRadius,
      },
      isAlternatingEnabled
    );
    svg.setAttribute(
      'style',
      `
      position: absolute;
      left: ${canvas.x}px; 
      top: ${canvas.y}px;
      width: ${canvas.width}px;
      height: ${canvas.height}px;
    `
    );
    path.setAttribute('d', pathDefinition);
  }
};
