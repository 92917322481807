import { FC, useMemo, useState } from 'react';

import { motion } from 'framer-motion';
import take from 'lodash/take';

import { ArticlesCollectionProps } from '@/common/blog/types';
import { useArticleStoryFormatter } from '@/common/blog/utils/use-article-story-formatter';

import { context } from './context';
import { useColumnCount } from './use-column-count';

const SHOW_NAME = 'expanded-show';
const HIDE_NAME = 'expanded-hide';

export const ArticlesFeedContainer: FC<{
  articles: ArticlesCollectionProps['articles'];
  maxColumnCount?: number;
}> = ({ maxColumnCount, articles, children }) => {
  const [isExpanded, setExpanded] = useState(false);
  const columnCount = useColumnCount({
    maxColumnCount,
  });

  const { formatArticleStory } = useArticleStoryFormatter();
  const orderedArticles = useMemo(
    () =>
      articles
        .map((article) => ({
          uuid: article.uuid,
          article: formatArticleStory(article.content),
        }))
        .sort(({ article }) => (article.status === 'published' ? 1 : 0)),
    [articles]
  );
  const visibleArticleCount: number = isExpanded
    ? articles.length
    : columnCount || 0;

  return (
    <context.Provider
      value={{
        isExpanded,
        setExpanded,
        toggleExpanded: () => setExpanded((is) => !is),
        showAnimationVariant: SHOW_NAME,
        hideAnimationVariant: HIDE_NAME,
        columnCount,
        hasMoreArticles: columnCount ? columnCount < articles.length : false,
        visibleArticles: take(orderedArticles, visibleArticleCount),
      }}
    >
      <motion.div animate={isExpanded ? SHOW_NAME : HIDE_NAME}>
        {children}
      </motion.div>
    </context.Provider>
  );
};
