import { FC } from 'react';

import { motion } from 'framer-motion';

import { cssOnVisible } from '@/lib/ScrollPagingView';
import { styled } from '@/style';

const FullPageContainer = styled(motion.div)<{
  isActive: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) => isActive && cssOnVisible}
`;

const Container: FC<{
  isActive: boolean;
}> = ({ isActive, children }) => (
  <FullPageContainer
    isActive={isActive}
    animate={isActive ? 'show' : 'hide'}
    initial='hide'
    variants={{
      show: {
        opacity: 1,
        zIndex: 1,
        transition: {
          staggerChildren: 0.1,
          delayChildren: 0.2,
        },
      },
      hide: {
        opacity: 0,
        pointerEvents: 'none',
        zIndex: 0,
      },
    }}
    transition={{
      staggerChildren: 0.1,
      type: 'tween',
    }}
  >
    {children}
  </FullPageContainer>
);

export default Container;
