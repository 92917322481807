import { FC } from 'react';

import { motion } from 'framer-motion';

import * as e from '@/elements';

import * as c from './components';

const Title: FC = ({ children }) => (
  <motion.div variants={c.variants.listItem}>
    <e.Text variant='h5' fontWeight='bold' textTransform='uppercase'>
      {children}
    </e.Text>
    <e.Hr mt={0} />
  </motion.div>
);

export default Title;
