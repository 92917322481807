import { useGetContextOrError } from '@/lib/hooks/use-get-context-or-error';

import { context } from './context';

export const useActiveOption = () => {
  const { activeOption } = useGetContextOrError(context, 'Scroll Spy');
  return {
    activeOption,
  };
};
