/* eslint-disable import/no-cycle */
import type { SbComponent, SbComponentContent } from 'storyblok';

import { Page } from '@/common/Layout';
import { TPalette } from '@/common/Palette';
import { ResourceHubHero } from '@/common/blog';
import { ResourceHubProvider } from '@/common/blog/context';
import { BlogUserResources } from '@/common/blog/user-resources';
import { useDisableScrollToTopOnRouteChange } from '@/lib/scroll-to-top-on-route-change';

import { PageTitle } from '../../components';
import Seo from '../../components/Seo';

export const ResourceHub: SbComponent<{
  palette: TPalette;
  content: SbComponentContent<typeof BlogUserResources>;
  seo: SbComponentContent<typeof Seo>;
}> = ({ content, story }) => {
  useDisableScrollToTopOnRouteChange();

  return (
    <ResourceHubProvider>
      <PageTitle content={content} story={story} />
      <Page.Main
        defaultPalette={content.palette}
        pb={{ _: 0, tablet: 'xl' }}
        alignItems='center'
      >
        {content.seo ? <Seo content={content.seo} /> : null}
        <ResourceHubHero />

        <BlogUserResources content={content.content[0]} />
      </Page.Main>
    </ResourceHubProvider>
  );
};
