import { FC } from 'react';

import { motion } from 'framer-motion';

import * as e from '@/elements';

import * as c from './components';

const List: FC<{
  isVisible?: boolean;
  colNo: number;
}> = ({ isVisible, colNo, children }) => (
  <motion.div
    animate={isVisible ? 'visible' : 'hidden'}
    variants={c.variants.list}
    custom={colNo}
    style={{
      flex: '1',
      maxWidth: '180px',
    }}
  >
    <e.Layout.VStack spacing='18px'>{children}</e.Layout.VStack>
  </motion.div>
);

export default List;
