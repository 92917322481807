import { FC, createContext, useContext, useState } from 'react';

const context = createContext<{
  selectedItemId?: string;
  setSelectedItemId: (id?: string) => void;
  animationPrefix: string;
}>(undefined as any);

export const Provider: FC<{
  defaultSelectedItemId?: string;
  animationPrefix?: string;
}> = ({ defaultSelectedItemId, animationPrefix = 'accordion-', children }) => {
  const [selectedItemId, setSelectedItemId] = useState(defaultSelectedItemId);
  return (
    <context.Provider
      value={{
        selectedItemId,
        setSelectedItemId,
        animationPrefix,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default function useAccordion() {
  const c = useContext(context);
  if (!c) {
    throw new Error('useAccordion must be used within a AccordionProvider');
  }
  return c;
}
