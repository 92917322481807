import * as e from '@/elements';
import { styled } from '@/style';

export const ContentContainer = styled.divBox`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Subtitle = styled(e.Text)`
  font-size: md;
  font-weight: 700;
  padding-bottom: sm;
  color: palette-primary;
  text-transform: uppercase;
`;

export const Content = styled.divBox``;
