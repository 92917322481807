import { useDrawerState as useWelcomeUiDrawerState } from '@welcome-ui/drawer';

export const useDrawerState = () => {
  const {
    visible: isOpen,
    show: open,
    hide: close,
    setVisible: setOpen,
    ...rest
  } = useWelcomeUiDrawerState();
  return {
    isOpen,
    open,
    close,
    setOpen,
    ...rest,
  };
};
