import type { TSpriteSheetData, TFrame, TSheetData } from './types';

import { prioritiseToNextPop, iterateBetween } from './utils';

type TSheetStatus = Record<
  string,
  'unloaded' | 'loadRequested' | 'loading' | 'loaded'
>;
type TConfig = {
  getImageSrc: (frameData: TSheetData) => string;
};

/**
 * Load automatically or on request?
 * Try on request first to see if it
 */
export default class SpriteSheet {
  private _sheetData: TSpriteSheetData;
  private _config: TConfig;

  private _hasStartedLoading = false;

  private _images: Record<string, HTMLImageElement> = {};

  // private _sheetToSpritePosMapping: Array<Array<number>>;
  private _sheetStatus: TSheetStatus;
  private _sheetsToLoad: Array<string> = [];

  private _availableFrames: Record<number, TFrame> = {};
  private _loadedSpritePositions: Array<number> = [];

  constructor(data: TSpriteSheetData, config: TConfig) {
    this._sheetData = data;
    this._config = config;
    this._sheetsToLoad = data.sheets
      .sort((a, b) => b.priority - a.priority)
      .map(({ id }) => id);
    this._sheetStatus = data.sheets.reduce((acc, curr) => {
      acc[curr.id] = 'loadRequested';
      return acc;
    }, {} as TSheetStatus);
  }

  public get count(): number {
    return this._sheetData.count;
  }

  private _addLoadedSpritePositions = (sheetId: string) => {
    const sheet = this._getSheet(sheetId);

    this._loadedSpritePositions = this._loadedSpritePositions
      .concat(sheet.spritePositions)
      .sort((a, b) => a - b);
  };

  private _getSheet = (sheetId: string): TSheetData => {
    const sheetData = this._sheetData.sheets.find((s) => s.id === sheetId);

    if (!sheetData) throw new Error(`Could not find sheet for ${sheetId}`);

    return sheetData;
  };

  private _getFrameFromPos = (spritePos: number): TFrame => {
    const frameData = this._sheetData.spritePosToFrameMap[spritePos];
    const sheet = this._getSheet(frameData.sheetId);

    return {
      spritePos,
      sheetId: sheet.id,
      image: this._images[sheet.id],
      frame: frameData.frame.frame,
      sourceSize: frameData.frame.sourceSize,
      spriteSourceSize: frameData.frame.spriteSourceSize,
      trimmedSourceSize: frameData.trimmedSourceSize,
      trimmedSpriteSourceSize: frameData.trimmedSpriteSourceSize,
    };
  };

  private _updateLoadedFrames = (sheetId: string) => {
    this._addLoadedSpritePositions(sheetId);

    let prevSpritePos: number | null = null;
    let prevFrame: TFrame | null = null;
    this._loadedSpritePositions.forEach((currentSpritePos, index) => {
      const currentFrame = this._getFrameFromPos(currentSpritePos);
      const isLastLoadedSpritePos =
        index === this._loadedSpritePositions.length;

      if (prevSpritePos === null || prevFrame === null) {
        iterateBetween(0, currentSpritePos, (spritePos) => {
          this._availableFrames[spritePos] = currentFrame;
        });
      } else {
        const posDifference = currentSpritePos - prevSpritePos;

        if (posDifference <= 1)
          this._availableFrames[currentSpritePos] = currentFrame;
        else {
          const middleSpritePos = prevSpritePos + Math.floor(posDifference / 2);

          iterateBetween(prevSpritePos, middleSpritePos, (spritePos) => {
            this._availableFrames[spritePos] = prevFrame!;
          });
          iterateBetween(middleSpritePos, currentSpritePos, (spritePos) => {
            this._availableFrames[spritePos] = currentFrame;
          });
        }
      }

      if (isLastLoadedSpritePos) {
        const lastSpritePos = this._sheetData.count - 1;

        iterateBetween(
          currentSpritePos,
          lastSpritePos,
          (spritePos) => {
            this._availableFrames[spritePos] = currentFrame;
          },
          { includeMax: true }
        );
      }

      prevSpritePos = currentSpritePos;
      prevFrame = currentFrame;
    });
  };

  private _afterLoaded = (sheetId: string) => () => {
    this._sheetStatus[sheetId] = 'loaded';

    this._updateLoadedFrames(sheetId);

    this._loadNextSheet();
  };

  private _loadNextSheet = () => {
    const nextSheetToLoad = this._sheetsToLoad.pop();
    if (nextSheetToLoad) {
      this._loadSheet(nextSheetToLoad);
      return true;
    }
    return false;
  };

  private _loadSheet = (nextSheetToLoad: string) => {
    const sheet = this._getSheet(nextSheetToLoad);

    const image = new Image();
    image.src = this._config.getImageSrc(sheet);
    image.onload = this._afterLoaded(nextSheetToLoad);

    this._images[nextSheetToLoad] = image;
    this._sheetStatus[nextSheetToLoad] = 'loading';
  };

  private _prioritiseSheetForSpritePos = (spritePos: number) => {
    const { sheetId } = this._sheetData.spritePosToFrameMap[spritePos];
    switch (this._sheetStatus[sheetId]) {
      case 'unloaded': {
        this._sheetsToLoad.push(sheetId);
        this._sheetStatus[sheetId] = 'loadRequested';
        break;
      }
      case 'loadRequested': {
        prioritiseToNextPop(this._sheetsToLoad, sheetId);
        break;
      }
      default:
        break;
    }
  };

  public start = () => {
    if (!this._hasStartedLoading) {
      this._hasStartedLoading = true;
      this._loadNextSheet();
    }
  };

  public requestSpriteAt = (spritePos: number): TFrame | null => {
    const frame = this._availableFrames[spritePos];
    if (!frame || frame.spritePos !== spritePos)
      this._prioritiseSheetForSpritePos(spritePos);
    return frame || null;
  };
}
