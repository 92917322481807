import { ComponentProps, FC } from 'react';

import omit from 'lodash/omit';

// import type { TTheme } from './index';

export const stripProps =
  (Component: FC<any>, propNames: Array<string>) =>
  (props: Record<string, any>) =>
    <Component {...omit(props, propNames)} />;

export function setProps<TProps, TComponent extends FC<TProps>>(
  Component: TComponent,
  defaultProps: ComponentProps<TComponent>
): TComponent {
  const TypedComponent = Component as FC<TProps>;
  return ((props: TProps) => (
    <TypedComponent {...defaultProps} {...props} />
  )) as any;
}
export function setPropsAndExtend<
  TPropsExtension,
  TProps,
  TComponent extends FC<TProps>,
  TFullProps = ComponentProps<TComponent> & Partial<TPropsExtension>
>(
  Component: TComponent,
  defaultOutputProps: (props: TFullProps) => ComponentProps<TComponent>,
  defaultInputProps: TPropsExtension = {} as any
): FC<TFullProps> {
  const TypedComponent = Component as FC<TProps>;
  return (props: TFullProps) =>
    (
      <TypedComponent
        {...omit(props as any, Object.keys(defaultInputProps))}
        {...defaultOutputProps({ ...defaultInputProps, ...props })}
      />
    ) as any;
}

export const themeColor =
  (name: string) =>
  ({ theme, ...rest }: any) =>
    theme.colors[rest[name] as any] || rest[name];
