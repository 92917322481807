import type { SbComponent, SbComponentContent, SbRichText } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import SectionTitle from '@/common/SectionTitle';
import {
  RichText,
  RichSentence,
  TRichSentence,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { OnVisible } from '@/lib/utils/animation';
import { x } from '@/style';

import Row from './Row';

const ColumnsSection: SbComponent<{
  title: TRichSentence;
  intro?: SbRichText;
  rows: Array<SbComponentContent<typeof Row>>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section maxWidth='section-width'>
      <e.Layout.VStack spacing='lg'>
        <SectionTitle>
          <OnVisible.FadeUp>
            <RichSentence variant='h2'>{content.title}</RichSentence>
          </OnVisible.FadeUp>
        </SectionTitle>
        {content.intro ? (
          <OnVisible.FadeUp>
            <RichText>{content.intro}</RichText>
          </OnVisible.FadeUp>
        ) : null}
        <x.div>
          {content.rows.map((item, index) => (
            <Row
              content={item}
              key={item.title}
              isLast={index === content.rows.length - 1}
            />
          ))}
        </x.div>
      </e.Layout.VStack>
    </Section>
  </SbEditable>
);

export default ColumnsSection;
