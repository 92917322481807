import { FC, useState } from 'react';

import { FlattenSimpleInterpolation } from 'styled-components';

import { useTheme, css } from '@/style';

import * as c from './components';

const HoverHeader: FC<{
  Left: FC<{ inExpandedContainer?: boolean; isExpanded: boolean }>;
  Center: FC<{ inExpandedContainer?: boolean; isExpanded: boolean }>;
  Right: FC<{ inExpandedContainer?: boolean; isExpanded: boolean }>;
  headerCss?: FlattenSimpleInterpolation;
  hiddenCss?: FlattenSimpleInterpolation;
  forceIsExpanded?: boolean;
  showName?: string;
  hideName?: string;
}> = ({
  Left,
  Center,
  Right,
  headerCss = css`
    background-color: transparent;
    border-bottom: 1px solid;
    border-bottom-color: transparent;
  `,
  hiddenCss = css`
    background-color: palette-bg;
    border-bottom-color: palette-border;
  `,
  forceIsExpanded,
  showName = 'header-show',
  hideName = 'header-hide',
}) => {
  const {
    sizes: { header: headerHeight },
  } = useTheme();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isExpanded = isMouseOver || !!forceIsExpanded;

  return (
    <c.Container
      animate={isExpanded ? showName : hideName}
      initial={forceIsExpanded ? showName : hideName}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <c.PermanentContainer css={headerCss as any}>
        <c.Content>
          <c.LeftContainer>
            <Left isExpanded={isExpanded} />
          </c.LeftContainer>
          <c.RightContainer>
            <Right isExpanded={isExpanded} />
          </c.RightContainer>
        </c.Content>
      </c.PermanentContainer>
      <c.HiddenContainer
        css={css`
          ${headerCss} ${hiddenCss}
        `}
        $isVisible={isExpanded}
        variants={{
          [showName]: {
            y: 0,
          },
          [hideName]: {
            y: -headerHeight,
          },
        }}
        transition={{ type: 'tween', duration: 0.15 }}
      >
        <c.Content
          variants={{
            [showName]: {
              y: 0,
            },
            [hideName]: {
              y: headerHeight,
            },
          }}
          transition={{ type: 'tween', duration: 0.15 }}
        >
          <c.LeftContainer>
            <Left inExpandedContainer isExpanded={isExpanded} />
          </c.LeftContainer>
          <c.CenterContainer>
            <Center isExpanded={isExpanded} />
          </c.CenterContainer>
          <c.RightContainer>
            <Right inExpandedContainer isExpanded={isExpanded} />
          </c.RightContainer>
        </c.Content>
      </c.HiddenContainer>
    </c.Container>
  );
};

export default HoverHeader;
