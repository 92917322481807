import { motion } from 'framer-motion';

import { cssOnVisible } from '@/lib/ScrollPagingView';
import { styled } from '@/style';

export const Page = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

export const ContentContainer = styled.divBox`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: palette-bg;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

export const ScrollSpyArea = styled.divBox`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  ${cssOnVisible}
`;

export const ContentArea = styled.divBox`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: lg;
  justify-content: center;
  position: relative;
  margin-right: md;
`;

export const HouseTextArea = styled.divBox`
  flex: 1;
  position: relative;
  width: 100%;
`;
export const HouseSpriteArea = styled.divBox`
  flex: 0;
  min-height: 300px;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
`;

export const Background = styled.div`
  background-color: palette-bg;
`;

export const FooterContainer = styled.divBox<{
  isVisible: boolean;
}>`
  ${({ isVisible }) => isVisible && cssOnVisible}
`;
