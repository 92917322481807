import { useEffect } from 'react';

import { TSpritePlayer } from '@/lib/Sprite';
import { useBreakpoint } from '@/style';

type SpriteQualityOptions = 'sd' | 'md' | 'hd';

const useGetSpriteQuality = (): SpriteQualityOptions => {
  const breakpoint = useBreakpoint();

  switch (breakpoint) {
    case 'phablet':
    case 'phone':
      return 'sd';
    default:
      return 'md';
  }
};

const useResponsiveSpriteQuality = (
  spritePlayer: TSpritePlayer<SpriteQualityOptions>
) => {
  const quality = useGetSpriteQuality();

  useEffect(() => {
    spritePlayer.setSpriteGroup(quality);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quality]);
};

export default useResponsiveSpriteQuality;
