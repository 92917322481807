import { FC } from 'react';

import { x } from '@/style';

const ResponsiveSwitch: FC<{
  sm: JSX.Element;
  bg: JSX.Element;
  breakpoint?: string;
}> = ({ sm, bg, breakpoint = 'tablet' }) => (
  <>
    <x.div display={{ _: 'block', [breakpoint]: 'none' }}>{sm}</x.div>
    <x.div display={{ _: 'none', [breakpoint]: 'block' }}>{bg}</x.div>
  </>
);

export default ResponsiveSwitch;
