import trimStart from 'lodash/trimStart';
import { GetStaticPaths, GetStaticPathsResult } from 'next';
import { StoryData } from 'storyblok-js-client';

import type { ManagementClient } from '@/lib/storyblok';
import { slugHelper } from '@/services/storyblok';

import { STORYBLOK_WEBSITE_ROOT } from '../constants';

interface Config {
  root: string;
  fallback: boolean | 'blocking';
}

class GetStaticPathsFromStoryblok {
  private _root: Config['root'];
  private _fallback: Config['fallback'];
  private _managementClient?: ManagementClient;

  constructor(
    {
      root = trimStart(STORYBLOK_WEBSITE_ROOT, '/'),
      fallback = 'blocking',
    }: Config = {} as Config
  ) {
    this._root = root;
    this._fallback = fallback;
  }

  private _getManagementClient = (): ManagementClient => {
    if (!this._managementClient)
      throw new Error('Management client has not been initialised');
    return this._managementClient;
  };

  private _isStoryHomePage = (story: StoryData) =>
    story.full_slug !== `${this._root}`;

  private _initManagementClient = async () => {
    this._managementClient = (
      await import('@/services/storyblokManagement')
    ).default;
  };

  private _init = async () => {
    await this._initManagementClient();
  };

  private _fetchStories = async () => {
    const storiesResponse = await this._getManagementClient().getAllStories(
      this._root,
      {
        with_tag: 'build',
      }
    );
    return storiesResponse.data.stories.filter(this._isStoryHomePage);
  };

  private _getUrlParamArraysFromStories = (stories: Array<StoryData>) =>
    stories.map((story) => slugHelper.toUrlArray(story.full_slug));

  private _createStaticPathsResponseFromUrls = (
    urlParamArrays: Array<Array<string>>
  ): GetStaticPathsResult => ({
    fallback: this._fallback,
    paths: urlParamArrays.map((paramArray) => ({
      params: { path: paramArray },
    })),
  });

  getStaticPaths: GetStaticPaths = async () => {
    await this._init();

    const stories = await this._fetchStories();
    const urlParamArrays = this._getUrlParamArraysFromStories(stories);

    return this._createStaticPathsResponseFromUrls(urlParamArrays);
  };
}

export const getStaticPathsFromStoryblok = new GetStaticPathsFromStoryblok()
  .getStaticPaths;
