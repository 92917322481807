import { motion } from 'framer-motion';

import { OmitMotionProps } from '@/lib/motion';
import { styled, StyledComponent } from '@/style';

const Container = styled(motion.div)``;

const Child: OmitMotionProps<StyledComponent> = (props) => (
  <Container
    variants={{
      show: {
        opacity: 1,
        y: 0,
      },
      hide: {
        opacity: 0,
        y: 20,
      },
    }}
    {...props}
  />
);

export default Child;
