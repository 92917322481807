import { FC } from 'react';

import loadable from '@loadable/component';
import { MotionValue } from 'framer-motion';

import { TPalette } from '@/common/Palette';

const Backgrounds: Partial<
  Record<
    TPalette | 'default',
    FC<{
      motionValue: MotionValue<number>;
    }>
  >
> = {
  lightOrange: loadable(() => import('./Orange')) as any,
  lightYellow: loadable(() => import('./Yellow')) as any,
  lightGreen: loadable(() => import('./Green')) as any,
  default: loadable(() => import('./Yellow')) as any,
};

export default Backgrounds;
