import { StoryData } from 'storyblok-js-client';
import { SbEditableContent } from 'storyblok-react';

import config from '@/config';

export const getTitle = (
  content: SbEditableContent,
  story?: StoryData<any>
) => {
  const { title } = content;
  const pageName = story?.name;

  if (title) return `Housecure | ${title}`;
  if (pageName) return `Housecure | ${pageName}`;
  return config.seo.title;
};
