import { x, StyledProps, StyledComponent } from '@/style';

const style: StyledProps = {
  border: '1px solid transparent',
  borderColor: 'palette-border',
  borderRadius: 'md',
  bg: 'palette-bg',
  transition: 'base',
  boxShadow: {
    _: 'sm',
    hover: 'md',
  },
  scale: {
    _: 1,
    hover: 1.3,
  },
  color: {
    _: 'currentColor',
    hover: 'palette-primary',
  } as any,
};
const sizeMap: Record<string, StyledProps> = {
  lg: {
    w: '52px',
    h: '52px',
  },
};

export const SquareIconButton: StyledComponent<{
  size?: keyof typeof sizeMap;
}> = ({ size = 'lg', ...rest }) => (
  <x.button {...(rest as any)} {...style} {...sizeMap[size]} />
);
