import { FC } from 'react';

import { Hr, Text } from '@/elements';
import { VStack } from '@/elements/layout';

import { MenuItem } from './menu-item';

export const DiamondMenu: FC<{
  title?: string;
  activeOptionId?: string;
  options: Array<{
    id: string;
    href: string;
    label: string;
  }>;
}> = ({ title, activeOptionId, options }) => (
  <VStack
    w='100%'
    p='md'
    borderRadius='md md 0 0'
    borderTop='1px solid transparent'
    borderTopColor='palette-border'
    bg='white'
  >
    {title && (
      <>
        <Text variant='bodyBig' as='h2' pb='0'>
          {title}
        </Text>
        <Hr variant='boldStart' />
      </>
    )}
    {options.map((option) => (
      <MenuItem
        key={option.id}
        href={option.href}
        isActive={option.id === activeOptionId}
      >
        {option.label}
      </MenuItem>
    ))}
  </VStack>
);
