import type { SbRichText } from 'storyblok';

import { FC } from 'react';

import { Text } from '@/elements';
import {
  RichText as LibRichText,
  TConfig,
  TBloks,
  TResolver,
} from '@/lib/storyblok';

// eslint-disable-next-line import/no-cycle
import defaultBloks from './bloks';
import defaultConfig from './config';
import defaultResolver from './resolver';

const RichText: FC<{
  children: SbRichText;
  config?: TConfig;
  bloks?: TBloks;
  resolver?: Partial<TResolver>;
}> = ({ children, config, bloks, resolver }) => (
  <LibRichText
    config={{
      ...defaultConfig,
      ...config,
    }}
    bloks={{
      ...defaultBloks,
      ...bloks,
    }}
    resolver={{
      ...defaultResolver,
      ...resolver,
    }}
    StringComponent={(props) => <Text variant='body1' {...props} />}
  >
    {children}
  </LibRichText>
);

export default RichText;
