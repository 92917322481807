import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';

import { DesktopPage } from './desktop-page';
import { MobilePage } from './mobile-page';

import { ArticleProps } from '../types';
import {
  PublishedArticleContent,
  useArticleStoryFormatter,
} from '../utils/use-article-story-formatter';

export const ArticlePage: SbComponent<ArticleProps> = ({ content }) => {
  const { formatArticleStory } = useArticleStoryFormatter();
  const article = formatArticleStory<PublishedArticleContent>(content);
  const isTabletSize = useIsUpAndMounted('tablet');

  return (
    <SbEditable content={content}>
      {isTabletSize ? (
        <DesktopPage key='desktop' article={article} />
      ) : (
        <MobilePage key='mobile' article={article} />
      )}
    </SbEditable>
  );
};
