import { useEffect } from 'react';

import { FlattenSimpleInterpolation } from 'styled-components';

import useAppState from '.';

export const useHeaderCss = (
  getHeaderCss: (isAtTop?: boolean) => FlattenSimpleInterpolation
) => {
  const { setGetHeaderCss } = useAppState();

  useEffect(() => {
    setGetHeaderCss(getHeaderCss);
    return () => {
      setGetHeaderCss(() => null);
    };
  }, []);
};
