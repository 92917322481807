import { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import ArrowDownSvg from '@/assets/iconly/Arrow - Down 2.svg';
import { Button } from '@/elements';
import { x } from '@/style';

import { useArticlesFeed } from './use-articles-feed';

export const ExpandButton: FC<{}> = ({ children }) => {
  const { isExpanded, toggleExpanded, hasMoreArticles } = useArticlesFeed();
  return (
    <AnimatePresence>
      {hasMoreArticles && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Button
            variant='hoverColor'
            onClick={toggleExpanded}
            display='flex'
            flexDirection='row'
            alignItems='center'
          >
            <x.span mr='xs' whiteSpace='nowrap'>
              {children}
            </x.span>
            <motion.span
              animate={isExpanded ? 'show' : 'hide'}
              variants={{
                show: { rotate: 180 },
                hide: { rotate: 0 },
              }}
            >
              <ArrowDownSvg width='24px' height='24px' color='currentColor' />
            </motion.span>
          </Button>
        </motion.span>
      )}
    </AnimatePresence>
  );
};
