import { FC } from 'react';

import { Clickable, ClickableProps } from '@/common/clickable';
import { StyledProps } from '@/style';

export const StickyFooterButton: FC<ClickableProps & StyledProps> = ({
  as = 'button',
  ...rest
}) => (
  <Clickable
    as={as}
    {...rest}
    w='100%'
    position='sticky'
    bottom='0'
    bg='palette-bg'
    borderTop='1px solid transparent'
    borderTopColor='palette-border'
    boxShadow='0px -2px 2px rgba(0, 0, 0, 0.04)'
    display='flex'
    alignItems='center'
    justifyContent='center'
    h='button'
    color={
      {
        _: 'palette-text',
        hover: 'palette-primary',
      } as any
    }
  />
);
