import type { SbComponent, SbRichText, TCtaContent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import { CtaRow, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

const CtaSection: SbComponent<{
  title: string;
  subtitle?: string;
  description: SbRichText;
  ctas?: Array<TCtaContent>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <AnimateOnVisible>
      <Section>
        <e.Layout.VStack textAlign='center' mx='auto' maxW='section-width-sm'>
          <Fade.Up>
            <e.Text variant='subtitle1' as={x.h3} color='palette-primary'>
              {content.subtitle}
            </e.Text>
          </Fade.Up>
          <Fade.Up>
            <e.Text variant='h3'>{content.title}</e.Text>
          </Fade.Up>
          <Fade.Up>
            <x.div my='md'>
              <RichText>{content.description}</RichText>
            </x.div>
          </Fade.Up>
          {content.ctas?.length ? (
            <Fade.Up>
              <CtaRow
                ctas={content.ctas}
                dir='column'
                alignItems='stretch'
                maxW={320}
                spacing='md'
                mx='auto'
              />
            </Fade.Up>
          ) : null}
        </e.Layout.VStack>
      </Section>
    </AnimateOnVisible>
  </SbEditable>
);

export default CtaSection;
