import { styled } from '@/style';

export const Ul = styled.ul``;

export const Li = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: sm;
  margin-left: sm;
`;

export const IconContainer = styled.divBox<{
  $size: number;
}>`
  width: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`;

export const LiText = styled.spanBox`
  margin-top: 4px;
  margin-left: sm;
  line-height: 1.6rem;
`;
