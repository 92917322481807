import { nanoid } from 'nanoid';

import { IItemStateManager, Visibility } from '../types';
import * as utils from '../utils';

interface ICalibrationData {
  sectionHeight: number | null;
  containerHeight: number;
}
interface ITriggers {
  forwards: Array<number>;
  backwards: Array<number>;
}

type TSectionStateManager<TElement extends string> = IItemStateManager<
  Visibility | null,
  ICalibrationData,
  TElement | 'section',
  ITriggers
>;

interface IConfig<TElement extends string> {
  id?: string;
  tolerance?: number;
  elements: TSectionStateManager<TElement>['elements'];
  handlers: TSectionStateManager<TElement>['handlers'];
}
export default class SectionStateManager<TElement extends string>
  implements TSectionStateManager<TElement>
{
  private _id: string;
  private _config: IConfig<TElement>;

  /**
   * Used to prevent skipping where two sections next to
   * each other have the same trigger at AboveInside and BelowInside
   */
  private _tolerance: number;

  constructor(config: IConfig<TElement>) {
    this._id = config.id || nanoid();
    this._config = config;
    this._tolerance = config.tolerance || 10;
  }

  public get id() {
    return this._id;
  }
  public get elements() {
    return this._config.elements;
  }
  public get handlers() {
    return this._config.handlers;
  }

  private _getNextDownTrigger = (
    currentPos: number,
    triggersList?: ITriggers
  ): number | null => {
    if (!triggersList) return null;
    const index = triggersList.forwards.findIndex((pos) => pos > currentPos);
    return index < 0 ? null : triggersList.forwards[index];
  };
  private _getNextUpTrigger = (
    currentPos: number,
    triggersList?: ITriggers
  ): number | null => {
    if (!triggersList) return null;
    const index = triggersList.backwards.findIndex((pos) => pos < currentPos);
    return index < 0 ? null : triggersList.backwards[index];
  };

  getStatus: TSectionStateManager<TElement>['getStatus'] = ({
    scrollStatus: { currentPos },
    triggersList,
  }) => {
    if (!triggersList) return null;
    const index = triggersList.forwards.findIndex((pos) => pos >= currentPos);
    const breachedIndex = index === -1 ? -1 : index - 1;
    return utils.getVisibility(breachedIndex);
  };
  getTriggersList: TSectionStateManager<TElement>['getTriggersList'] = ({
    scrollStatus: { container },
    elements: { section },
  }) => {
    if (section) {
      const containerRect = container.getBoundingClientRect();
      const sectionRect = section.getBoundingClientRect();
      const halfScreenOrItemWidth = Math.min(
        containerRect.height / 2,
        sectionRect.height / 2
      );
      const triggers = [
        // Below Invisible
        section.offsetTop - containerRect.height,
        // Below Visible
        section.offsetTop +
          halfScreenOrItemWidth -
          containerRect.height +
          this._tolerance,
        // Below Inside
        section.offsetTop + 1,
        // Inside
        section.offsetTop +
          sectionRect.height -
          containerRect.height -
          this._tolerance,
        // Above Inside
        section.offsetTop +
          sectionRect.height +
          halfScreenOrItemWidth -
          containerRect.height -
          1,
        // Above Visible
        section.offsetTop + sectionRect.height,
        // Above Invisible
      ];
      return {
        forwards: triggers,
        backwards: [...triggers].reverse(),
      };
    }

    // TODO: do we need to not return empty when section is null?
    return {
      forwards: [],
      backwards: [],
    };
  };
  getCalibrationData: TSectionStateManager<TElement>['getCalibrationData'] = ({
    scrollStatus: { container },
    elements: { section },
  }) => {
    const sectionHeight = section?.getBoundingClientRect().height;
    return {
      containerHeight: container.getBoundingClientRect().height,
      sectionHeight: sectionHeight === undefined ? null : sectionHeight,
    };
  };

  getNextTriggers: TSectionStateManager<TElement>['getNextTriggers'] = ({
    scrollStatus: { currentPos },
    triggersList,
  }) => ({
    up: this._getNextUpTrigger(currentPos, triggersList),
    down: this._getNextDownTrigger(currentPos, triggersList),
  });
}
