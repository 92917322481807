import { styled } from '@/style';

export const Container = styled.divBox`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const ContentContainer = styled.divBox`
  position: relative;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: base;
`;
export const FooterContainer = styled.divBox`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
