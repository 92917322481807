import type { MoreSectionProps } from '../../types.d';
import type { SbComponent } from 'storyblok';

import { Fragment } from 'react';

import { Icon, CtaRow, RichText } from '@/common/storyblok/components';
import { Text, Layout } from '@/elements';
import { Fade, time } from '@/lib/utils/animation';

import * as c from './components';

const More: SbComponent<MoreSectionProps> = ({
  content: { title, columns },
}) => (
  <Layout.VStack maxW={1200} px='lg'>
    <Fade.Up transition={{ delay: 0 }}>
      <Text variant='h1' mb='lg'>
        {title}
      </Text>
    </Fade.Up>
    <c.Grid>
      {columns.map((column, index) => (
        <Fragment key={column.id}>
          <c.Icon gridColumn={index + 1}>
            <Fade.Up transition={{ delay: (index + 1) * time.md }}>
              <Icon name={column.iconName} width='54px' height='54px' />
            </Fade.Up>
          </c.Icon>
          <c.Title gridColumn={index + 1}>
            <Fade.Up transition={{ delay: (index + 1) * time.md }}>
              <Text variant='h4'>{column.title}</Text>
            </Fade.Up>
          </c.Title>
          <c.Content gridColumn={index + 1}>
            <Fade.Up transition={{ delay: (index + 1) * time.md }}>
              <RichText>{column.content}</RichText>
            </Fade.Up>
          </c.Content>
          <c.Cta gridColumn={index + 1}>
            <Fade.Up transition={{ delay: (index + 1) * time.md }}>
              <CtaRow ctas={column.ctas} justifyContent='flex-start' />
            </Fade.Up>
          </c.Cta>
        </Fragment>
      ))}
    </c.Grid>
  </Layout.VStack>
);

export default More;
