import type { TTextColumnContent } from 'storyblok';

import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { Icon, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import * as Accordion from '@/lib/Accordion';
import { OnVisible } from '@/lib/utils/animation';

import * as c from './components';

const AccordionComponent: FC<{
  items: Array<TTextColumnContent>;
}> = ({ items }) => (
  <c.Accordion.Container>
    <Accordion.Container>
      {items.map((item) => (
        <SbEditable key={item.title} content={item}>
          <OnVisible.FadeUp>
            <Accordion.Item id={item.title}>
              <Accordion.Button w={1}>
                <c.Accordion.Row>
                  <c.Accordion.Icon>
                    <Icon name={item.iconName} />
                  </c.Accordion.Icon>
                  <c.Accordion.RowTextContainer spacing='xs'>
                    <e.Text variant='h4' pb={'0 !important' as any}>
                      {item.title}
                    </e.Text>
                    {item.subtitle ? (
                      <e.Text variant='body1' pb={'0 !important' as any}>
                        {item.subtitle}
                      </e.Text>
                    ) : null}
                  </c.Accordion.RowTextContainer>
                  <Accordion.Arrow />
                </c.Accordion.Row>
              </Accordion.Button>
              <Accordion.Content>
                <c.Accordion.ContentContainer>
                  <RichText>{item.content}</RichText>
                </c.Accordion.ContentContainer>
              </Accordion.Content>
            </Accordion.Item>
          </OnVisible.FadeUp>
        </SbEditable>
      ))}
    </Accordion.Container>
  </c.Accordion.Container>
);

export default AccordionComponent;
