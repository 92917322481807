import { FC } from 'react';

import { motion } from 'framer-motion';

import { profileSymbolMap } from '@/common/ProfileSymbols';
import { RichSentence, TRichSentence } from '@/common/storyblok/components';
import { BlankLink, Text } from '@/elements';
import { HStack, VStack } from '@/elements/layout';
import ResponsiveSwitch from '@/lib/ResponsiveSwitch';
import { useColor } from '@/style';

import * as c from './components';

const ProfileRow: FC<{
  symbolName: keyof typeof profileSymbolMap;
  href: string;
  title: string;
  text: TRichSentence;
  pos: 'top' | 'middle' | 'bottom';
}> = ({ symbolName, href, title, text, pos }) => {
  const Symbol = profileSymbolMap[symbolName];
  const palettePrimary = useColor('palette-primary') as string;
  return (
    <motion.div whileHover='hovering'>
      <BlankLink href={href}>
        <c.ProfileRowContainer $pos={pos}>
          <ResponsiveSwitch
            breakpoint='phablet'
            sm={
              <VStack spacing='md'>
                <HStack alignItems='center'>
                  <Symbol size={50} condensedAnimationName='hovering' />
                  <Text variant='h3' pb={0}>
                    <motion.span
                      variants={{ hovering: { color: palettePrimary } }}
                    >
                      {title}
                    </motion.span>
                  </Text>
                </HStack>
                <RichSentence>{text}</RichSentence>
              </VStack>
            }
            bg={
              <HStack spacing='lg' alignItems='center'>
                <c.ProfileSymbolContainer>
                  <Symbol size='100%' condensedAnimationName='hovering' />
                </c.ProfileSymbolContainer>
                <VStack spacing={0}>
                  <c.ProfileLgTitle as='h3'>
                    <motion.span
                      variants={{ hovering: { color: palettePrimary } }}
                    >
                      {title}
                    </motion.span>
                  </c.ProfileLgTitle>
                  <RichSentence>{text}</RichSentence>
                </VStack>
              </HStack>
            }
          />
        </c.ProfileRowContainer>
      </BlankLink>
    </motion.div>
  );
};

export default ProfileRow;
