import { FC } from 'react';

import { x, useSize } from '@/style';

const OffsetAnchor: FC<{
  id: string;
  /**
   * The distance to offset the anchor by. Set
   * to the header height by default.
   */
  offset?: string;
}> = ({ id, offset, children }) => {
  const headerHeight = useSize('header');
  return (
    <x.div id={id} position='relative' top={offset || `-${headerHeight}`}>
      {children}
    </x.div>
  );
};

export default OffsetAnchor;
