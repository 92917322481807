import type { SbEditableContent } from 'storyblok';

import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { x } from '@/style';

// eslint-disable-next-line import/no-cycle
import * as c from './components';

import Icon from '../../../Icon';
// eslint-disable-next-line import/no-cycle
import RichSentence, { TRichSentence } from '../../../RichSentence';

const RtSimpleTable: FC<
  SbEditableContent<{
    gazPledge: TRichSentence;
    patiencePledge: TRichSentence;
    urgencyPledge: TRichSentence;
    materialCostPledge: TRichSentence;
  }>
> = (props) => {
  const { gazPledge, patiencePledge, urgencyPledge, materialCostPledge } =
    props;
  return (
    <SbEditable content={props}>
      <x.div py='md' mx='auto'>
        <c.Table>
          <c.TableBody>
            <c.Row>
              <c.Cell>
                <c.PledgeNameCell>
                  <Icon name='star' color='palette-primary' size='1.5rem' />
                  <c.PledgeName>No Gaz Pledge</c.PledgeName>
                </c.PledgeNameCell>
              </c.Cell>
              <c.Cell>
                <RichSentence>{gazPledge}</RichSentence>
              </c.Cell>
            </c.Row>
            <c.Row>
              <c.Cell>
                <c.PledgeNameCell>
                  <Icon name='star' color='palette-primary' size='1.5rem' />{' '}
                  <c.PledgeName>Patience Pledge</c.PledgeName>
                </c.PledgeNameCell>
              </c.Cell>
              <c.Cell>
                <RichSentence>{patiencePledge}</RichSentence>
              </c.Cell>
            </c.Row>
            <c.Row>
              <c.Cell>
                <c.PledgeNameCell>
                  <Icon name='star' color='palette-primary' size='1.5rem' />{' '}
                  <c.PledgeName>Urgency Pledge</c.PledgeName>
                </c.PledgeNameCell>
              </c.Cell>
              <c.Cell>
                <RichSentence>{urgencyPledge}</RichSentence>
              </c.Cell>
            </c.Row>
            <c.Row>
              <c.Cell>
                <c.PledgeNameCell>
                  <Icon name='star' color='palette-primary' size='1.5rem' />{' '}
                  <c.PledgeName>Material Cost Pledge</c.PledgeName>
                </c.PledgeNameCell>
              </c.Cell>
              <c.Cell>
                <RichSentence>{materialCostPledge}</RichSentence>
              </c.Cell>
            </c.Row>
          </c.TableBody>
        </c.Table>
      </x.div>
    </SbEditable>
  );
};

export default RtSimpleTable;
