import times from 'lodash/times';

export const prioritiseToNextPop = <T>(array: Array<T>, item: T): Array<T> =>
  array.filter((i) => i !== item).concat([item]);

export const iterateBetween = (
  min: number,
  max: number,
  fn: (pos: number, index: number) => void,
  { includeMax = false } = {}
) => {
  const range = max - min;
  const additionalCount = includeMax ? 1 : 0;
  return times(range + additionalCount, (i) => fn(min + i, i));
};
