import { styled } from '@/style';

export const Grid = styled.divBox`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: lg;
  row-gap: md;
  margin-right: lg;
`;
export const Icon = styled.divBox`
  grid-row-start: 1;
  color: palette-primary;
`;
export const Title = styled.divBox`
  grid-row-start: 2;
`;
export const Content = styled.divBox`
  grid-row-start: 3;
`;
export const Cta = styled.divBox`
  grid-row-start: 4;
  padding-top: md;
`;
