import { FC } from 'react';

import { motion } from 'framer-motion';

import { ReadTime } from '@/common/blog/common/icon-summary';
import { PublishedArticleContent } from '@/common/blog/utils/use-article-story-formatter';
import { Text } from '@/elements';
import { HStack, VStack } from '@/elements/layout';
import { Fade, time } from '@/lib/utils/animation';

import { ArticleImage } from './image';

import { useArticleLayoutIdsContext } from '../../context';

export const Hero: FC<{
  article: PublishedArticleContent;
}> = ({ article }) => {
  const { layoutIds } = useArticleLayoutIdsContext();

  return (
    <VStack>
      <ArticleImage layoutId={layoutIds?.image} image={article.headlineImage} />
      <motion.div
        initial='hide'
        animate='show'
        variants={{
          show: {
            transition: {
              delayChildren: 0.25,
              staggerChildren: time.sm,
            },
          },
        }}
      >
        <HStack pt='sm' pb='md'>
          <HStack>
            <Fade.Down>
              <ReadTime readTime={article.readTime} />
            </Fade.Down>
          </HStack>
        </HStack>
      </motion.div>
      <Text variant='h1' as={motion.h1} layoutId={layoutIds?.title}>
        {article.title}
      </Text>
    </VStack>
  );
};
