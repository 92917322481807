import { useState } from 'react';

import { Diamond } from '@/common/diamond';
import { x, StyledComponent } from '@/style';

import * as c from './components';
import { getMenuItemState } from './get-menu-item-state';
import { Option } from './types/option';

export const MenuItem: StyledComponent<{
  activeId?: string;
  option: Option;
  onOptionSelected?: (id: string, option: Option) => void;
  isLastOption: boolean;
  showLabel?: boolean;
  isCondensed: boolean;
}> = ({
  activeId,
  option,
  onOptionSelected,
  isLastOption,
  showLabel,
  isCondensed,
}) => {
  const [mouseOverOptionId, setMouseOverOptionId] = useState<string | null>(
    null
  );
  const { labelPaddingBottom, showLine } = getMenuItemState({
    isCondensed,
    isLastOption,
  });

  return (
    <x.div key={option.id}>
      <c.LabelContainer
        pb={labelPaddingBottom}
        href={option.href}
        onClick={() => onOptionSelected?.(option.id, option)}
        onMouseOver={() => setMouseOverOptionId(option.id)}
        onMouseLeave={() =>
          mouseOverOptionId === option.id && setMouseOverOptionId(null)
        }
      >
        <Diamond isFilled={option.id === activeId} />
        <c.Label
          display={showLabel ? 'block' : 'none'}
          isActive={option.id === activeId}
          isMouseOver={mouseOverOptionId === option.id}
        >
          {option.label}
        </c.Label>
      </c.LabelContainer>
      {showLine ? <c.Line /> : null}
    </x.div>
  );
};
