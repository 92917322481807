import { FC } from 'react';

import { Hr, Text } from '@/elements';
import { HStack } from '@/elements/layout';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

import { ArticlesGrid } from './article-grid';
import { ExpandButton, ArticlesFeedContainer } from './articles-feed';

import { ArticlesCollectionProps } from '../../types';

export const BlogArticlesCollection: FC<{
  title: ArticlesCollectionProps['title'];
  articles: ArticlesCollectionProps['articles'];
  maxColumnCount?: number;
}> = ({ title, articles, maxColumnCount }) => (
  <ArticlesFeedContainer articles={articles} maxColumnCount={maxColumnCount}>
    <AnimateOnVisible>
      <Fade.Up>
        <HStack justifyContent='space-between' alignItems='flex-end'>
          <Text as={x.h3} variant='h4' pb='0'>
            {title}
          </Text>
          <ExpandButton>Show more</ExpandButton>
        </HStack>
      </Fade.Up>
      <Hr variant='boldStart' />
      <ArticlesGrid />
    </AnimateOnVisible>
  </ArticlesFeedContainer>
);
