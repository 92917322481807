import type { SbComponent, SbRichText } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import { RichText } from '@/common/storyblok/components';
import { Layout } from '@/elements';
import { OnVisible } from '@/lib/utils/animation';

const TextSection: SbComponent<{
  id?: string;
  description: SbRichText;
}> = ({ content }) => {
  return (
    <SbEditable content={content}>
      <Section id={content.id}>
        <Layout.HStack spacing='1rem' py='md'>
          <OnVisible.FadeUp>
            <RichText>{content.description}</RichText>
          </OnVisible.FadeUp>
        </Layout.HStack>
      </Section>
    </SbEditable>
  );
};
export default TextSection;
