import { TPalette } from '@/common/Palette';

import Buyer from './Buyer';
import EstateAgent from './EstateAgent';
import Seller from './Seller';
import { TProfileSymbolComponent } from './types.d';

const getProfileSymbolForPalette = (
  palette: TPalette
): TProfileSymbolComponent | null => {
  switch (palette) {
    case 'lightYellow':
      return Buyer;
    case 'lightGreen':
      return EstateAgent;
    case 'lightOrange':
      return Seller;
    default:
      return null;
  }
};

export default getProfileSymbolForPalette;
