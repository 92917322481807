import type { HouseSectionProps } from '../../types.d';
import type { SbComponent } from 'storyblok';

import { CtaRow, RichText } from '@/common/storyblok/components';
import { Text, Layout } from '@/elements';

import * as c from './components';

import { Child as PageAnimatorChild } from '../PageAnimator';

const HouseSection: SbComponent<HouseSectionProps> = ({ content }) => (
  <c.ContentContainer>
    <Layout.VStack>
      {content.subtitle ? (
        <PageAnimatorChild>
          <c.Subtitle>{content.subtitle}</c.Subtitle>
        </PageAnimatorChild>
      ) : null}
      <PageAnimatorChild>
        <Text variant='h1'>{content.title}</Text>
      </PageAnimatorChild>
      <PageAnimatorChild>
        <RichText>{content.body}</RichText>
      </PageAnimatorChild>
      <PageAnimatorChild>
        <CtaRow
          ctas={content.ctas}
          flexWrap='none'
          justifyContent='flex-start'
        />
      </PageAnimatorChild>
    </Layout.VStack>
  </c.ContentContainer>
);

export default HouseSection;
