import { useRef, useEffect, MutableRefObject } from 'react';

const useCanvas: (
  draw: (options: {
    context: CanvasRenderingContext2D;
    timestamp: number;
    frameCount: number;
    canvas: HTMLCanvasElement;
    devicePixelRatio: number;
  }) => void,
  options?: {
    preDraw?: (context: CanvasRenderingContext2D, canvas: any) => void;
    postDraw?: (context: CanvasRenderingContext2D, canvas: any) => void;
  }
) => MutableRefObject<HTMLCanvasElement | null> = (draw, options = {}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    let frameCount = 0;
    let animationFrameId: number;
    const render = (timestamp: number) => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');
      if (canvas && context) {
        // eslint-disable-next-line no-plusplus
        frameCount++;
        options.preDraw?.(context, canvas);
        draw({
          context,
          timestamp,
          frameCount,
          canvas,
          devicePixelRatio: window.devicePixelRatio,
        });
        options.postDraw?.(context, canvas);
      }
      animationFrameId = window.requestAnimationFrame(render);
    };
    animationFrameId = window.requestAnimationFrame(render);
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draw]);
  return canvasRef;
};
export default useCanvas;
