/* eslint-disable import/no-cycle */
import SbEditable from 'storyblok-react';

import { Page } from '@/common/Layout';
import HomeView, { HomeViewComponent } from '@/views/Home';

const HomePage: HomeViewComponent = ({ content }) => (
  <SbEditable content={content}>
    <Page.Main disableFooter>
      <HomeView content={content} />
    </Page.Main>
  </SbEditable>
);

export default HomePage;
