/* eslint-disable import/no-cycle */
import { createDynamicComponent } from '@/lib/storyblok';

import { CalloutSection } from './CalloutSection';
import CardsSection from './CardsSection';
import { CarouselSection } from './CarouselSection';
import ColumnsSection from './ColumnsSection';
import ComparisonSection from './ComparisonSection';
import ContactDetailsSection from './ContactDetailsSection';
import ContactFormSection from './ContactFormSection';
import ContactInformationSection from './ContactInformationSection';
import ContentSection from './ContentSection';
import CtaSection from './CtaSection';
import { EmbedSection } from './EmbedSection';
import FaqAccordionSection from './FaqAccordionSection';
import HrArrowSection from './HrArrowSection';
import ProfilesSection from './ProfilesSection';
import QuoteSection from './QuoteSection';
import RowsSection from './RowsSection';
import { ScreenshotSection } from './ScreenshotSection';
import { SmallCardsSection } from './SmallCardsSection';
import SteppedJourneySection from './SteppedJourneySection';
import StorySection from './StorySection';
import TextSection from './TextSection';
import TitleSection from './TitleSection';

export const components = {
  CalloutSection,
  CardsSection,
  CarouselSection,
  ColumnsSection,
  'Comparison Section': ComparisonSection,
  ContactDetailsSection,
  ContactFormSection,
  ContactInformationSection,
  ContentSection,
  CtaSection,
  EmbedSection,
  FaqAccordionSection,
  HrArrowSection,
  QuoteSection,
  RowsSection,
  ProfilesSection,
  'Screenshot Section': ScreenshotSection,
  SmallCardsSection,
  SteppedJourneySection,
  StorySection,
  TitleSection,
  TextSection,
};

type TComponent = typeof components;
type TKeys = keyof TComponent;
type TValues = TComponent[TKeys];

export default createDynamicComponent<TValues>(components);
