import type { SbComponent, SbRichText, TCtaContent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import {
  CtaRow,
  RichText,
  Icon,
  TIconName,
  RichSentence,
  TRichSentence,
} from '@/common/storyblok/components';
import { Layout } from '@/elements';
import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';
import { OnVisible, AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

const ContentSection: SbComponent<{
  id?: string;
  iconName: TIconName;
  title: TRichSentence;
  rightTitle?: TRichSentence;
  description: SbRichText;
  ctas?: Array<TCtaContent>;
}> = ({ content }) => {
  const isBg = useIsUpAndMounted('tablet');
  return (
    <SbEditable content={content}>
      <Section id={content.id}>
        <Layout.HStack spacing='1rem' py='md'>
          {isBg ? (
            <OnVisible.FadeUp>
              <Icon size={80} color='palette-primary' name={content.iconName} />
            </OnVisible.FadeUp>
          ) : null}
          <x.div flex={1}>
            <Layout.VStack spacing='lg' maxW='section-width-sm' mx='auto'>
              <AnimateOnVisible>
                <Layout.HStack alignItems='center'>
                  {!isBg ? (
                    <Fade.Up>
                      <Icon
                        size={60}
                        name={content.iconName}
                        color='palette-primary'
                      />
                    </Fade.Up>
                  ) : null}
                  <x.div flex={1}>
                    <Layout.HStack>
                      <x.div flex={1}>
                        <Fade.Up>
                          <RichSentence
                            variant='h3'
                            pb={0}
                            mt={{ _: '0.5rem' }}
                          >
                            {content.title}
                          </RichSentence>
                        </Fade.Up>
                      </x.div>
                      {content.rightTitle ? (
                        <x.div alignSelf='flex-end'>
                          <Fade.Up>
                            <RichSentence
                              variant='h4'
                              color='palette-primary'
                              pb={0}
                              fontSize={{
                                _: 'lg',
                                tablet: 'xl',
                                laptop: 'xl',
                              }}
                            >
                              {content.rightTitle}
                            </RichSentence>
                          </Fade.Up>
                        </x.div>
                      ) : null}
                    </Layout.HStack>
                  </x.div>
                </Layout.HStack>
              </AnimateOnVisible>
              <OnVisible.FadeUp>
                <RichText>{content.description}</RichText>
              </OnVisible.FadeUp>
              {content.ctas ? (
                <OnVisible.FadeUp>
                  <CtaRow ctas={content.ctas} justifyContent='flex-start' />
                </OnVisible.FadeUp>
              ) : null}
            </Layout.VStack>
          </x.div>
        </Layout.HStack>
      </Section>
    </SbEditable>
  );
};
export default ContentSection;
