import * as e from '@/elements';
import { styled } from '@/style';

export const BackgroundContainer = styled.divBox`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TextContainer = styled(e.Layout.VStack)`
  position: relative;
  text-align: center;
  z-index: infront;
  margin: ${({ theme }) => theme.sizes.header}px auto;
  max-width: section-width-sm;
`;

export const ProfileSymbolContainer = styled.divBox`
  margin: 0 auto;
  display: inline-block;
`;
