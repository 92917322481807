export default class PositionService {
  private _msPerFrame: number;
  private _frameCount: number;
  private _timeRemainder: number = 0;
  private _previousRequestTime: number | null = null;
  private _lastOptimalPosition: number | null = null;

  constructor(frameCount: number, animationFramesPerSecond = 200) {
    this._frameCount = frameCount;
    this._msPerFrame = 1000 / animationFramesPerSecond;
  }

  private _calculatePosition = (
    targetSpritePos: number,
    elapsedTimeMs: number
  ) => {
    let newFramePos = targetSpritePos;
    if (this._lastOptimalPosition !== null) {
      const targetDelta = targetSpritePos - this._lastOptimalPosition;
      if (targetDelta === 0) {
        newFramePos = targetSpritePos;
        this._timeRemainder = 0;
      } else {
        const direction = targetDelta / Math.abs(targetDelta);

        // Make sure the animation doesn't run too quickly
        const frameDelta =
          this._timeRemainder + elapsedTimeMs / this._msPerFrame;
        const maxFrameChange = Math.floor(frameDelta);
        this._timeRemainder = frameDelta - maxFrameChange;

        if (maxFrameChange < Math.abs(targetDelta)) {
          newFramePos = this._lastOptimalPosition + maxFrameChange * direction;
        } else {
          newFramePos = targetSpritePos;
        }
      }
    }
    if (newFramePos < 0) return 0;
    if (newFramePos > this._frameCount) return this._frameCount;
    return Math.floor(newFramePos);
  };

  public requestPosition(targetPosition: number, currentTime: number): number {
    if (!this._previousRequestTime) this._previousRequestTime = currentTime;

    const elapsedTimeMs = currentTime - this._previousRequestTime;
    const positionToRender = this._calculatePosition(
      targetPosition,
      elapsedTimeMs
    );

    this._lastOptimalPosition = positionToRender;
    this._previousRequestTime = currentTime;

    return positionToRender;
  }
}
