import type { IJourneyStepContent } from './types.d';
import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import * as e from '@/elements';
import { OnVisible } from '@/lib/utils/animation';
import { x } from '@/style';

import JourneyStep from './Step';

const animationOffset = 200;

const SteppedJourneySection: SbComponent<{
  title: string;
  subtitle: string;
  steps: Array<IJourneyStepContent>;
  includeBottomPadding?: boolean;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section isFullPage maxWidth='section-width-lg'>
      <e.Layout.VStack textAlign='center'>
        {content.subtitle ? (
          <OnVisible.FadeUp>
            <e.Text variant='subtitle1' fontWeight={700}>
              {content.subtitle}
            </e.Text>
          </OnVisible.FadeUp>
        ) : null}
        <OnVisible.FadeUp>
          <e.Text variant='h2' mb='lg'>
            {content.title}
          </e.Text>
        </OnVisible.FadeUp>
      </e.Layout.VStack>
      <x.div
        pb={
          content.includeBottomPadding
            ? ((animationOffset + 1) as any)
            : undefined
        }
      >
        {content.steps.map((step, index) => {
          const nextStep =
            content.steps.length > index + 1 ? content.steps[index + 1] : null;
          return (
            <JourneyStep
              key={step.title}
              step={step}
              nextStep={nextStep}
              stepNo={index + 1}
              isLast={content.steps.length === index + 1}
              animationOffset={animationOffset}
            />
          );
        })}
      </x.div>
    </Section>
  </SbEditable>
);

export default SteppedJourneySection;
