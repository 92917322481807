import { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { movement } from '@/lib/utils/animation';
import { footerButtonCss, x } from '@/style';

import { useContext } from './use-context';

const OpenAnimator: FC = ({ children }) => (
  <motion.span
    animate='visible'
    exit='hidden'
    initial='hidden'
    variants={{
      hidden: {
        opacity: 0,
        y: -movement.sm,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    }}
  >
    {children}
  </motion.span>
);

const CloseAnimator: FC = ({ children }) => (
  <motion.span
    animate='visible'
    exit='hidden'
    initial='hidden'
    variants={{
      hidden: {
        opacity: 0,
        y: movement.sm,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    }}
  >
    {children}
  </motion.span>
);

export const ToggleButton: FC<{
  openText: JSX.Element | string;
  closeText: JSX.Element | string;
}> = ({ openText, closeText }) => {
  const { isOpen, toggleOpen } = useContext();
  return (
    <x.button
      {...footerButtonCss}
      onClick={toggleOpen}
      overflow='hidden'
      position='fixed'
      bottom='0'
      left='0'
      right='0'
    >
      <AnimatePresence initial={false} exitBeforeEnter>
        {isOpen ? (
          <CloseAnimator key='close'>{closeText}</CloseAnimator>
        ) : (
          <OpenAnimator key='open'>{openText}</OpenAnimator>
        )}
      </AnimatePresence>
    </x.button>
  );
};
