import type { SbComponent, SbEditableContent, SbRichText } from 'storyblok';

import { ComponentProps, FC } from 'react';

import { useMotionValue } from 'framer-motion';
import SbEditable from 'storyblok-react';

import AbstractShapeBackgroundMap from '@/common/AbstractShapeBackground';
import { Section } from '@/common/Layout';
import { TPalette } from '@/common/Palette';
import { getProfileSymbolForPalette } from '@/common/ProfileSymbols';
import ScrollHr from '@/common/ScrollHr';
import { CtaRow, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { useTimelineItem, Progress } from '@/lib/ScrollTimeline';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import usePageState from '@/state/PageState';
import { x } from '@/style';

import * as c from './components';

type TProps = {
  title: string;
  isScrollArrowDisabled: boolean;
  subtitle?: string;
  description: SbRichText;
  ctas?: ComponentProps<typeof CtaRow>['ctas'];
};
type TComponent = SbComponent<TProps>;

export const AbstractShapesComponent: FC<{
  content: SbEditableContent<TProps>;
  palette?: TPalette;
}> = ({ content, palette }) => {
  const motionValue = useMotionValue(0);

  const AbstractShapeBackground =
    AbstractShapeBackgroundMap[palette!] || AbstractShapeBackgroundMap.default!;
  let ProfileSymbol = null;
  if (!content.subtitle && palette) {
    ProfileSymbol = getProfileSymbolForPalette(palette);
  }

  useTimelineItem(
    () =>
      new Progress({
        progress: {
          from: 0,
          to: 1,
        },
        elements: {
          hero: 'hero',
        },
        getStartPos: ({ elements: { hero }, scrollStatus: { container } }) => {
          const rect = container.getBoundingClientRect();
          if (hero) {
            const top = hero.offsetTop - rect.height;
            return top;
          }
          return 0;
        },
        getEndPos: ({ elements: { hero }, scrollStatus: { container } }) => {
          const rect = container.getBoundingClientRect();
          if (hero) {
            const bottom = hero.offsetTop + hero.offsetHeight - rect.height;
            return bottom;
          }
          return rect.height;
        },
        handlers: {
          onStatusChanged: ({ status: progress }) => {
            if (progress !== null) {
              motionValue.set(progress);
            }
          },
        },
      })
  );

  return (
    <SbEditable content={content}>
      <AnimateOnVisible>
        <Section isFullPage>
          <c.BackgroundContainer>
            <AbstractShapeBackground motionValue={motionValue} />
          </c.BackgroundContainer>
          <c.TextContainer>
            {ProfileSymbol ? (
              <Fade.Up>
                <c.ProfileSymbolContainer>
                  <ProfileSymbol />
                </c.ProfileSymbolContainer>
              </Fade.Up>
            ) : null}
            {content.subtitle ? (
              <Fade.Up>
                <e.Text variant='subtitle1' as={x.h3} color='palette-primary'>
                  {content.subtitle}
                </e.Text>
              </Fade.Up>
            ) : null}
            <Fade.Up>
              <e.Text variant='h1'>{content.title}</e.Text>
            </Fade.Up>
            <Fade.Up>
              <e.DiamondHr style={{ marginTop: 0 }} />
            </Fade.Up>
            <Fade.Up>
              <RichText>{content.description}</RichText>
            </Fade.Up>
            {content.ctas?.length ? (
              <Fade.Up>
                <CtaRow ctas={content.ctas} mt='md' />
              </Fade.Up>
            ) : null}
          </c.TextContainer>
          {content.isScrollArrowDisabled ? null : <ScrollHr />}
        </Section>
      </AnimateOnVisible>
    </SbEditable>
  );
};

const AbstractShapes: TComponent = ({ content }) => {
  const { palette } = usePageState();
  return <AbstractShapesComponent palette={palette} content={content} />;
};

export default AbstractShapes;
