import { FC } from 'react';

import { ScrollSpy } from './scroll-spy';

import { ArticlesCollectionProps } from '../../../types';
import { useMenuOptions } from '../../use-menu-options';

export const JourneyNav: FC<{
  title: string;
  collections: Array<ArticlesCollectionProps>;
}> = ({ title, collections }) => {
  const { menuOptions } = useMenuOptions(collections);
  return <ScrollSpy title={title} menuOptions={menuOptions} />;
};
