import { FC } from 'react';

import SocialSection from '@/common/blog/article-page/social-section';
import { StickyColumns } from '@/common/sticky-columns';
import { useSize, x } from '@/style';

import { BackButton } from './back-button';

import { PublishedArticleContent } from '../../utils/use-article-story-formatter';
import { Content } from '../content';
import { CtaSection } from '../cta-section';
import { SubscribeSection } from '../subscribe-section';
import { Suggestions } from '../suggestions';
import { ToTopButton } from '../to-top-button';

export const DesktopPage: FC<{
  article: PublishedArticleContent;
}> = ({ article }) => {
  const headerHeight = useSize('header');
  const { ctaSection, suggestions, socials } = article;
  return (
    <StickyColumns
      contentWidth='section-width'
      left={{
        top: headerHeight,
        w: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        p: 'md',
        mt: 'lg',
        children: (
          <x.div w='100%' maxW='100px'>
            <BackButton article={article} />
          </x.div>
        ),
      }}
      right={{
        top: '0',
        p: 'md',
        h: '100vh',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        children: (
          <x.div w='100%' maxW='100px' display='flex' justifyContent='flex-end'>
            <ToTopButton />
          </x.div>
        ),
      }}
    >
      <x.div mt={headerHeight}>
        <Content article={article} />
        <CtaSection
          title={ctaSection.title}
          content={ctaSection.content}
          ctas={ctaSection.ctas}
        />
        <Suggestions
          title={suggestions.title}
          articles={suggestions.articles}
        />
        <SubscribeSection />
        {socials && <SocialSection title={socials.title} />}
      </x.div>
    </StickyColumns>
  );
};
