import type { SbComponent } from 'storyblok';

import { ComponentProps } from 'react';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import * as e from '@/elements';
import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';
import { OnVisible } from '@/lib/utils/animation';
import { x } from '@/style';

import ComparisonCard from '../../components/ComparisonCard';

const ComparisonSection: SbComponent<{
  title: string;
  cards: Array<ComponentProps<typeof ComparisonCard>>;
}> = ({ content }) => {
  const isUp = useIsUpAndMounted('phablet');
  const maxCardRows = Math.max(
    ...content.cards.map((card) => card.rows.length)
  );

  return (
    <SbEditable content={content}>
      <Section maxWidth='section-width-lg'>
        <e.Layout.VStack textAlign='center'>
          <OnVisible.FadeUp>
            <e.Text variant='h2' mb='lg' maxW='500px' mx='auto'>
              {content.title}
            </e.Text>
          </OnVisible.FadeUp>
        </e.Layout.VStack>
        <x.div
          display='grid'
          gridTemplateColumns={isUp ? content.cards.length : 1}
          columnGap='md'
        >
          {content.cards.map((card, i) => (
            <ComparisonCard
              key={card.title}
              {...card}
              columnNo={isUp ? i + 1 : 1}
              rowOffsetMultiple={isUp ? 0 : i}
              maxComparisonRows={maxCardRows}
            />
          ))}
        </x.div>
      </Section>
    </SbEditable>
  );
};
export default ComparisonSection;
