/* eslint-disable import/no-cycle */
import { createDynamicComponent } from '@/lib/storyblok';

import BasicPage from './Basic';
import { BlogArticle } from './BlogArticle';
import HomePage from './Home';
import { ResourceHub } from './ResourceHub';

export const components = {
  BlogArticle,
  BasicPage,
  ResourceHub,
  HomePage,
};

type TComponent = typeof components;
type TKeys = keyof TComponent;
type TValues = TComponent[TKeys];

const DynamicPage = createDynamicComponent<TValues>(components);

export default DynamicPage;
