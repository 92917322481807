import { FC } from 'react';

import { RadioButtonGroup } from '@/elements';

import { useResourceHubState } from '../../context';

export const Tabs: FC = () => {
  const { activeTab } = useResourceHubState();
  return (
    <RadioButtonGroup
      buttonWidth={{ _: 140, phablet: 180, tablet: 200 }}
      selectedId={activeTab}
      items={[
        {
          id: 'buyer',
          type: 'link',
          label: 'Buyer',
          href: '/learn/buyer',
        },
        {
          id: 'seller',
          type: 'link',
          label: 'Seller',
          href: '/learn/seller',
        },
        // {
        //   id: 'ea',
        //   type: 'link',
        //   label: 'Estate Agent',
        //   href: '/learn/ea',
        // },
      ]}
    />
  );
};
