import { FC } from 'react';

import { Text } from '@/elements';

import * as c from './components';

const SectionTitle: FC = ({ children }) => (
  <c.Container>
    <Text variant='h3' as='h3'>
      {children}
    </Text>
    <c.Underscore />
  </c.Container>
);
export default SectionTitle;
