import { styled } from '@/style';

export const Container = styled.divBox`
  border-bottom: 1px solid;
  border-bottom-color: palette-border;
  position: relative;
  padding: md 0;
`;

export const Underscore = styled.divBox`
  display: inline-block;
  position: absolute;
  border-radius: 1.5px;
  bottom: -2px;
  left: 0;
  max-width: 100%;
  width: 80px;
  height: 3px;
  background-color: palette-accent;
`;
