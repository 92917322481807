import config from '@/config';
import type { TConfig } from '@/lib/storyblok';
import Placeholder from '@/lib/storyblok/components/placeholder';
import { warn } from '@/services/log';

const rtConfig: TConfig = {
  Placeholder,
  log: {
    warn,
  },
  env: config.env === 'development' ? 'development' : 'production',
};

export default rtConfig;
