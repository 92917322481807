import { FC } from 'react';

import { Text } from '@/elements';
import { HStack } from '@/elements/layout';
import { x } from '@/style';

export const IconSummary: FC<{
  icon: JSX.Element;
  label: string;
}> = ({ icon, label }) => (
  <HStack alignItems='center' spacing='sm'>
    <x.span w='16px' h='16px' color='palette-primary'>
      {icon}
    </x.span>
    <Text variant='body2' pb='0'>
      {label}
    </Text>
  </HStack>
);
