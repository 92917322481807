import type { SbRichText, SbEditableContent } from 'storyblok';

import { FC } from 'react';

import SbEditable from 'storyblok-react';

import { x } from '@/style';

// eslint-disable-next-line import/no-cycle
import RichText from '../../Component';

const RtCard: FC<
  SbEditableContent<{
    content: SbRichText;
    background: string;
  }>
> = (props) => {
  const { content, background = 'transparent' } = props;
  return (
    <SbEditable content={props}>
      <x.div py='md' mx='auto'>
        <x.div
          border='1px solid'
          borderColor='palette-border'
          backgroundColor={background}
          borderRadius='lg'
          p='lg'
        >
          <RichText>{content}</RichText>
        </x.div>
      </x.div>
    </SbEditable>
  );
};

export default RtCard;
