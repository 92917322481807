import type { IItemStateManager } from './types.d';

import { useEffect } from 'react';

import TimelineItem from './TimelineItem';
import useTimeline from './useTimeline';

type TTimelineRegister = () => IItemStateManager<any, any, any, any>;

const useTimelineItem = (createItemStateManager: TTimelineRegister | null) => {
  const timeline = useTimeline();
  useEffect(() => {
    if (createItemStateManager === null) return () => undefined;
    const itemStateManager = createItemStateManager();
    const item = new TimelineItem(itemStateManager);
    timeline.register(item);
    return () => {
      timeline.deregister(item);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return timeline;
};

export default useTimelineItem;
