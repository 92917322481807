/* eslint-disable import/no-cycle */
import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import DynamicSection from '../index';

const StorySection: SbComponent<{
  story: {
    content: any;
  };
}> = ({ content }) => (
  <SbEditable content={content}>
    <DynamicSection content={content.story.content} />
  </SbEditable>
);

export default StorySection;
