import { FC } from 'react';

import { VStack } from '@/elements/layout';

import { JourneyNav } from './journey-nav';

import { UserResourcesProps } from '../../types';
import { Collections } from '../collections';

export const MobileUserResources: FC<UserResourcesProps> = ({
  name,
  navTitle,
  collections,
}) => (
  <VStack spacing='lg'>
    <Collections id={name} collections={collections} />
    <JourneyNav title={navTitle} collections={collections} />
  </VStack>
);
