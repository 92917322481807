import type { SbRichText, SbEditableContent } from 'storyblok';

import { FC } from 'react';

import loadable from '@loadable/component';
import SbEditable from 'storyblok-react';

import defaultResolver from '@/common/storyblok/components/RichSentence/resolver';
import { RichText } from '@/lib/storyblok';
import { x } from '@/style';

import * as c from './components';

import defaultConfig from '../../config';
// eslint-disable-next-line import/no-cycle
import defaultBloks from '../index';

const iconMap = {
  tick: loadable(() => import('@/assets/icons/Tick.svg')),
};
const empty = () => null;

const RtIconList: FC<
  SbEditableContent<{
    content: SbRichText;
    icon: keyof typeof iconMap;
  }>
> = (props) => {
  const { content, icon } = props;
  const Icon = iconMap[icon] || empty;
  return (
    <SbEditable content={props}>
      <x.div py='md'>
        <RichText
          config={defaultConfig}
          bloks={defaultBloks}
          resolver={{
            ...defaultResolver,
            Ul: ({ children }) => <c.Ul>{children}</c.Ul>,
            Li: ({ children }) => (
              <c.Li>
                <c.IconContainer $size={24}>
                  <Icon width='100%' height='100%' />
                </c.IconContainer>
                <c.LiText>{children}</c.LiText>
              </c.Li>
            ),
          }}
        >
          {content}
        </RichText>
      </x.div>
    </SbEditable>
  );
};

export default RtIconList;
