import { ComponentProps, FC } from 'react';

import { motion } from 'framer-motion';

import ArrowDown from '@/assets/iconly/Arrow - Down 2.svg';
import { styled } from '@/style';

import useAccordion from './containerContext';

const StyledArrow = styled(ArrowDown)`
  width: 24px;
  height: 24px;
  color: palette-text;
`;

const Arrow: FC<ComponentProps<typeof StyledArrow>> = (props) => {
  const { animationPrefix } = useAccordion();
  return (
    <motion.div
      variants={{
        [`${animationPrefix}show`]: { rotate: 180 },
        [`${animationPrefix}hide`]: { rotate: 0 },
      }}
    >
      <StyledArrow {...props} />
    </motion.div>
  );
};

export default Arrow;
