import type { SbComponent, SbImage, SbRichText } from 'storyblok';

import { motion } from 'framer-motion';
import SbEditable from 'storyblok-react';

import Cross from '@/assets/icons/Cross.svg';
// import HousecureLogo from '@/assets/logos/Full Logo.png';
import { Section } from '@/common/Layout';
import { useScrollAdjustment } from '@/common/hooks/use-scroll-adjustment';
import {
  RichSentence,
  TRichSentence,
  RichText,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { Stack, VStack } from '@/elements/layout';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { useSize, useUp, x } from '@/style';

export const PartnerHero: SbComponent<{
  title: TRichSentence;
  partnerLogo: SbImage;
  titleImage?: SbImage;
  description: SbRichText;
  screenshot?: SbImage;
}> = ({ content }) => {
  const { title, partnerLogo, titleImage, description, screenshot } = content;
  const isUp = useUp('largePhone');
  const headerHeight = useSize('header') as string;

  const screenshotAdjustment = useScrollAdjustment({
    from: [0, 2500],
    to: [0, -4000],
  });
  return (
    <SbEditable content={content}>
      <AnimateOnVisible>
        <Section pb='0' mb='10vw' overflow='hidden'>
          <e.Layout.VStack
            textAlign='center'
            mt={headerHeight}
            mx='auto'
            maxW='section-width-sm'
            gap='xl'
          >
            <Fade.Up>
              <Stack
                alignItems='center'
                maxH={{
                  _: 'unset',
                  largePhone: '40px',
                }}
                flexDirection={{
                  _: 'column',
                  largePhone: 'row',
                }}
              >
                <x.div
                  flex='1'
                  h='100%'
                  display='flex'
                  justifyContent='flex-end'
                >
                  <x.img
                    maxH='40px'
                    h='100%'
                    maxW='100%'
                    objectFit='contain'
                    src={partnerLogo.filename}
                  />
                </x.div>
                <Cross width='16px' height='16px' />
                <x.div flex='1'>
                  <x.img
                    src='/logos/Full Logo.png'
                    maxH='24px'
                    h='100%'
                    maxW='100%'
                    objectFit='contain'
                  />
                </x.div>
              </Stack>
            </Fade.Up>
            <VStack>
              {titleImage ? (
                <Fade.Up>
                  <x.img
                    maxH='40px'
                    mx='auto'
                    h='100%'
                    maxW='100%'
                    src={titleImage.filename}
                  />
                </Fade.Up>
              ) : null}
              <Fade.Up>
                <RichSentence variant='h3'>{title}</RichSentence>
              </Fade.Up>
              <Fade.Up>
                <RichText>{description}</RichText>
              </Fade.Up>
            </VStack>
            {screenshot ? (
              <Fade.Up>
                <x.div
                  as={motion.div}
                  style={
                    isUp
                      ? {
                          y: screenshotAdjustment,
                          marginBottom: screenshotAdjustment,
                        }
                      : {}
                  }
                  display='flex'
                  justifyContent='center'
                >
                  <x.img w='120vw' src={screenshot.filename} />
                </x.div>
              </Fade.Up>
            ) : null}
          </e.Layout.VStack>
        </Section>
      </AnimateOnVisible>
    </SbEditable>
  );
};
