import type { SbComponent } from 'storyblok';

import { useFormspark } from '@formspark/use-formspark';
import SbEditable from 'storyblok-react';
import { z } from 'zod';

import * as Form from '@/common/Form';
import config from '@/config';
import { Button, Text, Link, Layout } from '@/elements';
import { useZodForm } from '@/lib/Form';
import { OnVisible } from '@/lib/utils/animation';
import { x } from '@/style';

const schema = z.object({
  name: z.string().min(3, 'Name is too short'),
  email: z.string().email('Email is in wrong format'),
  message: z.string().min(10, 'Message is too short'),
});

const ContactFormSection: SbComponent = ({ content }) => {
  const [submit, submitting] = useFormspark({
    formId: config.form.contact,
  });
  const formHandler = useZodForm(schema);

  const onSubmit = formHandler.handleSubmit(async (form, ev) => {
    ev?.preventDefault();
    if (!submitting) {
      await submit(form);
      formHandler.reset();
    }
  });

  return (
    <SbEditable content={content}>
      <x.div mx='auto' mb='lg' px='md' className='private'>
        <Form.Container
          form={formHandler}
          onSubmit={onSubmit}
          mx='auto'
          maxWidth='section-width-sm'
        >
          <OnVisible.FadeUp>
            <Form.Input name='name' label='Name' />
          </OnVisible.FadeUp>
          <OnVisible.FadeUp>
            <Form.Input name='email' label='Email' type='email' />
          </OnVisible.FadeUp>
          <OnVisible.FadeUp>
            <Form.TextArea
              name='message'
              label='Message'
              placeholder='What did you want to say?'
            />
          </OnVisible.FadeUp>
          <Layout.VStack spacing='md' pt='lg'>
            <OnVisible.FadeUp>
              <Button type='submit' w='100%'>
                Send message
              </Button>
            </OnVisible.FadeUp>

            <OnVisible.FadeUp>
              <Text variant='meta1' textAlign='center'>
                By clicking send you agree to our{' '}
                <Link
                  variant='simpleLink'
                  href='/legals/privacy-policy'
                  target='_blank'
                >
                  Privacy Policy
                </Link>
              </Text>
            </OnVisible.FadeUp>
          </Layout.VStack>
        </Form.Container>
      </x.div>
    </SbEditable>
  );
};

export default ContactFormSection;
