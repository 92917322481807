import type { SbComponent, SbRichText, TCtaContent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import {
  RichSentence,
  TRichSentence,
  CtaRow,
  RichText,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { useSize, x } from '@/style';

export const PlainHeroComponent: SbComponent<{
  title: TRichSentence;
  subtitle?: string;
  description: SbRichText;
  ctas?: Array<TCtaContent>;
}> = ({ content }) => {
  const headerHeight = useSize('header') as string;
  return (
    <SbEditable content={content}>
      <AnimateOnVisible>
        <Section>
          <e.Layout.VStack
            textAlign='center'
            mt={headerHeight}
            mx='auto'
            pt='xl'
            maxW='section-width-sm'
          >
            <Fade.Up>
              <e.Text variant='subtitle1' as={x.h3} color='palette-primary'>
                {content.subtitle}
              </e.Text>
            </Fade.Up>
            <Fade.Up>
              <RichSentence variant='h1'>{content.title}</RichSentence>
            </Fade.Up>
            <Fade.Up>
              <e.DiamondHr style={{ marginTop: 0 }} />
            </Fade.Up>
            <Fade.Up>
              <RichText>{content.description}</RichText>
            </Fade.Up>
            {content.ctas?.length ? (
              <Fade.Up>
                <CtaRow ctas={content.ctas} mt='md' />
              </Fade.Up>
            ) : null}
          </e.Layout.VStack>
        </Section>
      </AnimateOnVisible>
    </SbEditable>
  );
};

export default PlainHeroComponent;
