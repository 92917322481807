import * as c from './components';
import { TProfileSymbolComponent } from './types.d';

const BuyerProfileSymbol: TProfileSymbolComponent = ({
  condensedAnimationName = 'condensed',
  distance = 3,
  size = 128,
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 128 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <c.SymbolG
      variants={{
        [condensedAnimationName]: {
          x: distance,
          y: -distance,
        },
      }}
    >
      <path
        d='M60.2198 20.3265L108.812 105.041H11.6277L60.2198 20.3265Z'
        fill='#F5C963'
      />
    </c.SymbolG>
    <c.SymbolG
      variants={{
        [condensedAnimationName]: {
          x: -distance,
          y: distance,
        },
      }}
    >
      <path
        d='M90.6582 24.7273L120.512 75.8858H60.8042L90.6582 24.7273Z'
        fill='#F5C963'
      />
    </c.SymbolG>
  </svg>
);

export default BuyerProfileSymbol;
