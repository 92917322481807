import { createContext, useContext, useState } from 'react';

import useRaf from '@/lib/hooks/useRaf';

import Timeline from './Timeline';

const context = createContext<Timeline>(undefined as any);

export const Provider: React.FC<{
  containerId: string;
}> = ({ containerId, children }) => {
  const [timeline] = useState(() => new Timeline(containerId));

  useRaf(() => {
    timeline.onRaf();
  });

  return <context.Provider value={timeline}>{children}</context.Provider>;
};
export default function useTimeline() {
  const c = useContext(context);
  if (!c) throw new Error('Cannot retrieve timeline outside of provider');
  return c;
}
