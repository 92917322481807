import { useBreakpoint } from '@xstyled/styled-components';

import useHasMounted from '@/lib/hooks/useHasMounted';

const getRecommendedCount = (breakpoint: string | null) => {
  switch (breakpoint) {
    case 'largeDesktop':
    case 'desktop':
    case 'laptop':
      return 3;
    case 'largeTablet':
    case 'tablet':
    case 'phablet':
    case 'largePhone':
      return 2;
    default:
      return 1;
  }
};

export const useColumnCount = ({
  maxColumnCount,
}: {
  maxColumnCount?: number;
} = {}) => {
  const breakpoint = useBreakpoint();
  const isMounted = useHasMounted();
  if (!isMounted) return null;

  const recommendedCount = getRecommendedCount(breakpoint);
  return maxColumnCount
    ? Math.min(recommendedCount, maxColumnCount)
    : recommendedCount;
};
