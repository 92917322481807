import { Text, Strong, Em, U, Strike, Image, List, Hr } from '@/elements';
import type { TResolver } from '@/lib/storyblok';

import { RichTextLink } from '../rich-text-link';

const resolver: TResolver = {
  Link: ({ children, href, target }) => (
    <RichTextLink href={href} target={target}>
      {children}
    </RichTextLink>
  ),
  Bold: ({ children }) => <Strong $variant='weight'>{children}</Strong>,
  Italic: Em,
  Strike,
  Underline: U,
  H1: ({ children }) => <Text variant='h1'>{children}</Text>,
  H2: ({ children }) => <Text variant='h2'>{children}</Text>,
  H3: ({ children }) => <Text variant='h3'>{children}</Text>,
  H4: ({ children }) => <Text variant='h4'>{children}</Text>,
  H5: ({ children }) => <Text variant='h5'>{children}</Text>,
  Paragraph: ({ children }) => <Text variant='body1'>{children}</Text>,
  Image: ({ src, alt, title }) => <Image alt={alt} src={src} title={title} />,
  Ol: List.Ol,
  Ul: List.Ul,
  Li: List.Li,
  Hr: () => <Hr variant='simple' />,
};

export default resolver;
