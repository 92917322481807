import { FC } from 'react';

import { HStack } from '@/elements/layout';

import { ReadTime } from '../icon-summary';

export const SummaryBanner: FC<{ readTime: number }> = ({ readTime }) => (
  <HStack
    mx='md'
    py='md'
    borderTop='1px solid transparent'
    borderTopColor='palette-border'
  >
    <ReadTime readTime={readTime} />
  </HStack>
);
