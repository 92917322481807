import { FC, useState } from 'react';

import { context } from './context';

export const Provider: FC<{
  animationOpenName: string;
  animationClosedName: string;
}> = ({ animationOpenName, animationClosedName, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <context.Provider
      value={{
        isOpen,
        setIsOpen,
        toggleOpen: () => setIsOpen((is) => !is),
        animationOpenName,
        animationClosedName,
      }}
    >
      {children}
    </context.Provider>
  );
};
