import { createContext } from 'react';

import { ArticleContent } from '@/common/blog/utils/use-article-story-formatter';

export const context = createContext<{
  isExpanded: boolean;
  setExpanded: (is: boolean) => void;
  toggleExpanded: () => void;
  showAnimationVariant: string;
  hideAnimationVariant: string;
  columnCount: number | null;
  hasMoreArticles: boolean;
  visibleArticles: Array<{
    uuid: string;
    article: ArticleContent;
  }>;
}>(undefined as any);
