import type { SbEditableContent, SbRichText } from 'storyblok';

import { FC, ComponentProps } from 'react';

import SbEditable from 'storyblok-react';

import { RichText, Icon } from '@/common/storyblok/components';
import * as e from '@/elements';
import { OnVisible } from '@/lib/utils/animation';

import * as c from './components';

const Row: FC<{
  isLast: boolean;
  content: SbEditableContent<{
    iconName: ComponentProps<typeof Icon>['name'];
    title: string;
    content: SbRichText;
  }>;
}> = ({ isLast, content }) => (
  <SbEditable content={content}>
    <e.Layout.HStack spacing='lg'>
      <c.Icon>
        <OnVisible.FadeUp>
          <Icon name={content.iconName} />
        </OnVisible.FadeUp>
      </c.Icon>
      <c.RowTextContainer $isLast={isLast}>
        <OnVisible.FadeUp pos={1}>
          <e.Text variant='h4'>{content.title}</e.Text>
          <RichText>{content.content}</RichText>
        </OnVisible.FadeUp>
      </c.RowTextContainer>
    </e.Layout.HStack>
  </SbEditable>
);

export default Row;
