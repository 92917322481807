import { FC } from 'react';

import { ArticlesCollectionProps } from '@/common/blog/types';
import { DiamondMenu, FooterMenu } from '@/common/footer-menu';
import { useActiveOption } from '@/common/scroll-spy';

import { CloseIconAndText } from './close-icon-and-text';
import { OpenIconAndText } from './open-icon-and-text';

import { useMenuOptions } from '../../use-menu-options';

export const JourneyNav: FC<{
  title: string;
  collections: Array<ArticlesCollectionProps>;
}> = ({ title, collections }) => {
  const { activeOption } = useActiveOption();
  const { menuOptions } = useMenuOptions(collections);
  return (
    <FooterMenu closeText={<CloseIconAndText />} openText={<OpenIconAndText />}>
      <DiamondMenu
        title={title}
        activeOptionId={activeOption}
        options={menuOptions}
      />
    </FooterMenu>
  );
};
