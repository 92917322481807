import { FC } from 'react';

import { motion } from 'framer-motion';

import * as e from '@/elements';
import { styled } from '@/style';

import * as c from './components';

const StyledLink = styled(e.Link)`
  font-weight: 600;
  opacity: 0.7;
  transition: base;
  :hover {
    opacity: 1;
  }
`;

const Link: FC<{
  href: string;
  trackName?: string;
  target?: '_blank' | '__blank';
}> = ({ href, trackName, target, children }) => (
  <motion.div variants={c.variants.listItem}>
    <StyledLink href={href} target={target} trackName={trackName}>
      {children}
    </StyledLink>
  </motion.div>
);

export default Link;
