/* eslint-disable max-classes-per-file */
import { FC } from 'react';

import { Portal } from 'react-portal';

import Burger from '@/assets/icons/Burger.svg';
import Cross from '@/assets/icons/Cross.svg';
import Rocket from '@/assets/icons/Rocket.svg';
import FullLogo from '@/assets/logos/Full Logo.svg';
import * as e from '@/elements';
import * as Accordion from '@/lib/Accordion';
import useAppState, { INestedItem, ILinkItem } from '@/state/AppState';

import AccordionNavItem from './AccordionNavItem';
import * as c from './components';
import { useDrawerState } from './use-drawer-state';

const NavDrawer: FC<{
  isVisible: boolean;
}> = ({ isVisible }) => {
  const { headerItems, footerItems } = useAppState();
  const { isOpen, open, close, setOpen } = useDrawerState();
  return (
    <Portal>
      {isVisible ? (
        <>
          <c.Overlay onClick={close} $isOpen={isOpen} />
          <c.DrawerContainer
            initial='drawer-hide'
            animate={isOpen ? 'drawer-show' : 'drawer-hide'}
            variants={{
              'drawer-show': {
                transition: {
                  staggerChildren: 0.05,
                },
              },
            }}
            $isOpen={isOpen}
          >
            <c.DrawerContent aria-label='Nav drawer' $isOpen={isOpen}>
              <c.DrawerTitle>
                <e.Link variant='blank' href='/' trackName='home'>
                  <FullLogo width='150px' />
                </e.Link>
              </c.DrawerTitle>
              <c.DrawerList>
                <c.DrawerCta>
                  <c.DrawerChildAnimation style={{ flex: 1 }}>
                    <e.Link
                      w='100%'
                      variant='rounded'
                      href='https://app.housecure.co.uk'
                    >
                      <Rocket
                        width='20px'
                        height='20px'
                        style={{ display: 'inline-block', marginRight: '4px' }}
                      />
                      Start now
                    </e.Link>
                  </c.DrawerChildAnimation>
                </c.DrawerCta>
                <Accordion.Container>
                  {[...headerItems, ...footerItems].map((item) => {
                    switch (item.type) {
                      case 'nested': {
                        const nestedItem = item as INestedItem;
                        return (
                          <c.DrawerChildAnimation key={item.id}>
                            <AccordionNavItem
                              key={nestedItem.id}
                              onSelect={open}
                              content={nestedItem}
                              closeDrawer={close}
                            />
                          </c.DrawerChildAnimation>
                        );
                      }
                      case 'link': {
                        const linkItem = item as ILinkItem;
                        return (
                          <c.DrawerChildAnimation key={linkItem.id}>
                            <e.Link
                              variant='blank'
                              w='100%'
                              href={linkItem.href}
                              onClick={open}
                              trackName={linkItem.trackName}
                            >
                              <c.ListItemContent>
                                <c.ListItemText>
                                  {linkItem.label}
                                </c.ListItemText>
                                <c.ListItemArrowRight />
                              </c.ListItemContent>
                            </e.Link>
                          </c.DrawerChildAnimation>
                        );
                      }
                      default:
                        return null;
                    }
                  })}
                </Accordion.Container>
              </c.DrawerList>
            </c.DrawerContent>
          </c.DrawerContainer>

          <c.BurgerButton onClick={() => setOpen(!isOpen)}>
            <e.Button
              variant='blank'
              color={isOpen ? 'palette-primary' : 'palette-text'}
            >
              {isOpen ? (
                <Cross width='16px' height='16px' />
              ) : (
                <Burger width='16px' height='16px' />
              )}
            </e.Button>
          </c.BurgerButton>
        </>
      ) : null}
    </Portal>
  );
};

export default NavDrawer;
