import { FC } from 'react';

import Expandable from '@/lib/Expandable';

import useAccordion from './containerContext';

const Content: FC = ({ children, ...rest }) => {
  const { animationPrefix } = useAccordion();
  return (
    <Expandable
      {...rest}
      showName={`${animationPrefix}show`}
      hideName={`${animationPrefix}hide`}
    >
      {children}
    </Expandable>
  );
};

export default Content;
