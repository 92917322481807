import { Button } from '@/elements';
import { IButtonItem } from '@/state/AppState/types';

import * as c from './components';

export const ButtonNavItem = ({
  id,
  label,
  trackName,
  onClick,
  afterClick,
}: IButtonItem & {
  afterClick: () => any;
}) => (
  <c.AccordionChildAnimation>
    <Button
      variant='blank'
      w='100%'
      key={id}
      trackName={trackName}
      onClick={() => {
        onClick();
        afterClick();
      }}
    >
      <c.AccordionListItem>
        {label}
        <c.AccordionArrowRight />
      </c.AccordionListItem>
    </Button>
  </c.AccordionChildAnimation>
);
