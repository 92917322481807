import { nanoid } from 'nanoid';

import { IItemStateManager, IScrollStatus } from '../types';
import * as utils from '../utils';

interface ICalibrationData {
  start: number | null;
  end: number | null;
  containerHeight: number;
}
interface ITriggers {
  start: number | null;
  end: number | null;
}

type TProgressStateManager<TElement extends string> = IItemStateManager<
  number | null,
  ICalibrationData,
  TElement,
  ITriggers
>;

interface IConfig<TElement extends string> {
  id?: string;
  progress: {
    from: number;
    to: number;
  };
  getExtraCalibrationData?: (data: {
    scrollStatus: IScrollStatus;
    elements: Record<TElement, HTMLElement | null>;
  }) => any;
  getStartPos: (data: {
    scrollStatus: IScrollStatus;
    elements: Record<TElement, HTMLElement | null>;
  }) => number | null;
  getEndPos: (data: {
    scrollStatus: IScrollStatus;
    elements: Record<TElement, HTMLElement | null>;
  }) => number | null;
  elements: TProgressStateManager<TElement>['elements'];
  handlers: TProgressStateManager<TElement>['handlers'];
}
export default class ProgressStateManager<TElement extends string>
  implements TProgressStateManager<TElement>
{
  private _id: string;
  private _config: IConfig<TElement>;

  constructor(config: IConfig<TElement>) {
    this._id = config.id || nanoid();
    this._config = config;
  }
  public get id() {
    return this._id;
  }
  public get elements() {
    return this._config.elements;
  }
  public get handlers() {
    return this._config.handlers;
  }

  private _getNextDownTrigger = (
    currentPos: number,
    triggersList?: ITriggers
  ): number | null => {
    if (
      !triggersList ||
      triggersList.end === null ||
      triggersList.start === null
    )
      return null;
    if (currentPos > triggersList.end) return null;
    if (currentPos < triggersList.start) return triggersList.start;
    return currentPos + 1;
  };
  private _getNextUpTrigger = (
    currentPos: number,
    triggersList?: ITriggers
  ): number | null => {
    if (
      !triggersList ||
      triggersList.end === null ||
      triggersList.start === null
    )
      return null;
    if (currentPos < triggersList.start) return null;
    if (currentPos > triggersList.end) return triggersList.end;
    return currentPos - 1;
  };

  getStatus: TProgressStateManager<TElement>['getStatus'] = ({
    scrollStatus: { currentPos },
    triggersList,
  }) => {
    if (!triggersList) return 0;
    const { start, end } = triggersList;
    const { from, to } = this._config.progress;
    if (start === null || end === null) return null;
    const map = utils.linearMap(start, end, from, to);
    return map(currentPos);
  };
  getTriggersList: TProgressStateManager<TElement>['getTriggersList'] = ({
    scrollStatus,
    elements,
  }) => ({
    start: this._config.getStartPos({
      elements,
      scrollStatus,
    }),
    end: this._config.getEndPos({
      elements,
      scrollStatus,
    }),
  });

  getCalibrationData: TProgressStateManager<TElement>['getCalibrationData'] = ({
    scrollStatus,
    elements,
  }) => {
    const { container } = scrollStatus;
    return {
      start: this._config.getStartPos({
        elements,
        scrollStatus,
      }),
      end: this._config.getEndPos({
        elements,
        scrollStatus,
      }),
      extra: this._config.getExtraCalibrationData?.({
        elements,
        scrollStatus,
      }),
      containerHeight: container.getBoundingClientRect().height,
    };
  };

  getNextTriggers: TProgressStateManager<TElement>['getNextTriggers'] = ({
    scrollStatus: { currentPos },
    triggersList,
  }) => ({
    up: this._getNextUpTrigger(currentPos, triggersList),
    down: this._getNextDownTrigger(currentPos, triggersList),
  });
}
