import { styled } from '@/style';

const Main = styled.mainBox`
  position: relative;
  z-index: infront;
  min-height: 100%;
  background-color: palette-bg;
  flex: 1;
`;

export default Main;
