import type { SbComponent, TTextColumnContent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import * as e from '@/elements';
import ResponsiveSwitch from '@/lib/ResponsiveSwitch';
import { OnVisible } from '@/lib/utils/animation';

import Accordion from './Accordion';
import Grid from './Grid';

const ColumnsSection: SbComponent<{
  title: string;
  subtitle: string;
  columns: Array<TTextColumnContent>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Section isFullPage maxWidth='section-width-lg'>
      <e.Layout.VStack textAlign='center'>
        <OnVisible.FadeUp>
          <e.Text variant='subtitle1' fontWeight={700}>
            {content.subtitle}
          </e.Text>
        </OnVisible.FadeUp>
        <OnVisible.FadeUp>
          <e.Text variant='h2' mb='lg' maxW='500px' mx='auto'>
            {content.title}
          </e.Text>
        </OnVisible.FadeUp>
      </e.Layout.VStack>
      <ResponsiveSwitch
        sm={<Accordion items={content.columns} />}
        bg={<Grid items={content.columns} />}
      />
    </Section>
  </SbEditable>
);

export default ColumnsSection;
