import React, { CSSProperties, FC } from 'react';

import useCanvas from './useCanvas';
import * as utils from './utils';

const Canvas: FC<{
  draw: Parameters<typeof useCanvas>[0];
  options?: Parameters<typeof useCanvas>[1];
  style?: CSSProperties;
}> = ({
  draw,
  options = {
    preDraw: utils.predraw,
    postDraw: utils.postdraw,
  },
  ...rest
}) => {
  const canvasRef = useCanvas(draw, options);

  return <canvas ref={canvasRef} {...rest} />;
};

export default Canvas;
