import * as e from '@/elements';
import { styled } from '@/style';

export const Grid = {
  Icon: styled.divBox`
    width: 60px;
    height: 60px;
    color: palette-primary;
    grid-row-start: 1;
  `,
};

export const Accordion = {
  Container: styled.divBox`
    max-width: section-width;
    margin: 0 auto;
  `,
  Row: styled.divBox`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 74px;
    border-bottom: 1px solid;
    border-bottom-color: palette-border;
    position: relative;
  `,
  Icon: styled.divBox`
    width: 60px;
    height: 60px;
    color: palette-primary;
    margin-right: md;
    position: absolute;
    right: 100%;
  `,
  RowTextContainer: styled(e.Layout.VStack)`
    flex: 1;
    text-align: left;
    padding: md 0;
    /* padding: md 0; */
  `,
  ContentContainer: styled.divBox`
    text-align: left;
    padding: md 0;
    margin-left: 74px;
    border-bottom: 1px solid;
    border-bottom-color: palette-border;
  `,
};
