/* eslint-disable no-param-reassign */
export const resizeCanvas = (canvas: HTMLCanvasElement) => {
  const { width, height } = canvas.getBoundingClientRect();

  if (canvas.width !== width || canvas.height !== height) {
    const { devicePixelRatio: ratio = 1 } = window;
    canvas.width = width * ratio;
    canvas.height = height * ratio;

    const context = canvas.getContext('2d');
    if (context) {
      context.scale(ratio, ratio);

      context.imageSmoothingEnabled = true;
    }
    return true;
  }

  return false;
};

export const postdraw = (ctx: CanvasRenderingContext2D) => {
  ctx.restore();
};
export const predraw = (
  context: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement
) => {
  context.save();
  resizeCanvas(canvas);
  const { width, height } = context.canvas;
  context.clearRect(0, 0, width, height);
};

type TRect = {
  x: number;
  y: number;
  w: number;
  h: number;
};
type TSize = {
  w: number;
  h: number;
};

export const scaleImageToCover = (
  frameRect: TRect,
  fromSize: TSize,
  toSize: TSize,
  devicePixelRatio: number
) => {
  const wRatio = toSize.w / fromSize.w;
  const hRatio = toSize.h / fromSize.h;
  if (wRatio < hRatio) {
    const yOffset = (toSize.h - fromSize.h * wRatio) / 2;
    return {
      x: (frameRect.x * wRatio) / devicePixelRatio,
      y: (frameRect.y * wRatio + yOffset) / devicePixelRatio,
      w: (frameRect.w * wRatio) / devicePixelRatio,
      h: (frameRect.h * wRatio) / devicePixelRatio,
    };
  }
  const xOffset = (toSize.w - fromSize.w * hRatio) / 2;
  return {
    x: (frameRect.x * hRatio + xOffset) / devicePixelRatio,
    y: (frameRect.y * hRatio) / devicePixelRatio,
    w: (frameRect.w * hRatio) / devicePixelRatio,
    h: (frameRect.h * hRatio) / devicePixelRatio,
  };
};
