/* eslint-disable max-classes-per-file */
import { FC } from 'react';

import FullLogoWithTransparentText from '@/assets/logos/Full Logo - Transparent Text.svg';
import * as e from '@/elements';

import * as c from './components';

import NavDrawer from '../NavDrawer';

const MobileHeader: FC<{
  isVisible: boolean;
}> = ({ isVisible }) => (
  <>
    <c.HeaderContent>
      <e.Link variant='blank' href='/' trackName='home'>
        <FullLogoWithTransparentText width='150px' />
      </e.Link>
      <NavDrawer isVisible={isVisible} />
    </c.HeaderContent>
  </>
);

export default MobileHeader;
