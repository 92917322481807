import { FC } from 'react';

import OffsetAnchor from '@/common/OffsetAnchor';
import { x } from '@/style';

import { useItemVisibilityTracker } from './use-item-visibility-tracker';

export const ScrollSpyItemContainer: FC<{
  id: string;
}> = ({ id, children }) => {
  const { endId, offsetTop } = useItemVisibilityTracker(id);
  return (
    <x.div>
      <OffsetAnchor id={id} offset={offsetTop} />
      {children}
      <OffsetAnchor id={endId} offset={offsetTop} />
    </x.div>
  );
};
