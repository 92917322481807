import { GetStaticPropsResult } from 'next';

import { processingReducer } from '@/lib/utils/processing-reducer';

import { draftBlogArticleInterceptor } from '../interceptors/draft-blog-article-interceptor';
import { stripResourceHubInterceptor } from '../interceptors/strip-resource-hub-interceptor';

const interceptor = processingReducer([
  stripResourceHubInterceptor,
  draftBlogArticleInterceptor,
]);

export const interceptStaticPropsResult = (
  result: GetStaticPropsResult<any>
): GetStaticPropsResult<any> => interceptor(result);
