import { VFC } from 'react';

import { Section } from '@/common/Layout';
import Palette from '@/common/Palette';
import * as e from '@/elements';
import useModals from '@/lib/Modal/context';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';

export const SubscribeSection: VFC = () => {
  const modals = useModals();

  return (
    <Palette palette='lightRed'>
      <AnimateOnVisible>
        <Section maxWidth='section-width-sm'>
          <e.Layout.VStack alignItems='center' textAlign='center'>
            <Fade.Up>
              <e.Text variant='h3' as='h3'>
                Want to increase the chances a property sale is successful?
              </e.Text>
            </Fade.Up>
            <Fade.Up>
              <e.Text variant='body1'>
                Subscribe to our newsletter and we’ll send you helpful,
                actionable tips every month to give you the best chance for an
                offer to become a sale.
              </e.Text>
            </Fade.Up>
            <Fade.Up>
              <e.Button
                onClick={() => modals.openModal('subscribe')}
                trackName='modal.open.subscribe'
              >
                Subscribe
              </e.Button>
            </Fade.Up>
          </e.Layout.VStack>
        </Section>
      </AnimateOnVisible>
    </Palette>
  );
};
