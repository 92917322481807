import { GetStaticPropsResult } from 'next';

export const getPropsFromStaticPropsResult = (
  result: GetStaticPropsResult<any>
) => {
  const { props } = result as any;
  if (props) {
    return props;
  }
  return {};
};
