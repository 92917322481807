import { styled, css } from '@/style';

export const Container = styled.divBox``;

export const LineContainer = styled.divBox`
  height: 35px;
  width: 24px;
  position: relative;
`;
export const Line = styled.divBox`
  content: ' ';
  width: 1px;
  transform: translateX(-0.5);
  min-height: 35px;
  background-color: brand-grey;
  position: relative;
  left: 12px;
`;

export const LabelContainer = styled.aBox`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;
export const Label = styled.divBox<{
  isActive: boolean;
  isMouseOver: boolean;
}>`
  font-family: mont;
  font-size: sm;
  transition: default;
  transform: scale(0.9);
  transform-origin: left center;
  margin-top: 0.25rem;
  padding-left: md;
  color: brand-dark;
  opacity: 0.5;
  :hover {
    font-weight: bold;
    transform: scale(1);
    opacity: 1;
  }
  ${({ isActive, isMouseOver }) =>
    (isActive || isMouseOver) &&
    css`
      font-weight: bold;
      transform: scale(1);
      opacity: 1;
    `}
`;
