import type { SbComponent, SbRichText } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import {
  RichSentence,
  TRichSentence,
  RichText,
  Icon,
  TIconName,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { OnVisible } from '@/lib/utils/animation';
import { x } from '@/style';

const TitleSection: SbComponent<{
  iconName: TIconName;
  title: TRichSentence;
  subtitle?: string;
  description: SbRichText;
  includeHr?: boolean;
}> = ({ content }) => {
  let subtitle: JSX.Element | undefined;
  if (content.iconName)
    subtitle = (
      <OnVisible.FadeUp>
        <Icon
          name={content.iconName}
          color='palette-primary'
          size={100}
          mx='auto'
        />
      </OnVisible.FadeUp>
    );
  else if (content.subtitle)
    subtitle = (
      <OnVisible.FadeUp>
        <e.Text variant='subtitle1' as={x.h3} color='palette-primary'>
          {content.subtitle}
        </e.Text>
      </OnVisible.FadeUp>
    );

  return (
    <SbEditable content={content}>
      <Section>
        <e.Layout.VStack
          textAlign='center'
          mt='lg'
          mx='auto'
          maxW='section-width-sm'
        >
          {subtitle || null}
          <OnVisible.FadeUp>
            <RichSentence variant='h2' mt='md' mb='sm'>
              {content.title}
            </RichSentence>
          </OnVisible.FadeUp>
          {content.includeHr ? (
            <OnVisible.FadeUp>
              <e.DiamondHr style={{ marginTop: 0 }} />
            </OnVisible.FadeUp>
          ) : null}
          <OnVisible.FadeUp>
            <RichText>{content.description}</RichText>
          </OnVisible.FadeUp>
        </e.Layout.VStack>
      </Section>
    </SbEditable>
  );
};

export default TitleSection;
