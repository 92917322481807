import type { Link, SbComponent } from 'storyblok';

import { ComponentProps, PropsWithChildren, useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';
import SbEditable from 'storyblok-react';

import OffsetAnchor from '@/common/OffsetAnchor';
import Palette from '@/common/Palette';
import { VStack } from '@/elements/layout';
import { OnVisible } from '@/lib/utils/animation';
import { x } from '@/style';

import { RichSentence, TRichSentence } from '../../components';

const AutoResizeIframe = (
  props: PropsWithChildren<ComponentProps<typeof x.iframe>>
) => <x.iframe {...props} id='resizable-iframe' />;

export const EmbedSection: SbComponent<{
  id?: string;
  title: TRichSentence;
  url: Link;
}> = ({ content }) => {
  useEffect(() => {
    const eventer = window.addEventListener || (window as any).attachEvent;
    const messageEvent = (window.addEventListener as any)
      ? 'message'
      : 'onmessage';

    eventer(
      messageEvent,
      (e: any) => {
        if (Number.isNaN(e.data)) return;

        const iframe = document.getElementById(
          'resizable-iframe'
        ) as HTMLIFrameElement;
        if (iframe) {
          iframe.style.height = `${e.data}px`;
        }
      },
      false
    );
  }, []);
  const { query, isReady } = useRouter();
  const baseUrl = content.url.url;
  const url = useMemo(() => {
    if (!isReady) return baseUrl;
    const newUrl = new URL(baseUrl);
    Object.keys(query).forEach((key) => {
      if (key !== 'path') {
        newUrl.searchParams.set(key, (query as any)[key]);
      }
    });
    return newUrl.toString();
  }, [query, isReady, baseUrl]);

  return (
    <SbEditable content={content}>
      <Palette palette='lightRed'>
        <x.div
          display='flex'
          flexDirection='column'
          backgroundColor='palette-bg'
          py='xl'
          minH='100dvh'
        >
          {content.id ? <OffsetAnchor id={content.id} /> : null}
          <VStack maxW='content-width' textAlign='center' mx='auto' my='md'>
            <OnVisible.FadeUp>
              <RichSentence variant='h2'>{content.title}</RichSentence>
            </OnVisible.FadeUp>
          </VStack>
          <AutoResizeIframe
            border='0'
            mx='auto'
            w='100%'
            minH='100%'
            src={url}
          />
        </x.div>
      </Palette>
    </SbEditable>
  );
};
