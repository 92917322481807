import { usePreviousPathOrDefault } from '@/common/back-link/use-previous-path-or-default';
import { useResolveAttrsFromStoryblokLink } from '@/lib/storyblok';

import { PublishedArticleContent } from '../utils/use-article-story-formatter';

export const useGetBackPath = (article: PublishedArticleContent) => {
  const { href: defaultBackPath } = useResolveAttrsFromStoryblokLink(
    article.recommendedParentNav
  );

  const backPath = usePreviousPathOrDefault({
    shouldUsePreviousPath: (path) => path.startsWith('/learn'),
    defaultPath: defaultBackPath || '/learn/buyer',
  });
  return backPath;
};
