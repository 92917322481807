import { FC } from 'react';

import { DiamondMenu } from '@/common/diamond-menu';
import { useActiveOption } from '@/common/scroll-spy';
import { Text } from '@/elements';
import { VStack } from '@/elements/layout';

export const ScrollSpy: FC<{
  title: string;
  menuOptions: Array<{
    id: string;
    label: string;
    href: string;
  }>;
}> = ({ title, menuOptions }) => {
  const { activeOption } = useActiveOption();
  return (
    <VStack
      border='1px solid transparent'
      borderColor='palette-border'
      borderRadius='md'
      w='100%'
      maxW='320px'
      p='md'
    >
      <Text fontWeight='600'>{title}</Text>
      <DiamondMenu
        isCondensed
        activeId={activeOption}
        options={menuOptions}
        showLabel
      />
    </VStack>
  );
};
