import { Strong, U, Em, Strike } from '@/elements';
import { TResolver, fragmentResolver } from '@/lib/storyblok';

import { RichTextLink } from '../rich-text-link';

const resolver: TResolver = {
  ...fragmentResolver,
  Bold: Strong,
  Underline: U,
  Italic: Em,
  Strike,
  Link: (props) => <RichTextLink {...props} />,
};

export default resolver;
