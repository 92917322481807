import { FC, createContext, useContext, useState, useEffect } from 'react';

import { TPalette } from '@/common/Palette';

const context = createContext<{
  palette: TPalette | undefined;
  setPalette: (p: TPalette | undefined) => void;
  isFooterVisible: boolean;
  setIsFooterVisible: (is: boolean) => void;
}>(undefined as any);

export const Provider: FC<{
  defaultPalette?: TPalette;
}> = ({ defaultPalette = 'white', children }) => {
  const [palette, setPalette] = useState<TPalette | undefined>(defaultPalette);
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false);

  useEffect(() => {
    if (defaultPalette) setPalette(defaultPalette);
  }, [defaultPalette]);

  return (
    <context.Provider
      value={{
        palette,
        setPalette,
        isFooterVisible,
        setIsFooterVisible,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default function usePageState() {
  const c = useContext(context);

  if (!c) throw new Error('Cannot retrieve page state outside of provider');
  return c;
}
