import { FC } from 'react';

import { Section } from '@/common/Layout';
import Socials from '@/common/Layout/Footer/Socials';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';

const SocialSection: FC<{
  title?: string;
}> = ({ title }) => (
  <AnimateOnVisible>
    <Section>
      <e.Layout.VStack
        textAlign='center'
        mx='auto'
        maxW='section-width-sm'
        alignItems='center'
      >
        <Fade.Up>
          <e.Text variant='h3'>{title}</e.Text>
        </Fade.Up>
        <Socials color='brand-blue' />
      </e.Layout.VStack>
    </Section>
  </AnimateOnVisible>
);

export default SocialSection;
