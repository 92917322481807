/* eslint-disable import/no-cycle */

// import Indent from './Indent';
// import Table from './Table';
import Card from './Card';
import IconList from './IconList';
import PledgeTable from './PledgeTable';
import Table from './Table';

const bloks = {
  // RtIndent: Indent,
  RtCard: Card,
  RtIconList: IconList,
  RtPledgeTable: PledgeTable,
  RtTable: Table,
};

export default bloks;
