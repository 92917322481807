import { Visibility } from './types.d';

export const getVisibility = (index: number) => {
  switch (index) {
    case -1:
      return Visibility.BelowInvisible;
    case 0:
      return Visibility.BelowVisible;
    case 1:
      return Visibility.BelowInside;
    case 2:
      return Visibility.Inside;
    case 3:
      return Visibility.AboveInside;
    case 4:
      return Visibility.AboveVisible;
    case 5:
      return Visibility.AboveInvisible;
    default:
      throw new Error(
        `Unrecognised index number ${index} to convert to Visibility`
      );
  }
};

export const linearMap =
  (xMin: number, xMax: number, yMin: number, yMax: number) =>
  (value: number) => {
    if (value < xMin) return yMin;
    if (value > xMax) return yMax;
    const xProgress = (value - xMin) / (xMax - xMin);
    return xProgress * (yMax - yMin) + yMin;
  };
