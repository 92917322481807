import { FC } from 'react';

import { x, useSize, StyledProps } from '@/style';

export const StickyColumns: FC<{
  left?: StyledProps;
  right?: StyledProps;
  contentWidth: string;
}> = ({ contentWidth, children, left = {}, right = {} }) => {
  const widthValue = useSize(contentWidth);
  return (
    <>
      <x.div
        position='sticky'
        w='100%'
        maxW={`calc((100vw - ${widthValue}) / 2)`}
        float='left'
        zIndex='infront'
        {...left}
      />
      <x.div
        position='sticky'
        w='100%'
        maxW={`calc((100vw - ${widthValue}) / 2)`}
        float='right'
        zIndex='infront'
        {...right}
      />
      <x.div w='100%'>{children}</x.div>
    </>
  );
};
