import { FC } from 'react';

import { StickyColumns } from '@/common/sticky-columns';

import { JourneyNav } from './journey-nav';

import { useResourceHubLayout } from '../../context';
import { UserResourcesProps } from '../../types';
import { Collections } from '../collections';

export const DesktopUserResources: FC<UserResourcesProps> = ({
  name,
  navTitle,
  collections,
}) => {
  const { contentTop } = useResourceHubLayout();
  return (
    <StickyColumns
      contentWidth='section-width-lg'
      left={{
        top: `${contentTop}px`,
        maxH: `calc(100vh - ${contentTop + 8}px)`,
        overflow: 'auto',
        pr: 'md',
        pl: 'xl',
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: {
          _: '280px',
          laptop: '320px',
        },
        children: <JourneyNav title={navTitle} collections={collections} />,
      }}
    >
      <Collections id={name} collections={collections} />
    </StickyColumns>
  );
};
