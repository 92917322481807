import { FC } from 'react';

import { motion } from 'framer-motion';

import DownArrow from './DownArrow.svg';
import {
  Container,
  PositionVariants,
  Line,
  ArrowContainer,
  ScrollPosition,
} from './components';
import useScrollState from './useScrollState';

const ScrollHr: FC<{
  position?: PositionVariants;
  href?: string;
}> = ({ position = 'bottom', href }) => {
  const { scrollRef, ClickComponent } = useScrollState(href);
  return (
    <Container $position={position}>
      <motion.div whileHover='hovering'>
        <ClickComponent>
          <Line $position={position} />
          <ArrowContainer variants={{ hovering: { scale: 1.08 } }}>
            <DownArrow />
          </ArrowContainer>
        </ClickComponent>
      </motion.div>
      <ScrollPosition ref={scrollRef as any} />
    </Container>
  );
};
export default ScrollHr;
