import { FC } from 'react';

import { ScrollSpyItemContainer } from '@/common/scroll-spy';
import { VStack } from '@/elements/layout';
import { x } from '@/style';

import { BlogArticlesCollection } from '../../common/articles-collection';
import { UserResourcesProps } from '../../types';

export const Collections: FC<{
  id: string;
  collections: UserResourcesProps['collections'];
}> = ({ id, collections }) => (
  <x.div display='flex' flexDirection='row'>
    <x.div px='md' w='100%' flex='1' maxW='section-width-lg'>
      <VStack h='100%' spacing='xl'>
        {collections.map(({ hashLabel, title, articles }) => (
          <ScrollSpyItemContainer key={`${id}-${hashLabel}`} id={hashLabel}>
            <BlogArticlesCollection title={title} articles={articles} />
          </ScrollSpyItemContainer>
        ))}
      </VStack>
    </x.div>
    <x.div />
  </x.div>
);
