import type { LinkResult, StoryResult } from 'storyblok';

import {
  GetStaticProps,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';

import storyblokService, { fetchPage, slugHelper } from '@/services/storyblok';

interface PageData {
  content: StoryResult<any> | null;
  links: LinkResult;
}

interface Config {
  resultInterceptor?: (
    result: GetStaticPropsResult<any>
  ) => GetStaticPropsResult<any>;
}

class GetStaticPropsFromStoryblok {
  private _pageData?: PageData;

  constructor(
    private _config: Config,
    private _context: GetStaticPropsContext
  ) {}

  private get pageData(): PageData {
    if (!this._pageData) throw new Error('Page data has not been initialised');
    return this._pageData;
  }
  private get _story() {
    if (this.pageData.content === null)
      throw new Error('Cannot get story when content is null');
    return this.pageData.content.data.story;
  }
  private get _linksData() {
    return this.pageData.links.data.links;
  }

  private _isPreviewRequest = () => !!this._context.preview;
  private _isPageFound = () => !!this.pageData.content;

  private _setPreviewMode = () => {
    storyblokService.setQuery(this._context.previewData);
    storyblokService.devMode = this._isPreviewRequest();
  };

  private _createNotFoundReponse = (): GetStaticPropsResult<any> => ({
    notFound: true,
  });

  private _createPageContentReponse = (): GetStaticPropsResult<any> => ({
    props: {
      story: this._story,
      content: this._story.content,
      linkMap: this._linksData,
      preview: this._context.preview || null,
    },
  });

  private _handlePreviewRequest = () => {
    if (this._isPreviewRequest()) this._setPreviewMode();
  };

  private _initPageContent = async () => {
    const [content, links] = await fetchPage(
      slugHelper.toSlug(this._context.params?.path)
    );

    this._pageData = {
      content,
      links,
    };
  };

  private _getInterceptedResult = (result: GetStaticPropsResult<any>) => {
    if (this._config.resultInterceptor)
      return this._config.resultInterceptor(result);
    return result;
  };

  getStaticProps = async (): Promise<GetStaticPropsResult<any>> => {
    this._handlePreviewRequest();
    await this._initPageContent();

    if (!this._isPageFound()) return this._createNotFoundReponse();
    const response = this._createPageContentReponse();

    return this._getInterceptedResult(response);
  };
}

export const createGetStaticPropsFromStoryblok: (
  config: Config
) => GetStaticProps =
  (config = {}) =>
  (...args) =>
    new GetStaticPropsFromStoryblok(config, ...args).getStaticProps();
