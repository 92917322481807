import { FC } from 'react';

import { motion } from 'framer-motion';

import useAccordion from './containerContext';
import useAccordionItem, { Provider } from './itemContext';

const ItemContent: FC = ({ children }) => {
  const { animationPrefix } = useAccordion();
  const { isSelected } = useAccordionItem('ItemContent');
  return (
    <motion.div
      initial={`${animationPrefix}hide`}
      whileHover={`${animationPrefix}hover`}
      animate={isSelected ? `${animationPrefix}show` : `${animationPrefix}hide`}
    >
      {children}
    </motion.div>
  );
};
const Item: FC<{
  id: string;
}> = ({ id, children }) => (
  <Provider id={id}>
    <ItemContent>{children}</ItemContent>
  </Provider>
);

export default Item;
