import { FC, ComponentProps } from 'react';

import { motion } from 'framer-motion';

import { styled } from '@/style';

import useAccordionItem from './itemContext';

const StyledButton = styled(motion.button)`
  background: transparent;
  padding: 0;
  text-align: left;
  width: 100%;
`;

const Button: FC<ComponentProps<typeof StyledButton>> = ({
  children,
  ...rest
}) => {
  const { toggleSelected } = useAccordionItem('Button');
  return (
    <StyledButton {...rest} onClick={toggleSelected}>
      {children}
    </StyledButton>
  );
};

export default Button;
