/* eslint-disable import/no-cycle */
import type { SbComponent, SbComponentContent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Page } from '@/common/Layout';
import { TPalette } from '@/common/Palette';

import { PageTitle } from '../../components';
import Seo from '../../components/Seo';
import { DynamicHero, DynamicSection } from '../../index';

const BasicPage: SbComponent<{
  palette: TPalette;
  hero: Array<any>;
  sections: Array<any>;
  title?: string;
  seo: SbComponentContent<typeof Seo>;
}> = ({ content, story }) => (
  <SbEditable content={content}>
    <PageTitle content={content} story={story} />
    <Page.Main defaultPalette={content.palette} pb='xl'>
      {content.seo ? <Seo content={content.seo} /> : null}
      {content.hero[0] ? <DynamicHero content={content.hero[0]} /> : null}
      {content.sections.map((blok) => (
        <DynamicSection key={blok._uid} content={blok} />
      ))}
    </Page.Main>
  </SbEditable>
);

export default BasicPage;
