import { motion } from 'framer-motion';

import RightArrow from '@/assets/iconly/Arrow - Right.svg';
import * as AccordionComponent from '@/lib/Accordion';
import { styled, breakpoints, css } from '@/style';

export const Header = styled.divBox`
  display: flex;
  flex-direction: column;
  ${breakpoints({
    phablet: css`
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    `,
  })}
`;
export const Footer = styled.divBox`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: sm;
`;

export const FaqList = {
  Accordion: AccordionComponent.Container,
  Container: styled.divBox`
    border: 1px solid;
    border-color: palette-border;
    border-radius: md;
  `,
  Item: styled(AccordionComponent.Item)``,
  Button: styled(AccordionComponent.Button)<{
    $isFirst?: boolean;
  }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: md;
    border-top: 1px solid;
    border-color: palette-border;
    position: relative;
    color: palette-text;
    ${({ $isFirst }) => $isFirst && `border-top: 0;`})}
  `,
  QuestionContainer: styled.divBox`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  LeftSideArrowContainer: styled(motion.div)`
    color: palette-primary;
    width: 24px;
    height: 24px;
    position: absolute;
  `,
  LeftSideArrow: styled(RightArrow)``,
  Question: styled.divBox`
    font-weight: 600;
    margin-left: 40px;
    margin-right: md;
    ${breakpoints({
      phablet: css`
        font-size: lg;
        line-height: lg;
      `,
    })}
  `,
  Arrow: AccordionComponent.Arrow,
  ContentContainer: AccordionComponent.Content,
  Content: styled.divBox`
    padding: md;
    padding-bottom: lg;
    margin-left: 40px;
  `,
};
