import { FC } from 'react';

import { ArticleCard } from './card';
import { DraftBanner } from './draft-banner';
import { ArticleImage } from './image';
import { TextLayoutContainer } from './text-layout-container';
import { ArticleTitle } from './title';

import { DraftArticleContent } from '../../utils/use-article-story-formatter';

export const DraftArticleCard: FC<{
  article: DraftArticleContent;
}> = ({ article }) => (
  <ArticleCard disableHover>
    <ArticleImage image={article.headlineImage} />
    <TextLayoutContainer>
      <ArticleTitle>{article.title}</ArticleTitle>
      <DraftBanner id={article.id} />
    </TextLayoutContainer>
  </ArticleCard>
);
