import type { SbEditableContent } from 'storyblok';

import { FC } from 'react';

import { StoryData } from 'storyblok-js-client';

import { Title } from '@/lib/snippets/title';

import { getTitle } from './get-title';

export const PageTitle: FC<{
  content: SbEditableContent;
  story?: StoryData<any>;
}> = ({ content, story }) => <Title>{getTitle(content, story)}</Title>;
