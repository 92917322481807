import { ComponentProps, FC } from 'react';

import { StoryData } from 'storyblok-js-client';

import { StoryblokPage } from '@/lib/storyblok';
import storyblokService from '@/services/storyblok';

// eslint-disable-next-line import/no-cycle
import DynamicPage from '../pages';

const RootPage: FC<
  ComponentProps<typeof StoryblokPage> & { content: any; story: StoryData<any> }
> = ({
  // common,
  content,
  story,
  linkMap,
  preview,
}) => (
  <StoryblokPage preview={preview} linkMap={linkMap}>
    <DynamicPage content={content} story={story} />
    {storyblokService.bridge()}
  </StoryblokPage>
);

export default RootPage;
