import { useRouter } from 'next/router';

const useRouteHash = () => {
  const router = useRouter();
  const indexOfHash = router.asPath.indexOf('#');
  if (indexOfHash === -1) return null;
  return router.asPath.substring(indexOfHash + 1);
};

export default useRouteHash;
