import isEqual from 'lodash/isEqual';

import Queue from './Queue';
import DownQpm from './QueuePositionManagers/DownQpm';
import UpQpm from './QueuePositionManagers/UpQpm';
import TimelineItem from './TimelineItem';
import ElementPositionManager from './components/ElementPositionManager';
import List from './components/List';
import { IScrollStatus, ScrollDirection } from './types.d';

export default class Timeline {
  private _container: ElementPositionManager;
  private _downQueue: Queue = new Queue(new DownQpm());
  private _upQueue: Queue = new Queue(new UpQpm());
  private _allItems: List<TimelineItem> = new List();

  private _scrollStatus: IScrollStatus | null = null;
  private _calibrationData: ReturnType<typeof this._getCalibrationData> | null =
    null;
  private _currentPos: ReturnType<typeof this._getCurrentPos> | null = null;

  private _isInited = false;
  private _taskBacklog: Array<(scrollStatus: IScrollStatus) => void> = [];

  constructor(containerId: string) {
    this._container = new ElementPositionManager(containerId);
  }

  get scrollStatus() {
    return this._scrollStatus;
  }
  get calibrationData() {
    return this._calibrationData;
  }
  get currentPos() {
    return this._currentPos;
  }
  get allItems() {
    return this._allItems.toArray();
  }

  private _getDirection = (prevPos?: number | null, newPos?: number | null) => {
    if (
      prevPos === undefined ||
      prevPos === null ||
      newPos === null ||
      newPos === undefined
    )
      return ScrollDirection.Unknown;
    if (prevPos > newPos) return ScrollDirection.Up;
    return ScrollDirection.Down;
  };

  private _calculateScrollStatus = (): IScrollStatus | null => {
    const container = this._container.getElement();
    if (!container) return null;

    return {
      currentPos: container.scrollTop,
      direction: this._getDirection(
        this._scrollStatus?.currentPos,
        container.scrollTop
      ),
      container,
    };
  };

  private _getCalibrationData = (scrollStatus: IScrollStatus) => {
    const rect = scrollStatus.container.getBoundingClientRect();
    return {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      scrollHeight: scrollStatus.container.scrollHeight,
    };
  };

  private _getCurrentPos = (scrollStatus: IScrollStatus) =>
    scrollStatus.currentPos;

  private _checkAndUpdateCurrentPos = (scrollStatus: IScrollStatus) => {
    const newCurrentPos = this._getCurrentPos(scrollStatus);
    const didCurrentPosUpdated = !isEqual(newCurrentPos, this._currentPos);
    this._currentPos = newCurrentPos;
    return didCurrentPosUpdated;
  };

  private _checkAndUpdateScrollStatus = () => {
    this._scrollStatus = this._calculateScrollStatus();
    return this._scrollStatus;
  };

  private _processTaskBacklog = (scrollStatus: IScrollStatus) => {
    this._taskBacklog.forEach((fn) => fn(scrollStatus));
    this._taskBacklog = [];
    return true;
  };

  private _runOrAddToBacklog = (fn: (scrollStatus: IScrollStatus) => void) => {
    const scrollStatus = this._checkAndUpdateScrollStatus();
    if (scrollStatus) {
      this._init(scrollStatus);
      this._processTaskBacklog(scrollStatus);
      fn(scrollStatus);
      return true;
    }
    this._taskBacklog.push(fn);
    return false;
  };

  private _checkAndUpdateContainerCalibrationData = (
    scrollStatus: IScrollStatus
  ) => {
    const newCalibrationData = this._getCalibrationData(scrollStatus);
    const doesNeedCalibrating = !isEqual(
      newCalibrationData,
      this._calibrationData
    );
    this._calibrationData = newCalibrationData;
    return doesNeedCalibrating;
  };

  private _init = (scrollStatus: IScrollStatus) => {
    if (!this._isInited) {
      this._isInited = true;
      this._checkAndUpdateContainerCalibrationData(scrollStatus);
      this._checkAndUpdateCurrentPos(scrollStatus);

      this._processTaskBacklog(scrollStatus);

      return true;
    }
    return false;
  };
  private _runCalibrationCheck = (scrollStatus: IScrollStatus) => {
    if (this._checkAndUpdateContainerCalibrationData(scrollStatus)) {
      return this._allItems.map((i) => ({
        nextTriggers: i.calibrate(scrollStatus),
        item: i,
      }));
    }

    return this._allItems.map((i) => ({
      nextTriggers: i.tryCalibrate(scrollStatus),
      item: i,
    }));
  };
  private _tryCalibration = (scrollStatus: IScrollStatus) => {
    const results = this._runCalibrationCheck(scrollStatus).filter(
      (i) => i.nextTriggers !== null
    );
    if (results.length > 0) {
      this._downQueue.updateQueue(results as any);
      this._upQueue.updateQueue(results as any);
    }
  };
  private _trigger = (scrollStatus: IScrollStatus) => {
    switch (scrollStatus.direction) {
      case ScrollDirection.Up: {
        const updatedItems = this._upQueue.trigger(scrollStatus);
        if (updatedItems !== null) this._downQueue.updateQueue(updatedItems);
        return true;
      }
      case ScrollDirection.Down: {
        const updatedItems = this._downQueue.trigger(scrollStatus);
        if (updatedItems !== null) this._upQueue.updateQueue(updatedItems);
        return true;
      }
      case ScrollDirection.Unknown:
      default:
        return false;
    }
  };

  register = (item: TimelineItem) => {
    this._runOrAddToBacklog((scrollStatus) => {
      this._allItems.addIfNotExists(item);

      this._downQueue.register(item, scrollStatus);
      this._upQueue.register(item, scrollStatus);
    });
  };
  deregister = (item: TimelineItem) => {
    this._runOrAddToBacklog(() => {
      this._allItems.removeIfExists(item);

      this._downQueue.deregister(item);
      this._upQueue.deregister(item);
    });
  };

  onRaf = () => {
    const scrollStatus = this._checkAndUpdateScrollStatus();
    if (!scrollStatus) return false;

    this._processTaskBacklog(scrollStatus);

    this._allItems.forEach((i) => {
      i.raf(scrollStatus);
    });

    this._tryCalibration(scrollStatus);

    if (this._checkAndUpdateCurrentPos(scrollStatus)) {
      this._allItems.forEach((i) => {
        i.onScroll(scrollStatus);
      });

      this._trigger(scrollStatus);
    }

    return true;
  };
}
