import type { TCtaContent } from 'storyblok';

import { FC, ComponentProps } from 'react';

import { Link, Layout } from '@/elements';
import useIsUpAndMounted from '@/lib/hooks/useIsUpAndMounted';
import { useResolveAttrsFromStoryblokLink } from '@/lib/storyblok';
import { reportError } from '@/services/report';

const Cta: FC<{
  variant?: ComponentProps<typeof Link>['variant'];
  content: TCtaContent;
}> = ({ content, variant = 'rounded', ...rest }) => {
  const { href, ...linkAttrs } = useResolveAttrsFromStoryblokLink(content.link);
  if (!href) {
    reportError('No link provided', {
      content,
      linkAttrs,
    });
    return null;
  }
  return (
    <Link
      {...linkAttrs}
      href={href}
      target={content.newTab ? '__blank' : undefined}
      trackName={content.trackName}
      version={content.version}
      variant={variant}
      {...rest}
    >
      {content.label}
    </Link>
  );
};

export const CtaRow: FC<
  {
    ctas?: Array<TCtaContent>;
    dir?: any;
    breakpoint?: string;
    spacing?: string;
    flexWrap?: string;
    Component?: FC<{ href: string }>;
  } & ComponentProps<typeof Layout.Stack>
> = ({
  ctas,
  dir = 'row',
  alignItems = dir.includes('row') ? 'flex-start' : 'center',
  breakpoint = 'tablet',
  spacing = 'md',
  flexWrap = 'wrap',
  Component,
  ...rest
}) => {
  const isBig = useIsUpAndMounted(breakpoint);
  if (!ctas?.length) return null;
  return (
    <Layout.Stack
      alignItems={{ _: 'stretch', [breakpoint]: alignItems }}
      justifyContent='center'
      className='ctas'
      dir={isBig ? dir : 'column'}
      flexWrap={flexWrap}
      maxW='100%'
      spacing={spacing}
      {...rest}
    >
      {ctas.map((cta) => (
        <Cta key={cta._uid} content={cta} />
      ))}
    </Layout.Stack>
  );
};

export default Cta;
