import { FC } from 'react';

import { motion } from 'framer-motion';

import { time } from '@/lib/utils/animation';

import { useContext } from './use-context';

export const AnimationContainer: FC = ({ children }) => {
  const { isOpen, animationOpenName, animationClosedName } = useContext();
  return (
    <motion.div
      initial={animationClosedName}
      animate={isOpen ? animationOpenName : animationClosedName}
      transition={{
        staggerChildren: time.md,
      }}
    >
      {children}
    </motion.div>
  );
};
