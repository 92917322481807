import * as utils from '@/lib/utils/style';
import { styled } from '@/style';

type TArrowProps = {
  $border: string;
  $background: string;
  $size: number;
  $borderWidth: number;
  $startPos?: string;
};
const Arrow = styled.divBox<TArrowProps>`
  position: relative;
  background-color: ${utils.themeColor('$background')};
  border: ${({ $borderWidth }) => $borderWidth}px solid;
  border-color: ${utils.themeColor('$border')};
  :after,
  :before {
    bottom: 100%;
    left: ${({ $startPos }) => ($startPos === undefined ? '50%' : $startPos)};
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${utils.themeColor('$background')};
    border-width: ${({ $size }) => $size}px;
    margin-left: -${({ $size }) => $size}px;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: ${utils.themeColor('$border')};
    border-width: ${({ $size, $borderWidth }) =>
      $size + Math.round($borderWidth * Math.sqrt(2))}px;
    margin-left: -${({ $size, $borderWidth }) => $size + Math.round($borderWidth * Math.sqrt(2))}px;
  }
`;

export default Arrow;
