import { FC } from 'react';

import { BackTextAndIcon } from '@/common/back-link';
import { Link } from '@/elements';

import { PublishedArticleContent } from '../../utils/use-article-story-formatter';
import { useGetBackPath } from '../use-get-back-path';

export const BackButton: FC<{
  article: PublishedArticleContent;
}> = ({ article }) => {
  const backPath = useGetBackPath(article);
  return (
    <Link href={backPath} variant='hoverColor'>
      <BackTextAndIcon />
    </Link>
  );
};
