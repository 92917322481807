import type { SbImage } from 'storyblok';

import { FC } from 'react';

import Palette from '@/common/Palette';
import { styled, x } from '@/style';

const SlideGroup = styled.divBox<{
  isReversed: boolean;
}>`
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  height: 100%;
  display: inline-block;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: slide;
  animation-direction: ${({ isReversed }) =>
    isReversed ? 'normal' : 'reverse'};
`;

const Image = ({
  src,
  alt,
  gap = 'md',
}: {
  src: string;
  alt: string;
  gap: 'md' | 'lg' | 'xl';
}) => (
  <x.img
    src={src}
    alt={alt}
    display='inline'
    h='100%'
    mx={gap}
    boxShadow='base'
  />
);

export const Carousel: FC<{
  // eslint-disable-next-line react/no-unused-prop-types
  _uid: string;
  images: Array<SbImage>;
  isReversed?: boolean;
  speedMs?: number;
  gap?: 'md' | 'lg' | 'xl';
  height?: number;
}> = ({
  images,
  isReversed = false,
  gap = 'md',
  speedMs = 35000,
  height = 80,
}) => (
  <Palette palette='lightRed'>
    <x.div
      boxSizing='content-box'
      h={`${height}px`}
      w='100%'
      overflow='hidden'
      py='lg'
      whiteSpace='nowrap'
      position='relative'
      bg='palette-bg'
      my='lg'
      boxShadow='inner'
    >
      <SlideGroup animationDuration={speedMs} isReversed={isReversed}>
        {images.map((image) => (
          <Image
            key={image.id}
            gap={gap}
            src={image.filename}
            alt={image.alt}
          />
        ))}
      </SlideGroup>
      <SlideGroup animationDuration={35000} isReversed={isReversed}>
        {images.map((image) => (
          <Image
            key={image.id}
            gap={gap}
            src={image.filename}
            alt={image.alt}
          />
        ))}
      </SlideGroup>
    </x.div>
  </Palette>
);
