import { useUp } from '@/style';

import useHasMounted from './useHasMounted';

const useIsUpAndMounted: typeof useUp = (breakpoint) => {
  const hasMounted = useHasMounted();
  const isUp = useUp(breakpoint);

  return isUp && hasMounted;
};
export default useIsUpAndMounted;
