import { FC } from 'react';

import { useSpace, useZIndex } from '@xstyled/styled-components';

import { DiamondHr, Text } from '@/elements';
import { x } from '@/style';

import { RelativeContainer } from './relative-container';
import { StickyContainer } from './sticky-container';
import { Tabs } from './tabs';

export const ResourceHubHero: FC = () => {
  const header = useSpace('header');
  const infront = useZIndex('infront');
  const zIndex = `calc(${infront} + 1)`;

  return (
    <>
      <RelativeContainer mt='header' pt='2xl'>
        {/* <Text variant='subtitle1' as='h3' color='palette-primary' pb={0}>
          Info
        </Text> */}
      </RelativeContainer>
      <StickyContainer
        top={0}
        pb='md'
        bg='palette-bg'
        zIndex={zIndex}
        minH='header'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Text
          as={x.h1}
          fontSize={{
            phone: 'xl',
            largePhone: '2xl',
            largeTable: '3xl',
            desktop: '4xl',
          }}
          variant='h1'
          pb={0}
          pt={0}
        >
          Learning Centre
        </Text>
      </StickyContainer>
      <RelativeContainer>
        <DiamondHr />
      </RelativeContainer>
      <StickyContainer
        top={`calc(${header} - 2px)`}
        bg='palette-bg'
        pb='lg'
        w='100%'
        zIndex={zIndex}
      >
        <Tabs />
      </StickyContainer>
      <x.div pb='2xl' w='100%' />
    </>
  );
};
