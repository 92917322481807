import { FC } from 'react';

import { reportError } from '@/services/report';

import { DraftArticleCard } from './draft-article-card';
import { PublishedArticleCard } from './published-article-card';

import { ArticleContent } from '../../utils/use-article-story-formatter';
import { useGetArticlePath } from '../../utils/use-get-article-path';

export const ArticleCard: FC<{
  uuid: string;
  article: ArticleContent;
}> = ({ uuid, article }) => {
  const { getArticlePath } = useGetArticlePath();
  switch (article.status) {
    case 'published':
      return (
        <PublishedArticleCard
          key={article.id}
          article={article as any}
          path={getArticlePath(uuid)}
        />
      );
    case 'draft':
      return <DraftArticleCard key={article.id} article={article} />;
    default: {
      reportError(`Couldn't identify article type ${article.status}`, {
        uuid,
        title: article.title,
      });

      return null;
    }
  }
};
