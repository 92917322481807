import { useResolvePathFromSlug } from '@/lib/storyblok/path-from-slug-resolver';

export const useResolvePathForRichTextLink = (path: string) => {
  const { resolvePathFromSlug } = useResolvePathFromSlug();
  const isLocal = path.startsWith('/');

  if (isLocal) {
    const localPath = resolvePathFromSlug(path);
    if (localPath === null)
      throw new Error(`Could not resolve the local path ${path}`);
    return localPath;
  }
  return path;
};
