import type { SbImage } from 'storyblok';

import { ComponentProps } from 'react';

import { motion } from 'framer-motion';

import { x } from '@/style';

export const LayeredImageContainer = (
  props: ComponentProps<typeof motion.div>
) => <motion.div whileHover='containerHover' {...props} />;
export const LayeredImage = ({
  images,
  movementSize = 1,
  movementDelay = 0.03,
}: {
  images: Array<SbImage>;
  movementSize?: number;
  movementDelay?: number;
}) => {
  const [firstImage, ...restImages] = images;

  return (
    <x.div position='relative' style={{ perspective: '250px' }} maxW='100%'>
      <x.img
        maxW='100%'
        src={firstImage.filename}
        style={{ imageRendering: '-webkit-optimize-contrast' }}
        transform='translateZ(0)'
      />
      {restImages.map((image, index) => (
        <x.img
          as={motion.img}
          variants={{
            containerHover: {
              scale: 1 + (index * movementSize) / 40,
              transition: {
                delay: index * movementDelay,
              },
            },
          }}
          key={image.filename}
          className='overlay-image'
          src={image.filename}
          position='absolute'
          // top='50%'
          // translateY={'-50%'}
          top='0'
          left='0'
          width='100%'
          bottom='0'
          style={{ imageRendering: '-webkit-optimize-contrast' }}
          transform='translateZ(0)'
        />
      ))}
    </x.div>
  );
};
