import { BlankLink, Text } from '@/elements';
import { styled, css } from '@/style';

export const Link = styled(BlankLink)`
  border: 1px solid;
  border-color: palette-border;
  transition: base;
  :hover {
    border-color: palette-primary;
  }
`;

export const ProfileRowContainer = styled.divBox<{
  $pos: 'top' | 'middle' | 'bottom';
}>`
  border: 1px solid;
  border-color: palette-border;
  position: relative;
  text-align: left;
  display: flex;
  padding: lg;
  ::after {
    pointer-events: none;
    position: absolute;
    content: ' ';
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid;
    transition: slow;
    border-color: transparent;
    z-index: 1;
  }
  :hover::after {
    transition: base;
    border-color: palette-primary;
  }

  ${({ $pos }) => {
    switch ($pos) {
      case 'top':
        return css`
          border-radius: xl xl 0 0;
          ::after {
            border-radius: xl xl 0 0;
          }
        `;
      case 'middle':
        return css`
          margin-top: -1px;
        `;
      case 'bottom':
        return css`
          margin-top: -1px;
          border-radius: 0 0 xl xl;
          ::after {
            border-radius: 0 0 xl xl;
          }
        `;
      default:
        throw new Error(`Invalid position: ${$pos}`);
    }
  }}
`;

export const ProfileTextContainer = styled.divBox`
  /* margin-left: lg; */
  padding-left: lg;
`;

export const ProfileLgTitle = styled(Text)`
  font-size: xl;
  font-weight: 600;
`;

export const ProfileSymbolContainer = styled.divBox`
  min-width: 100px;
  width: 100px;
  height: 100px;
`;
