import { isStoryComponentType } from '@/common/blog/utils/is-story-component-type';
import { removeArticleContentFromCollections } from '@/common/blog/utils/remove-article-content-from-collections';

import { StaticPropsInterceptor } from '../types/static-props-interceptor';
import { getPropsFromStaticPropsResult } from '../utils/get-props-from-static-props-result';

export const stripResourceHubInterceptor: StaticPropsInterceptor = (result) => {
  const props = getPropsFromStaticPropsResult(result);
  const { story, content } = props;
  if (story) {
    if (isStoryComponentType(story, 'ResourceHub')) {
      return {
        ...result,
        props: {
          ...props,
          content: removeArticleContentFromCollections(content),
        },
      };
    }
  }
  return result;
};
