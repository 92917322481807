import { useCallback, useMemo, useRef, useState } from 'react';

import { reduce } from '@/lib/utils/reduce';

export const useActiveOptionManager = <TOption extends string>(
  options: Array<TOption>
) => {
  const defaultOptions = useMemo(
    () =>
      reduce<TOption, Record<TOption, boolean>>(
        options,
        (prev, curr) => ({
          ...curr,
          [prev]: false,
        }),
        {}
      ),
    [options]
  );

  const optionVisibility = useRef(defaultOptions);
  const [activeOption, setActiveOption] = useState<TOption | undefined>(
    undefined
  );

  const getEarliestActiveOption = useCallback(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const option of options) {
      const isOptionVisible = optionVisibility.current[option];
      if (isOptionVisible) {
        return option;
      }
    }
    return undefined;
  }, [options]);

  const refreshActiveOption = useCallback(() => {
    const newActiveOption = getEarliestActiveOption();
    setActiveOption(() => newActiveOption);
  }, [getEarliestActiveOption]);

  const setOptionVisibility = useCallback(
    (option: TOption, isVisible: boolean) => {
      optionVisibility.current[option] = isVisible;
      refreshActiveOption();
    },
    [refreshActiveOption]
  );

  return {
    activeOption,
    setOptionVisibility,
  };
};
