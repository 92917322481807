import { FC, ComponentProps } from 'react';

import { Hr } from '@/elements';
import { x } from '@/style';

import ComparisonRow from '../ComparisonRow';

const cardVariant = {
  good: {
    background:
      'linear-gradient(106.97deg, #EDBA37 3.35%, #FFE7AA 18.22%, #FFC838 26.4%, #FFEAB4 76.17%, #FDCC4E 88.22%, #EDBA37 103.93%)',
  },
  bad: {
    border: '1px solid',
    borderColor: 'brand-grey',
  },
};
const pillVariant = {
  good: {
    background:
      'linear-gradient(342deg, rgba(255,232,181,1) 0%, rgba(250,227,174,1) 35%, rgba(254,247,231,1) 100%)',
  },
  bad: {
    bg: '#F5BDBD',
  },
};

const ComparisonCard: FC<{
  pill?: string;
  title: string;
  type: keyof typeof cardVariant;
  columnNo: number;
  rows: Array<ComponentProps<typeof ComparisonRow>>;
  rowOffsetMultiple: number;
  maxComparisonRows: number;
}> = ({
  pill,
  title,
  type,
  columnNo,
  rowOffsetMultiple,
  maxComparisonRows,
  rows,
}) => {
  const fixedCardRows = 4;
  const postCardRows = 1;
  const cardRows = fixedCardRows + maxComparisonRows;
  const allRows = cardRows + postCardRows;
  const startRowPos = rowOffsetMultiple * allRows;
  const endCardRowPos = startRowPos + cardRows;
  return (
    <>
      <x.div
        gridColumn={columnNo}
        gridRow={`${startRowPos + 1} / ${endCardRowPos + 1}`} // + 1?
        textAlign='center'
        borderRadius='8px'
        position='relative'
        {...cardVariant[type]}
      >
        <x.div
          position='absolute'
          top='1px'
          left='1px'
          right='1px'
          bottom='1px'
          backgroundColor='palette-bg'
          borderRadius='6px'
        />
      </x.div>
      <x.div
        p='md'
        mt='sm'
        zIndex='1'
        gridColumn={columnNo}
        gridRow={startRowPos + 1}
        textAlign='center'
      >
        {pill ? (
          <x.span
            borderRadius='18px'
            fontSize='xs'
            textTransform='uppercase'
            fontWeight='700'
            letterSpacing={1}
            py='sm'
            px='md'
            whiteSpace='nowrap'
            {...pillVariant[type]}
          >
            {pill}
          </x.span>
        ) : null}
      </x.div>
      <x.div
        px='md'
        py='sm'
        fontSize='lg'
        fontWeight='600'
        zIndex='1'
        gridColumn={columnNo}
        gridRow={startRowPos + 2}
        textAlign='center'
      >
        {title}
      </x.div>

      <x.div
        zIndex='1'
        py='sm'
        gridColumn={columnNo}
        gridRow={startRowPos + 3}
        mx='auto'
        maxW='60px'
        w='100%'
      >
        <Hr />
      </x.div>
      {rows.map((row, i) => (
        <x.div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          px='md'
          py='xs'
          zIndex='1'
          gridColumn={columnNo}
          gridRow={`${startRowPos + i + 4}`}
        >
          <ComparisonRow {...row} />
        </x.div>
      ))}
      <x.div
        mb='md'
        zIndex='1'
        gridColumn={columnNo}
        gridRow={endCardRowPos}
        textAlign='center'
      />

      <x.div
        mb='md'
        zIndex='1'
        gridColumn={columnNo}
        gridRow={endCardRowPos + 1}
        textAlign='center'
      />
    </>
  );
};
export default ComparisonCard;
