import { useRef } from 'react';

import { useScroll, useTransform } from 'framer-motion';

export const useScrollAdjustment = <TOutput>({
  from,
  to,
}: {
  from: Array<number>;
  to: Array<TOutput>;
}) => {
  const scrollContainer = useRef(
    typeof document === 'undefined'
      ? null
      : document.getElementById('main-scroll-container')
  );
  const { scrollY } = useScroll({
    container: scrollContainer,
    smooth: 30,
  });
  return useTransform(scrollY, from, to);
};
