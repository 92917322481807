import { useCallback } from 'react';

import { useGetPathFromPageId } from '@/lib/storyblok/path-from-page-id';
import { reportError } from '@/services/report';

export const useGetArticlePath = () => {
  const { getPathFromPageId } = useGetPathFromPageId();

  const getArticlePath = useCallback((uuid: string, content?: any) => {
    const path = getPathFromPageId(uuid);
    if (!path) {
      reportError(`Could not find link to article`, {
        uuid,
        ...content,
      });
      return '#';
    }
    return path;
  }, []);

  return {
    getArticlePath,
  };
};
