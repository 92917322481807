export enum ScrollDirection {
  Up = 'Up',
  Down = 'Down',
  Unknown = 'Unknown',
}

export enum Visibility {
  BelowInvisible = 'BelowInvisible',
  BelowVisible = 'BelowVisible',
  BelowInside = 'BelowInside',
  Inside = 'Inside',
  AboveInside = 'AboveInside',
  AboveVisible = 'AboveVisible',
  AboveInvisible = 'AboveInvisible',
}

export interface IScrollStatus {
  currentPos: number;
  direction: ScrollDirection;
  container: HTMLElement;
}

export interface INextTriggers {
  up: number | null;
  down: number | null;
}

export interface IStateHandlers<IInput> {
  onRegister?: (input: IInput) => void;
  onCalibrate?: (input: IInput) => void;
  onRaf?: (input: IInput) => void;
  onScroll?: (input: IInput) => void;
  onStatusChanged?: (input: IInput) => void;
}

export interface IQueuePositionManager {
  direction: ScrollDirection;
  getTrigger: (nextTriggers: INextTriggers | null) => number | null;
  isBefore: (a: number, b: number) => boolean;
}

export interface IItemStateManager<
  TStatus,
  TCalibrationData,
  TElement extends string,
  TTriggersList = Array<number>
> {
  readonly id: string;
  readonly elements: Record<TElement, string>;
  readonly handlers: IStateHandlers<{
    scrollStatus: IScrollStatus;
    status: TStatus | null;
    triggersList: TTriggersList | null;
    calibrationData: TCalibrationData | null;
    elements: Record<TElement, HTMLElement | null>;
  }>;

  getTriggersList: (data: {
    scrollStatus: IScrollStatus;
    elements: Record<TElement, HTMLElement | null>;
  }) => TTriggersList;

  getStatus: (data: {
    scrollStatus: IScrollStatus;
    triggersList?: TTriggersList;
    elements: Record<TElement, HTMLElement | null>;
  }) => TStatus;

  getCalibrationData?: (data: {
    scrollStatus: IScrollStatus;
    elements: Record<TElement, HTMLElement | null>;
  }) => TCalibrationData;

  getNextTriggers: (data: {
    scrollStatus: IScrollStatus;
    triggersList?: TTriggersList;
    elements: Record<TElement, HTMLElement | null>;
  }) => INextTriggers;
}

export interface ITimelineItem {
  readonly id: string;
}
