/**
 * A reduce function that allows typing
 */
export function reduce<TOption, TResult>(
  arr: Array<TOption>,
  callback: (prev: TOption, curr: Partial<TResult>) => Partial<TResult>,
  defaultValue?: Partial<TResult>
): TResult {
  return arr.reduce(callback as any, defaultValue) as any;
}
