import { FC } from 'react';

import * as c from './components';

const Step: FC<{
  stepNo: number;
}> = ({ stepNo }) => (
  <c.Step.NumberContainer id={`step-number-${stepNo}`}>
    <c.Step.NumberAnimation variants={c.Step.numberContainerVariants}>
      <c.Step.NumberBackground />
      <c.Step.Number>{stepNo}</c.Step.Number>
    </c.Step.NumberAnimation>
  </c.Step.NumberContainer>
);

export default Step;
