import { FC } from 'react';

import { Section } from '@/common/Layout';
import { x } from '@/style';

import { BlogArticlesCollection } from '../../common/articles-collection';

export const Suggestions: FC<{
  title: string;
  articles: any;
}> = ({ title, articles }) => (
  <Section>
    <x.div px={{ _: 0, tablet: 'lg' }}>
      <BlogArticlesCollection
        title={title}
        articles={articles}
        maxColumnCount={2}
      />
    </x.div>
  </Section>
);
