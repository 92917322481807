import { Link } from '@/elements';
import { ILinkItem } from '@/state/AppState/types';

import * as c from './components';

export const LinkNavItem = ({
  href,
  target,
  label,
  trackName,
  afterClick,
}: ILinkItem & {
  afterClick: () => any;
}) => (
  <c.AccordionChildAnimation>
    <Link
      variant='blank'
      key={href}
      href={href}
      target={target}
      trackName={trackName}
      onClick={() => {
        afterClick();
      }}
    >
      <c.AccordionListItem>
        {label}
        <c.AccordionArrowRight />
      </c.AccordionListItem>
    </Link>
  </c.AccordionChildAnimation>
);
