import { FC } from 'react';

import { Portal } from 'react-portal';

import { AnimationContainer } from './animation-container';
import { MenuScreen } from './menu-screen';
import { Provider } from './provider';
import { ToggleButton } from './toggle-button';

export const FooterMenu: FC<{
  openText: JSX.Element | string;
  closeText: JSX.Element | string;
  animationOpenName?: string;
  animationClosedName?: string;
}> = ({
  children,
  openText,
  closeText,
  animationOpenName = 'footer-menu-open',
  animationClosedName = 'footer-menu-closed',
}) => (
  <Provider
    animationOpenName={animationOpenName}
    animationClosedName={animationClosedName}
  >
    <AnimationContainer>
      <Portal>
        <MenuScreen>{children}</MenuScreen>
        <ToggleButton openText={openText} closeText={closeText} />
      </Portal>
    </AnimationContainer>
  </Provider>
);
