import ArrowUp from '@/assets/iconly/Arrow - Up 2.svg';
import { SquareIconButton } from '@/common/icon-button';
import { useMainScrollContainerId } from '@/common/main-scroll-container/use-main-scroll-container-id';
import { useScrollToTop } from '@/lib/hooks/use-scroll-to-top';

export const ToTopButton = () => {
  const scrollContainerId = useMainScrollContainerId();
  const scrollToTop = useScrollToTop(scrollContainerId || undefined);
  return (
    <SquareIconButton onClick={() => scrollToTop('smooth')}>
      <ArrowUp />
    </SquareIconButton>
  );
};
