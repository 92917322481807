import { useRef } from 'react';

import { Progress, useTimelineItem } from '@/lib/ScrollTimeline';

const useScrollTracking = ({
  pages,
  onChange,
}: {
  pages: Array<string>;
  onChange: (id: string) => void;
}) => {
  const previousPageIndex = useRef(0);
  useTimelineItem(
    () =>
      new Progress({
        progress: {
          from: 0,
          to: pages.length,
        },
        elements: {},
        getStartPos: () => 0,
        getEndPos: ({ scrollStatus: { container } }) => {
          const containerRec = container.getBoundingClientRect();
          return container.scrollHeight - containerRec.height;
        },
        handlers: {
          onStatusChanged: ({ status: progress }) => {
            if (progress !== null) {
              let newPageIndex = Math.floor(progress);
              if (newPageIndex >= pages.length) newPageIndex = pages.length - 1;
              if (newPageIndex < 0) newPageIndex = 0;

              if (newPageIndex !== previousPageIndex.current) {
                onChange(pages[newPageIndex]);
                previousPageIndex.current = newPageIndex;
              }
            }
          },
        },
      })
  );
};

export default useScrollTracking;
