import { FC } from 'react';

import { context } from './context';
import { useActiveOptionManager } from './use-active-option-manager';

export const ScrollSpyContainer: FC<{
  offsetTop?: string;
  options: Array<string>;
}> = ({ offsetTop = '0px', options, children }) => {
  const { activeOption, setOptionVisibility } = useActiveOptionManager(options);

  return (
    <context.Provider
      value={{
        offsetTop,
        options,
        activeOption,
        setOptionVisibility,
      }}
    >
      {children}
    </context.Provider>
  );
};
