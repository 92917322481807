import type { IJourneyStepContent } from '../types.d';

import { FC } from 'react';

import { CtaRow, RichText } from '@/common/storyblok/components';
import * as e from '@/elements';
import { Fade } from '@/lib/utils/animation';

import StepNumber from '../StepNumber';
import * as c from '../components';

const ImportantStepContent: FC<{
  step: IJourneyStepContent;
  stepNo: number;
}> = ({ step, stepNo }) => (
  <c.Step.Container spacing='lg'>
    <c.Step.Column my={{ _: 0, tablet: 'lg' }} maxW='500px'>
      <StepNumber stepNo={stepNo} />
      <c.Step.TextContainer mt='8px'>
        <Fade.Up>
          <e.Text variant='h3' mb='md'>
            {step.title}
          </e.Text>
        </Fade.Up>
        <Fade.Up>
          <RichText>{step.content}</RichText>
        </Fade.Up>
        {step.ctas?.length ? (
          <Fade.Up>
            <CtaRow ctas={step.ctas} mt='lg' justifyContent='flex-start' />
          </Fade.Up>
        ) : null}
      </c.Step.TextContainer>
    </c.Step.Column>
  </c.Step.Container>
);

export default ImportantStepContent;
