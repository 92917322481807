import type { SbEditableContent, SbRichText, TCtaContent } from 'storyblok';

import { FC } from 'react';

import { profileSymbolMap } from '@/common/ProfileSymbols';
import { RichText, CtaRow } from '@/common/storyblok/components';
import { Layout, Text } from '@/elements';
import ResponsiveSwitch from '@/lib/ResponsiveSwitch';
import { x } from '@/style';

import * as c from './components';

export type TCardContent = SbEditableContent<{
  profileName: keyof typeof profileSymbolMap;
  title: string;
  content: SbRichText;
  ctas: Array<TCtaContent>;
}>;

const Card: FC<{
  content: TCardContent;
}> = ({ content }) => {
  const Symbol = profileSymbolMap[content.profileName];
  return (
    <c.CardContainer>
      <ResponsiveSwitch
        sm={
          <Layout.VStack
            maxW='section-width-sm'
            py='md'
            mx='auto'
            flex={1}
            spacing='lg'
          >
            <Layout.HStack alignItems='center'>
              <Symbol size={60} />
              <Text variant='h3' pb={0} mt={4}>
                {content.title}
              </Text>
            </Layout.HStack>
            <RichText>{content.content}</RichText>
            <CtaRow ctas={content.ctas} justifyContent='flex-start' />
          </Layout.VStack>
        }
        bg={
          <Layout.HStack justifyContent='center'>
            <Symbol size={100} />
            <Layout.VStack
              maxW='section-width-sm'
              py='lg'
              flex={1}
              spacing='lg'
            >
              <Text variant='h3'>{content.title}</Text>
              <RichText>{content.content}</RichText>
              <CtaRow ctas={content.ctas} justifyContent='flex-start' />
            </Layout.VStack>
            <x.div maxW='100px' w='100%' />
          </Layout.HStack>
        }
      />
    </c.CardContainer>
  );
};

export default Card;
