import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { StyledComponent, x } from '@/style';

export const StickyContainer: StyledComponent<{
  top?: number | string;
}> = ({ top, children, ...rest }) => (
  <x.div
    p='md'
    {...rest}
    position='sticky'
    top={top}
    w='100%'
    display='flex'
    justifyContent='center'
  >
    <AnimateOnVisible>
      <Fade.Up>{children}</Fade.Up>
    </AnimateOnVisible>
  </x.div>
);
